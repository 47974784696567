import { AxiosError } from "axios";
import { useState } from "react";
import { CalendarDateModel } from "../../../data/models";
import { LearningPathCalendarDataService } from "../../../data/services/learningPathCalendar.data.service";
import {
  CalendarDateEntity,
  OptionsSelectEntity,
} from "../../../domain/entities";
import { useCaseLearningPathCalendar } from "../../../domain/useCases/learningPathCalendar";
import {
  useCalendarDateFormatter,
  useCalendarDateUTCFormatter,
  useCustomEffect,
  useNavigation,
} from "../../hooks";
import { useForm, FieldValues } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { calendarFormSchema } from "../../validators";

export function useLearningPathCalendarViewModel() {
  const { getQueryParam, navigateTo } = useNavigation();
  const { getListSessions, postSignUpForSession } = useCaseLearningPathCalendar(
    LearningPathCalendarDataService()
  );
  const [sessionDateHours, setSessionDateHours] = useState<CalendarDateModel>();
  const [hoursForSelectedDate, setHoursForSelectedDate] = useState<
    Array<OptionsSelectEntity> | []
  >([]);
  const [showDateModal, setShowDateModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [dateForModal, setDateForModal] = useState("");
  const [hoursForModal, setHoursForModal] = useState("");

  const learningPathId = getQueryParam("uuid");
  const medalId = getQueryParam("medalid");

  const breadcrumbItemsElements = [
    {
      name: "Inicio",
      url: "/",
    },
    {
      name: "Caminos de Aprendizaje",
      url: "/learning-path",
    },
    {
      name: "Camino",
      url: `/learning-path-detail?uuid=${learningPathId}`,
    },
    {
      name: "Calendario",
    },
  ];

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
    getValues,
    resetField,
  } = useForm({ mode: "onChange", resolver: yupResolver(calendarFormSchema) });

  function successGetListSessions(dateHours: CalendarDateModel) {
    setSessionDateHours(dateHours);
  }

  function errorGetListSessions(e: AxiosError) {
    console.log("errorGetListSessions", e);
  }

  function onChangeDate(value: any) {
    const dateClicked = value.$D;
    const selectedDate = sessionDateHours?.items?.find((dateAndHours) => {
      const currentDate = new Date(dateAndHours.date).getUTCDate();
      return currentDate == dateClicked;
    });

    const selectedDateHours = selectedDate?.hours.map((hour) => {
      //Start Date
      const startFullDate = new Date(`${selectedDate.date}  ${hour.startHour}`);

      const optionHour = {
        id: hour.id,
        name: `${hour.startHour} - ${hour.endHour}${
          +hour.startHour.split(":")[0] > +hour.endHour.split(":")[0]
            ? "(+1 día)"
            : ""
        }`,
        complementaryData: {
          startDate: useCalendarDateFormatter(`${startFullDate}`),
          startTime: hour.startHour,
          endTime: `${hour.endHour}${
            +hour.startHour.split(":")[0] > +hour.endHour.split(":")[0]
              ? "(+1 día)"
              : ""
          }`,
        },
      };
      return optionHour;
    });
    if (!!selectedDateHours?.length) {
      setHoursForSelectedDate([
        { id: "none", name: "Selecciona un horario" },
        ...selectedDateHours,
      ]);
      resetField("selectedHours", { defaultValue: "none" });
    } else {
      setHoursForSelectedDate([]);
    }
  }

  function onErrorSignUpSession() {
    setShowDateModal(false);
    setShowErrorModal(true);
  }

  function shouldDisableDate(date: unknown) {
    const newDate = new Date(date as string).getUTCDate();
    const newMonth = new Date(date as string).getUTCMonth();
    const selectedDate = sessionDateHours?.items?.find((dateAndHours) => {
      const backEndDate = new Date(dateAndHours.date).getUTCDate();
      const backEndMonth = new Date(dateAndHours.date).getUTCMonth();

      return backEndDate === newDate && newMonth === backEndMonth;
    });
    return selectedDate === undefined ? true : false;
  }

  function onSuccessSignUpSession() {
    setShowDateModal(false);
    setShowSuccessModal(true);
  }

  const onSubmit = (data: FieldValues) => {
    const findHour = hoursForSelectedDate.find(
      (hours) => hours.id === data.selectedHours
    );
    if (findHour?.complementaryData) {
      setHoursForModal(
        `${findHour?.complementaryData.startTime} - ${findHour?.complementaryData.endTime}`
      );
    }
    if (data.selectedHours) {
      postSignUpForSession({
        learningPathUuid: learningPathId,
        medalId: medalId,
        id: data.selectedHours,
        error: onErrorSignUpSession,
        success: onSuccessSignUpSession,
      });
    }
  };

  function handleOpenModal() {
    const hoursIdSelectedByUser = getValues().selectedHours;
    const hoursSelectedByUser = hoursForSelectedDate.find(
      (currentHours) => currentHours.id === hoursIdSelectedByUser
    );
    hoursSelectedByUser?.complementaryData?.startDate &&
      setDateForModal(`${hoursSelectedByUser?.complementaryData.startDate}`);

    setShowDateModal(true);
  }

  function handleSuccess() {
    navigateTo("/dashboard");
    setShowSuccessModal(false);
  }

  useCustomEffect(() => {
    const today = { $D: new Date().getDate() };
    !!sessionDateHours?.items.length && onChangeDate(today);
  }, [sessionDateHours?.items]);

  useCustomEffect(() => {
    getListSessions({
      success: successGetListSessions,
      error: errorGetListSessions,
      learningPathUuid: learningPathId,
      medalId: medalId,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
  }, []);
  return {
    breadcrumbItemsElements,
    sessionDateHours,
    onChangeDate,
    hoursForSelectedDate,
    control,
    handleSubmit,
    onSubmit,
    errors,
    useCalendarDateUTCFormatter,
    setShowDateModal,
    showDateModal,
    handleOpenModal,
    isValid,
    shouldDisableDate,
    showSuccessModal,
    handleSuccess,
    dateForModal,
    hoursForModal,
    showErrorModal,
    setShowErrorModal,
  };
}
