import dayjs, { Dayjs } from 'dayjs';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
//import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { StyledDatePicker, StyledInputDate } from './DatePicker.styles';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import 'dayjs/locale/es-mx';
import {ReactComponent as LeftArrow} from "./../../../../assets/images/leftArrowCalendar.svg"
import {ReactComponent as RightArrow} from "./../../../../assets/images/rightArrowCalendar.svg"
import {ReactComponent as CalendarIcon} from "./../../../../assets/images/calendarIcon.svg"

import React, { FC, useState } from 'react';
//import 'sassy-datepicker/dist/';

enum DaysOfTheWeek {
  do = "Dom",
  lu = "Lun",
  ma = "Mar",
  mi = "Mie",
  ju = "Jue",
  vi = "Vie",
  sá = "Sab"
}

interface DatePickerProps {
  onChangeDate:(value: any) => void;
  shouldDisableDate: (date: unknown) => boolean;
}

export const DatePicker: FC<DatePickerProps> = ({onChangeDate,shouldDisableDate}) => {
  const [value, setValue] = useState<Dayjs | null | unknown>(dayjs());
 
  return (
    <div className='max-w-xs mx-auto'>
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es-mx">
      <StyledInputDate 
        value={value}
        onChange={(newValue: unknown) => {
          setValue(newValue);
          onChangeDate(newValue);
        }}
        renderInput={(params) => <TextField {...params} />} 
        //disableOpenPicker={true}
        inputFormat={"MMMM/DD/YYYY"}
        components={{OpenPickerIcon: CalendarIcon}}
        open={false}
      />
      <StyledDatePicker
        shouldDisableDate={shouldDisableDate}
        disablePast={true}
        displayStaticWrapperAs='desktop'
        openTo={'day'}
        value={value}
        views={['day']}
        onChange={(newValue: unknown) => {
            setValue(newValue);
            onChangeDate(newValue);
        }}
        dayOfWeekFormatter={(day) => DaysOfTheWeek[day as keyof typeof DaysOfTheWeek]}
        renderInput={(params) => <TextField {...params} />}
        components={{LeftArrowIcon: LeftArrow, RightArrowIcon: RightArrow}}
      />
    </LocalizationProvider>
    </div>
  );
}
