import { yupResolver } from "@hookform/resolvers/yup";
import { FieldValues, useForm } from "react-hook-form";
import { useNavigation } from "../../../../../hooks";
import { goSubscribeSchema } from "../../../../../validators";

export const useGoSubscribe = () => {
  const { navigateTo } = useNavigation();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ mode: "onChange", resolver: yupResolver(goSubscribeSchema) });

  const onSubmit = (data: FieldValues) => {
    navigateTo("/signup", data);
  };

  return {
    handleSubmit,
    control,
    onSubmit,
    errors,
  };
};
