import { FC } from "react";
import { MiniMedalEntity } from "../../../../../domain/entities";
import { Carousel, MiniMedalCard } from "../../../../components";

export interface MedalsCompletedProps {
  medalsCompletedByUser: MiniMedalEntity[];
}

export const MedalsCompleted: FC<MedalsCompletedProps> = ({
  medalsCompletedByUser,
}) => {
  return (
    <div className="px-6 ">
      <Carousel
        slidesToShow={3}
        slidesToScroll={1}
        infinite={false}
        dots={false}
        centerMode={false}
        responsive={[
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
              arrows: false,
              centerMode: true,
              centerPadding: "84px",
            },
          },
        ]}
      >
        {medalsCompletedByUser.map((medal: MiniMedalEntity, index) => {
          return (
            <MiniMedalCard
              key={`m-${index}`}
              title={medal.title}
              image={medal.image}
              id={medal.id}
            />
          );
        })}
      </Carousel>
    </div>
  );
};
