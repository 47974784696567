import tw from "twin.macro";
import styled from "styled-components";

export const StyledBannerSection = styled.section.attrs({
  className: "StyledBannerSection",
})`
  ${tw`p-5 pt-28 relative w-full h-[40rem] flex flex-col gap-y-6 items-center  sm:(p-10 flex-row items-end justify-between)`}
`;

export const StyledBannerBackground = styled.div.attrs({
  className: "StyledBannerBackground",
})`
  ${tw`absolute w-full top-0 left-0 z-[1] h-[40rem] overflow-hidden `}
  video {
    ${tw`object-none object-bottom`}
    transform: translate(0,-12%);
  }
`;

export const StyledBannerInfoContainer = styled.div.attrs({
  className: "StyledBannerInfoContainer",
})`
  ${tw`h-full max-w-preview flex flex-col justify-between gap-y-6 text-center z-[2] sm:(h-auto px-0 text-left)`}
  h1 {
    ${tw`text-xl leading-6 font-bold text-white sm:(text-[3.5rem] leading-[4.25rem])`}
  }

  p {
    ${tw`px-6 flex flex-col gap-y-6 text-sm leading-4 font-primarySemibold text-white sm:(px-0 gap-y-0 text-xl leading-6) `}
  }
`;

export const StyledCallToActionContainer = styled.div.attrs({
  className: "StyledCallToActionContainer",
})`
  ${tw`w-52 flex flex-col items-center gap-y-1 z-[2]`}
  button {
    ${tw``}
  }
  h3 {
    ${tw`flex flex-col text-center text-xs leading-4 font-primarySemibold text-white sm:(text-2xl leading-7 max-w-[12.5rem])`}
  }
`;
