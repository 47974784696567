import { LearningPathService } from "../../domain/services/learningPath.service";
import { handleResponse } from "../../domain/shared";
import { httpService } from "../http/services/http";
import { LearningPathModel } from "../models/learningPath.model";
import { MedalModel } from "../models/medal.model";
import { Endpoints } from "./endpoints";

export interface ListMedalsResponse {
    data: {
        data: {
            items: MedalModel;
        };
    }
}

export interface ListLearningPathsResponse {
    data:{
        data:{
            items: Array<LearningPathModel>;
        }
    }
}

export function learningPathDataService(): LearningPathService {
    const { get } = httpService();
    return {
        async getListMedals({success, error}: handleResponse){
            try {
                const response: ListMedalsResponse = await get(Endpoints.listMedals);
                if (success){
                    success(response.data.data.items);
                }
            } catch (e) {
                if(error){
                    error(e);
                }
            }
        },
        async getListLearningPaths({success, error}: handleResponse){
            try {
                const response: ListLearningPathsResponse = await get(Endpoints.listLearningPaths);
                if (success){
                    success(response.data.data.items);
                }
            } catch (e) {
                if (error) {
                    error(e);
                }
            }
        }
    }
}