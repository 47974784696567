import tw from "twin.macro";
import styled from "styled-components";

export interface StyledCardProps {
  className?: string;
  withBadge?: boolean;
}

export const StyledCard = styled.article.attrs((props) => ({
  className: props.className,
}))<StyledCardProps>`
  ${tw`grid gap-y-2 `}
  grid-template-rows: 216px 30px 52px 24px 72px;

  .playButton {
    ${tw`absolute top-1/2 left-1/2`}
    width: 30px;
    height: 30px;
    transform: translate(-50%, -50%);
  }

  ${({ withBadge }) =>
    withBadge &&
    `grid-template-rows: 216px 30px 52px auto 24px 72px;
    `}

  @media (max-width: 1024px) {
    ${tw`flex flex-col items-center gap-y-1 relative`}
  }
`;
export const StyledImage = styled.img.attrs({
  className: "StyledImage",
})`
  ${tw`rounded-3xl w-full object-cover `}
  display: block;
  height: 216px;
  max-width: 384px;
  @media (max-width: 1024px) {
    max-height: 264px;
    width: 100%;
    max-width: 100%;
    height: 264px;
  }

  @media (max-width: 480px) {
    display: none !important;
  }
`;

export const StyledImageMobile = styled.img.attrs({
  className: "StyledImageMobile",
})`
  ${tw`rounded-3xl w-full object-cover `}
  display: block;
  height: 216px;
  max-width: 384px;
  @media (max-width: 1024px) {
    max-height: 264px;
    width: 100%;
    max-width: 100%;
    height: 264px;
  }
  @media (min-width: 479.99px) {
    display: none !important;
  }
`;
