import {
  ContentService,
  ContentBySlug,
} from "../../domain/services/content.service";
import { handleResponse } from "../../domain/shared";
import { httpService } from "../http/services/http";
import { Endpoints } from "./endpoints";
import { ContentModel } from "../models";

export interface ContentResponse {
  data: {
    data: ContentModel;
  };
}

export function contentDataService(): ContentService {
  const { get } = httpService();
  return {
    async getContent({ slug, success, error }: ContentBySlug) {
      try {
        const response: ContentResponse = await get(
          `${Endpoints.content}/${slug}`
        );
        if (success) {
          success(response.data.data);
        }
      } catch (e) {
        if (error) {
          error(e);
        }
      }
    },
  };
}
