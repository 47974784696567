import { CountryRepository } from "../../domain/repositries";
import { CountryEntity } from "../../domain/entities";
import { httpService } from "../http/services/http";
import { Endpoints } from "./endpoints";
export interface CountryResponse {
  data: {
    data: {
      items: Array<CountryEntity>;
    };
  };
}
export function countryDataService(): CountryRepository {
  const { get } = httpService();
  return {
    async getCountries({ success, error }) {
      try {
        const response: CountryResponse = await get(Endpoints.countries);
        if (success) {
          success(response.data.data.items);
        }
      } catch (e) {
        if (error) {
          error(e);
        }
      }
    },
  };
}
