import { FC } from "react";
import { DefaultLayout } from "../../layouts";
import { Navbar, SEO, VideoJS } from "../../components";
import { Helmet } from "react-helmet";
import {
  InformationSection,
  RelatedAreas,
  SeasonsSection,
  UsersCommentsSection,
  VideoPreview,
} from "./components";
import { usePreviewViewModel } from "../../viewModels";
import { StyledBlurWall, StyledButton } from "./preview.styles";

export const Preview: FC = () => {
  const {
    isProductionRented,
    token,
    userSubscription,
    videoOptions,
    similarProductions,
    currentSeasonNumber,
    currentProduction,
    currentChapter,
    nextChapter,
    onGetChapter,
    navigateTo,
    handleSaveProduction,
    isProductionSaved,
    handlePlayerReady,
    videoTimeLeft,
    handleCallToActionRental,
    isPodcast,

    showModalRating,
    onSubmitRating,
    onCancelRating,
    onClickRatingButton,
    userCanComment,
    comments,
    handleChangeCommentsPage,
    seoProductionData,
  } = usePreviewViewModel();
  return (
    <DefaultLayout>
      <SEO
        title={seoProductionData.title}
        description={seoProductionData.description}
        image={seoProductionData.image}
        author={seoProductionData.author}
        jsonLd={seoProductionData.jsonLd}
        robots={seoProductionData.robots}
        facebookAppId={seoProductionData.facebookAppId}
        twitterSite={seoProductionData.twitterSite}
      />
      <div className="w-full lg:hidden">
        <Navbar />
      </div>
      <VideoPreview
        videoOptions={videoOptions}
        imgPreview={currentProduction.imgPreview}
        format={currentProduction.format}
        terms={currentProduction.terms}
        duration={currentProduction.duration}
        handlePlayerReady={handlePlayerReady}
        timeLeft={videoTimeLeft}
        chapterName={
          currentChapter.name ? currentChapter.name : currentProduction.title
        }
        productionName={currentProduction.title}
        currentSeasonNumber={currentSeasonNumber}
        nextChapterName={nextChapter?.name ? nextChapter.name : ""}
        nextChapterImage={nextChapter?.imgPreview ? nextChapter.imgPreview : ""}
        similarProductions={similarProductions}
        isPodcast={isPodcast}
      />

      <InformationSection
        token={token}
        title={currentProduction.title}
        description={currentProduction.description}
        onClick={handleSaveProduction}
        isProductionSaved={isProductionSaved}
        isProductionRented={isProductionRented}
        isSubscriptionActive={userSubscription?.stripeStatus === "active"}
        price={currentProduction.price}
        navigateToRental={handleCallToActionRental}
        onSubmitRating={onSubmitRating}
        onCancelRating={onCancelRating}
        onClickRatingButton={onClickRatingButton}
        showModalRating={showModalRating}
        userCanComment={userCanComment}
      />
      <div className="relative pb-36">
        {userSubscription?.stripeStatus !== "active" && !isProductionRented && (
          <StyledBlurWall />
        )}
        {!!(
          currentProduction?.seasons?.length ||
          currentProduction?.chapters?.length
        ) && (
          <SeasonsSection
            onChapterClick={onGetChapter}
            seasons={currentProduction.seasons}
            chapters={currentProduction.chapters}
            currentChapter={currentChapter}
          />
        )}
        <RelatedAreas terms={currentProduction.terms} />
        {/* <RelatedContent
        categoryName="MÁS TÍTULOS SOBRE ESTA SOFT SKILL:"
        productions={dataSliders}
      /> */}

        {!!comments?.items?.length && (
          <UsersCommentsSection
            comments={comments}
            handleChangeCommentsPage={handleChangeCommentsPage}
          />
        )}
        {!token && (
          <StyledButton onClick={() => navigateTo("/signup")}>
            ¡Regístrate ahora!
          </StyledButton>
        )}
      </div>
    </DefaultLayout>
  );
};
