import {
  RentalsService,
  RentalsWithFilter,
  RentProduction,
} from "../../services/rentals.service";
import { handleResponse } from "../../shared";

export function useCaseRentals(service: RentalsService) {
  return {
    getFormats({ success, error }: handleResponse) {
      return service.getRentalsFormats({
        success,
        error,
      });
    },
    getRentalsFiltered({ filter, success, error }: RentalsWithFilter) {
      return service.getRentalsWithFilter({
        filter,
        success,
        error,
      });
    },
    getRental({ productionId, success, error }: RentProduction) {
      return service.getRentProduction({
        productionId,
        success,
        error,
      });
    },
  };
}
