import { FC } from "react";
import { StyledPodcastContainer } from "./Podcast.styles";

import { ProductionEntity } from "../../../../../domain/entities";
import { Carousel, PodcastCard } from "../../../../components";

export interface PodcastSectionProps {
  podcastList: Array<ProductionEntity>;
}

export const PodcastSaved: FC<PodcastSectionProps> = ({ podcastList }) => {
  return (
    <>
      <StyledPodcastContainer>
        <h3>PODCAST</h3>

        <div className="w-full flex lg:pt-8 ">
          <Carousel
            slidesToShow={3}
            slidesToScroll={3}
            infinite={false}
            dots={true}
            arrows={false}
            centerMode={false}
            responsive={[
              {
                breakpoint: 1220,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                },
              },
            ]}
          >
            {podcastList.map((currentPodcast, index) => (
              <PodcastCard
                className={"h-full px-0 mx-auto"}
                key={`P-${index}`}
                podcastData={currentPodcast}
              />
            ))}
          </Carousel>
        </div>
      </StyledPodcastContainer>
    </>
  );
};
