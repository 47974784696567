import { FC } from "react";
import { Control, FieldValues, useController } from "react-hook-form";
import { PasswordField, PasswordProps } from "./PasswordField";

interface PasswordFieldControlledProps extends PasswordProps {
  control?: Control<FieldValues, any>;
  id: string;
  name: string;
  useTooltip?: boolean;
}

export const PasswordFieldControlled: FC<PasswordFieldControlledProps> = ({
  className,
  id,
  name,
  label,
  error,
  helperText,
  placeholder,
  control,
  useTooltip=false,
  ...rest
}) => {
  const {
    field: { onChange, value, ref },
  } = useController({
    name,
    control,
    rules: {
      required: true,
    },
    defaultValue: "",
  });

  return (
    <PasswordField
      onChange={onChange}
      value={value}
      ref={ref}
      className={className}
      id={id}
      name={name}
      label={label}
      error={error}
      helperText={helperText}
      placeholder={placeholder}
      {...rest}
      useTooltip={useTooltip}
    />
  );
};
