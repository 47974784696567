import { FC } from "react";
import {
  Breadcrumb,
  Button,
  Modal,
  TextFieldControlled,
  TextAreaControlled,
} from "../../components";
import { DefaultLayout } from "../../layouts";
import { useSubscriptionsViewModel } from "../../viewModels";
import {
  CodeValidationSection,
  HistorySection,
  SubscriptionsSection,
} from "./components";
import { StyledSubscriptionContainer } from "./subscriptions.styles";

export const Subscriptions: FC = () => {
  const {
    navigateTo,
    newLocationState,
    breadcrumbItemsElements,

    subscriptionsList,
    handleCallToActionCard,
    showContactFormModal,
    showContactFormSuccessModal,
    showGoToLoginModal,
    handleCloseGoToLoginModal,

    handleCloseModal,
    onSubmit,
    control,
    errors,
    handleSubmit,

    handlePromoCodeCallToAction,
  } = useSubscriptionsViewModel();

  return (
    <DefaultLayout>
      <Breadcrumb
        className="pb-12 px-8 lg:px-14 "
        breadcrumbItems={breadcrumbItemsElements}
      />
      <StyledSubscriptionContainer>
        <HistorySection />
        <SubscriptionsSection
          subscriptionsList={subscriptionsList}
          handleCallToActionCard={handleCallToActionCard}
        />
      </StyledSubscriptionContainer>

      <StyledSubscriptionContainer>
        <CodeValidationSection
          handleCallToAction={handlePromoCodeCallToAction}
        />
      </StyledSubscriptionContainer>

      <div className="py-10" />

      <Modal
        showModal={showContactFormModal}
        backgroundColor="white"
        onClose={handleCloseModal}
      >
        <div className="px-4 lg:px-8 text-center flex flex-col items-center justify-center  max-w-registerModal">
          <h3 className="text-2xl font-primaryBold text-primary-100">
            Contáctanos
          </h3>
          <p className="font-primaryBook text-xs lg:text-xl">
            Sabemos que buscas mejorar las soft skill de los integrantes de tu
            compañía, nuestro equipo te enviará la información al correo
            ingresado.
          </p>
          <form>
            <TextFieldControlled
              id={"email"}
              name="email"
              label="Correo electrónico"
              placeholder="Escribe aquí tu email"
              error={!!errors.email}
              helperText={errors?.email?.message}
              control={control}
              required
              textLabelColor="#000000"
              className="py-2"
            />
            <TextFieldControlled
              id={"company"}
              name="company"
              label="Empresa"
              placeholder="Escribe el nombre de tu empresa"
              error={!!errors.company}
              helperText={errors?.company?.message}
              control={control}
              required
              textLabelColor="#000000"
              className="py-2"
            />
            <TextAreaControlled
              id={"description"}
              name="description"
              label="Descripción"
              placeholder="Cuéntanos si necesitas información o un plan personalizado"
              error={!!errors.description}
              helperText={errors?.description?.message}
              control={control}
              required
              textLabelColor="#000000"
              className="py-2"
            />
            <Button className="my-4" onClick={handleSubmit(onSubmit)}>
              Enviar
            </Button>
            <p className="font-primaryBook text-xs">
              Al enviar la solicitud, aceptas el procesamiento de sus datos
              personales por parte de Storytraining, para gestionarla y
              responder a ella. Si deseas obtener más información, por favor
              consulte nuestra Política de privacidad.
            </p>
          </form>
        </div>
      </Modal>

      <Modal showModal={showContactFormSuccessModal} backgroundColor="white">
        <div className="px-4 lg:px-8 text-center flex flex-col items-center justify-center gap-y-6 max-w-registerModal">
          <h3 className="text-2xl lg:text-2 font-primaryBold text-primary-100 tracking-tight">
            ¡Gracias por contactarnos!
          </h3>
          <p className="font-primaryBook text-sm lg:text-base">
            ¡Tu mensaje ha sido enviado con éxito!
          </p>
          <p className="font-primaryBook text-sm lg:text-base">
            Te responderemos en el menor tiempo posible. Por ahora sigue
            explorando nuestro contenido y todos los beneficios que tenemos para
            ti y tu empresa.
          </p>
          <Button onClick={() => navigateTo("/")}>Volver al inicio</Button>
        </div>
      </Modal>

      <Modal
        showModal={showGoToLoginModal}
        onClose={handleCloseGoToLoginModal}
        backgroundColor="white"
      >
        <div className="px-4 lg:px-8 text-center flex flex-col items-center justify-center gap-y-6 max-w-inputForm">
          <h3 className="text-2 font-primaryBold text-primary-100 tracking-tight">
            ¡Necesitas loguearte!
          </h3>
          <p className="font-primaryBook text-xs tracking-tight lg:text-base">
            Antes de elegir una de nuestras suscripciones debes ingresar a tu
            cuenta.
          </p>
          <p className="flex gap-x-2 font-primaryBook text-xs tracking-tight lg:text-base">
            ¿Aún no cuentas con una?{" "}
            <span
              className="text-primary-100 cursor-pointer font-primaryBold"
              onClick={() => navigateTo("/signup")}
            >
              Regístrate aquí
            </span>
          </p>
          <Button onClick={() => navigateTo("/login", newLocationState)}>
            Ingresar
          </Button>
        </div>
      </Modal>
    </DefaultLayout>
  );
};
