import tw from "twin.macro";
import styled from "styled-components";

export const StyledHistoryCardInfo = styled.section.attrs((props) => ({className: props.className}))`
${tw`
    h-[328px]
    w-full
    lg:px-7
    place-items-center
    items-center
    text-center
    justify-around
    text-white
    px-2
    grid gap-y-2 
    
`}
grid-template-rows: 140px 60px 120px;

h2 {
    ${tw`font-primaryBold text-xl lg:text-2xl w-[214px]`}
}

p {
    ${tw`font-primaryRegular text-sm lg:text-base w-[252px]`}
}

img {
    ${tw`w-28 h-28 lg:w-32 lg:h-32`}
}
`