import tw from "twin.macro";
import { styled as styledMui } from "@mui/material/styles";
import styled from "styled-components";
import { Pagination, PaginationProps } from "@mui/material";

export const StyledPaginatorContainer = styled.div.attrs((props) => ({
  className: props.className,
}))`
  ${tw`relative`}
`;

export const StyledPagination = styledMui(Pagination)<PaginationProps>(
  ({ theme }) => ({
    color: "#fff",
    "& .MuiPagination-ul": {
      "& .MuiPaginationItem-root": {
        // backgroundColor: "#C1101A",
        color: "#fff",
        "&.Mui-selected": {
          backgroundColor: "#C1101A",
        },
      },
    },
  })
);
