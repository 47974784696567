import { HomeService } from "../../services/home.service";
import { handleResponse } from "../../shared";

export function useCaseHome(service: HomeService) {
  return {
    getHomeData({ success, error }: handleResponse) {
      return service.getHomeData({ success, error });
    },
    getFavorites({ success, error }: handleResponse) {
      return service.getFavoritesProductionsHome({ success, error });
    },
  };
}
