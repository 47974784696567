import { Avatar } from "@mui/material";
import { forwardRef, InputHTMLAttributes } from "react";
import { FieldError, FieldErrorsImpl, Merge } from "react-hook-form";
import { StyledLabelInputAvatar } from "./AvatarField.styles";
import avatarIcon from "../../assets/images/avatarIcon.svg";
import { ReactComponent as EditPhotoIcon } from "../../assets/images/editPhotoIcon.svg";
import { ReactComponent as ImageIcon } from "../../assets/images/imageIcon.svg";

export interface AvatarProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, "value"> {
  id?: string;
  name?: string;
  value?: { base64: string | null; file?: any };
  error?: boolean;
  editAvatar?: boolean;
}

export const AvatarField = forwardRef<HTMLInputElement, AvatarProps>(
  (props, ref) => {
    const {
      className,
      value,
      id,
      name,
      required,
      error,
      editAvatar = true,
      ...rest
    } = props;

    return (
      <div className="relative flex flex-col gap-y-6 items-center">
        <Avatar
          sx={{ width: 144, height: 144 }}
          src={value?.base64 ? value.base64 : avatarIcon}
          alt="Imagen de usuario"
          title="Imagen de usuario"
        />
        {editAvatar && (
          <h4 className="text-sm flex flex-col lg:hidden">
            Foto de perfil
            <span className="font-primaryBook">
              (Recomendado: 800 x 800 px)
            </span>
          </h4>
        )}
        <StyledLabelInputAvatar
          className={editAvatar ? "flex" : "hidden"}
          htmlFor={id}
        >
          <EditPhotoIcon className="hidden lg:inline-block" />
          <ImageIcon className="inline-block lg:hidden" />
          <span className="hidden lg:inline-block">Cambiar foto</span>
          <span className="inline-block lg:hidden">Seleccionar imagen</span>
        </StyledLabelInputAvatar>
        <input
          className="hidden"
          type="file"
          accept="image/*"
          id={id}
          name={name}
          ref={ref}
          {...rest}
        />
      </div>
    );
  }
);
