import { FC } from "react";
import { Carousel, Card } from "../";
import { StyledCategoryCarousel } from "./CategoryCarousel.styles";
import { ProductionEntity } from "../../../domain/entities";
interface CategoryCarouselProps {
  className?: string;
  isCatalog?: boolean;
  categoryName: string;
  categoryDescription?: string;
  productions: Array<ProductionEntity>;
  fullWidth?: boolean;
  hideCategory?: boolean;
  slidesToShow?: number;
  slidesToShowMobile?: number;
  currentCardPaddingMobile?: string;
}

export const CategoryCarousel: FC<CategoryCarouselProps> = ({
  className,
  isCatalog = false,
  categoryName,
  categoryDescription,
  productions,
  fullWidth = false,
  hideCategory = false,
  slidesToShow = 3,
  slidesToShowMobile = 1,
  currentCardPaddingMobile = "45px",
}) => {
  return (
    <StyledCategoryCarousel
      className={`StyledCategoryCarousel ${className}`}
      fullWidth={fullWidth}
      hideCategory={!!hideCategory}
    >
      <h2
        className={`self-start ${
          isCatalog ? "lg:self-start" : "lg:self-center"
        } pl-4 lg:pl-0`}
      >
        {categoryName}
      </h2>
      {!!categoryDescription && (
        <p className="hidden lg:block">{categoryDescription}</p>
      )}
      <div
        className={`${
          fullWidth ? "max-w-full" : "max-w-theme mx-auto"
        }  w-full lg:px-10 px-0  mt-4`}
      >
        <Carousel
          slidesToShow={slidesToShow}
          slidesToScroll={1}
          infinite={false}
          dots={false}
          responsive={[
            {
              breakpoint: 1280,
              settings: {
                slidesToShow: slidesToShow - 1,
                slidesToScroll: 1,
                infinite: false,
                dots: false,
                arrows: true,
                centerMode: false,
              },
            },
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: slidesToShowMobile,
                slidesToScroll: 1,
                infinite: true,
                dots: true,
                arrows: false,
                centerMode: true,
                centerPadding: currentCardPaddingMobile,
              },
            },
          ]}
        >
          {productions?.map((production: ProductionEntity, i: number) => {
            return (
              <Card
                isCatalog
                key={`card${i}-${production.uuid}`}
                className="px-2 lg:px-4"
                productionData={production}
              />
            );
          })}
        </Carousel>
      </div>
    </StyledCategoryCarousel>
  );
};
