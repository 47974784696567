import { Routes, Route } from "react-router-dom";
import React from "react";
import {
  Catalog,
  Content,
  CreatePassword,
  Faqs,
  Home,
  Login,
  RecoverPassword,
  Register,
  Preview,
  Profile,
  Subscriptions,
  PageNotFound,
  Rentals,
  Dashboard,
  SubscriptionDetail,
  SearchPage,
  LearningPathsLanding,
  LearningPathDetail,
  LearningPathCalendar,
  CodeValidation,
} from "../../pages";
import { PrivateRoute } from "./PrivateRoute";

export const AppRoutes = () => (
  <Routes>
    {/* Public */}
    <Route path="/" element={<Home />} />
    <Route path="/home" element={<Home />} />
    <Route path="/content/:slug" element={<Content />} />
    <Route path="/code-validation" element={<CodeValidation />} />
    <Route path="/catalog" element={<Catalog />} />
    <Route path="/recover-password" element={<RecoverPassword />} />
    <Route path="/reset-password" element={<CreatePassword />} />
    <Route path="/signup" element={<Register />} />
    <Route path="/login" element={<Login />} />
    <Route path="/preview" element={<Preview />} />
    <Route path="/subscriptions" element={<Subscriptions />} />
    <Route path="/search" element={<SearchPage />} />
    <Route path="/faqs" element={<Faqs />} />
     <Route path="/learning-path" element={<LearningPathsLanding />} />
    <Route path="/learning-path-detail" element={<LearningPathDetail />} />
    <Route path="/learning-path-calendar" element={<LearningPathCalendar />} />
    <Route path="/rentals" element={<Rentals />} />

    <Route path="*" element={<PageNotFound />} />

    {/* Private */}
    <Route
      path="/profile"
      element={
        <PrivateRoute>
          <Profile />
        </PrivateRoute>
      }
    />
    <Route
      path="/subscription-detail"
      element={
        <PrivateRoute>
          <SubscriptionDetail />
        </PrivateRoute>
      }
    />

    <Route
      path="/dashboard"
      element={
        <PrivateRoute>
          <Dashboard />
        </PrivateRoute>
      }
    />
  </Routes>
);
