import tw from "twin.macro"
import styled from "styled-components"
import { TextFieldControlled } from "../TextField";

export const StyledNavbar = styled.ul.attrs(props=>({
    className:props.className
  }))`
    ${tw`
        flex items-center  justify-between
        bg-secondary-200 
        py-2
        mb-5
        px-5
        sm:(hidden)
    `}
`;

export const StyledItem= styled.li.attrs(props=>({
  className:props.className
}))`
  ${tw` text-gray-400`}
`

export const StyledSearchTextField = styled(TextFieldControlled).attrs(props=>({
  className:props.className
}))
`
${tw `mb-4`}
#searchTerm{
  ${tw`h-10 bg-secondary-50 rounded-none border-none text-white`}
}
.StyledIconContainer {
  ${tw`h-10`}
}

`