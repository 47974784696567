import { FC } from 'react'

interface IconStarProps{
    color?: string,
    width?:string,
    height?:string,
} 


export const IconStar:FC<IconStarProps> = ({color="#C1101A",width="19px",height="18px"}:IconStarProps) => {
    return (
        <svg  width={width} height={height} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M9.20313 0.159058L11.618 6.29835L18.2031 6.69796L13.1106 10.8916L14.7654 17.2779L9.20313 13.7305L3.64081 17.2779L5.29569 10.8916L0.203125 6.69796L6.78826 6.29835L9.20313 0.159058Z" fill={color}/>
        </svg>

    );
};
