import {StyledNavbar, StyledItem, StyledSearchTextField} from "./Navbar.styles"
import { Link } from "react-router-dom";
import { ReactComponent as StoryTrainingTextlessLogo } from "../../assets/images/storyTrainingTextlessLogo.svg";
import { StyledSearchBar } from "../Header";
import { TextFieldControlled } from "../TextField";
import { Button } from "../Button";
import { useHeader } from "../Header/useHeader";
import  searchIcon  from "../../assets/images/searchIcon.svg"
import { useState } from "react";
import { CloseIcon } from "../../assets/Icons";
/* 
interface NavbarProps {
    navBarItems?: NavbarItemProps[];
    className?: string; 
} */

interface NavbarItemProps {
    name: string;
    url: string;
}

const navBarItems = [
    {
     name: "Mi storytraining",
     url: "/dashboard"
    },
    {   
      name: "Catálogo",
      url: "/catalog"
    },
    {
      name: "Buscar",
      url: "/search"
    },
  ]

export const Navbar = () => {
  const [showSearchbar, setShowSearchBar] = useState(true)
  const { handleSubmit, control, onSubmit,token } = useHeader();
    return(
      <div className="sm:hidden">
        {showSearchbar ? 
        <StyledNavbar >
            <StoryTrainingTextlessLogo className="StoryTrainingTextlessLogo" />
            <StyledItem>
                 {token ? <Link key={1} to={"/dashboard"} className="mx-2">
                   {"Mi storytraining"}
                 </Link>:
                  <Link key={1} to={"/subscriptions"} className="mx-2">
                  {"Suscripciones"}
                </Link>
                  }
                 <Link key={2} to={"/catalog"}  className="mx-2">
                   {"Catálogo"}
                 </Link>
                 <Link key={3} to={""} onClick={() => setShowSearchBar(false)} className="mx-2">
                   {"Buscar"}
                 </Link>
              </StyledItem>
        </StyledNavbar> 
        : 
        <StyledSearchBar>
           <StyledSearchTextField iconSrc={searchIcon} placeholder="Buscar soft skills, áreas o series" id="searchTerm" name="searchTerm" control={control}/>
           <div className="h-10 bg-secondary-50 rounded-none border-none ">
           <CloseIcon  onClick={() => setShowSearchBar(true)} className="w-7 h-7 my-1"/>
           </div>
           <div className="hidden">
           <Button onClick={handleSubmit(onSubmit)}>Buscar</Button>
           </div>
         </StyledSearchBar>
        }
      </div>
    );
}