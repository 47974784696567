import tw from "twin.macro";
import styled from "styled-components";

export const StyledPodcastContainer = styled.div.attrs({
  className: "StyledPodcastContainer",
})`
  ${tw`relative max-w-sm w-full mx-auto px-2 lg:(max-w-theme px-16) pb-16 flex flex-col gap-4 items-center justify-center text-center`}
  > h3 {
    ${tw`text-2xl lg:text-2 font-primaryBold text-primary-100`}
  }
  > p {
    ${tw`hidden pt-4 max-w-[1110px] text-xl leading-6 text-white lg:(p-0 block)`}
    letter-spacing: -0.02em;
  }
  > div {
    ${tw` max-w-[1110px]`}
  }
`;
