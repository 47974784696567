import tw from "twin.macro";
import styled from "styled-components";
import { styled as styledMUI } from "@mui/material/styles";
import LinearProgress from "@mui/material/LinearProgress";

export interface StyledMiniProgressCardProps {
  isCardUnique?: boolean;
  id?: string;
}

export interface StyledCakeProgressProps {
  progress?: number;
}

export const StyledMiniProgressCard = styled.div.attrs((props) => ({
  className: "StyledMiniProgressCard",
}))<StyledMiniProgressCardProps>`
  ${tw`relative text-white rounded-xl  px-4 flex gap-2 mx-auto h-full`}

  > h3 {
    ${tw`text-sm font-primaryBold`}
  }

  > h4 {
    ${tw`m-0 text-xs font-primaryRegular`}
  }
  img {
    ${tw`w-[62px] h-[80px]`}
  }

  ${({ id }) => {
    switch (id) {
      case "green_4eC39Hq":
        return `background: linear-gradient(156.71deg, #45AA4F 28.16%, #0C3F11 100%); .progressContainer{display:none;}`;
      case "gold_4eC39Hq":
        return `background: linear-gradient(125.21deg, #FFB500 3.61%, #FFFFFF 16.89%, #FFB500 31.54%, #FFB500 72.41%, #7C5903 100.92%); color:#000000; .progressContainer{display:none;}`;
      default:
        return "background: linear-gradient(341.28deg, #001273 12.6%, #00ABE4 77.64%);";
    }
  }}
  ${({ isCardUnique }) =>
    isCardUnique
      ? `
      height: 100%;
      flex-direction:flex-row; 
      gap: 32px;
      padding: 32px 24px; 
       h3 {
        font-family: "Carnero-Bold", "sans-serif";
        font-size: 24px;
        line-height: 24px;
      }
    
      h4 {
        font-family: "Carnero-SemiBold", "sans-serif";
        font-size: 16px;
        line-height: 22px;
      }
      img {
        width:92px;
        height:120px;
      }
      `
      : tw`py-2.5 flex-col max-w-[200px]`}
`;

export const StyledLinearProgressPath = styledMUI(LinearProgress)((props) => ({
  margin: "0px",
  height: "10px",
  width: "100%",
  display: "relative",
  bottom: "0px",
  zIndex: "10",
  backgroundColor: "#232323",

  [props.theme.breakpoints.down(1024)]: {
    height: "10px",
  },
  "& .MuiLinearProgress-bar1Determinate": {
    backgroundColor: "#00ABE4",
  },
  "& .MuiLinearProgress-bar2Determinate": {
    backgroundColor: "#FFFFFF",
  },
}));

export const StyledCakeProgress = styled.div.attrs((props) => ({
  className: "StyledCakeProgress",
}))<StyledCakeProgressProps>`
  ${tw`relative bg-secondary-100 w-10 inline-grid place-content-center`}
  border-radius: 50%;
  aspect-ratio: 1;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  &:before {
    content: "";
    position: absolute;
    border-radius: 50%;
    inset: 0;
    ${({ progress = 0 }) =>
      `background: conic-gradient(#00abe4 calc(${progress} * 1%), #0000 0);`}
  }
`;
