import {
  AuthenticationService,
  changePasswordInput,
} from "../../services/authentication.service";

export function useCaseResetPassword(service: AuthenticationService) {
  return {
    run({
      token,
      email,
      password,
      passwordConfirmation,
      success,
      error,
    }: changePasswordInput) {
      return service.changePassword({
        token,
        email,
        password,
        passwordConfirmation,
        success,
        error,
      });
    },
  };
}
