import tw from "twin.macro";
import styled from "styled-components";

export const StyledContentSection = styled.section.attrs({
  className: "StyledContentSection",
})`
  ${tw`bg-white p-8 lg:p-16`}
  min-height: calc(100vh - 574px); // min height without header and footer
  @media (max-width: 1024px) {
    ${tw`bg-transparent`}
    min-height: calc(100vh - 130px); // min height without header (mobile)
  }
`;

export const StyledContentContainer = styled.div.attrs({
  className: "StyledContentContainer",
})`
  ${tw`text-white max-w-theme mx-auto flex flex-col gap-y-2 font-primaryRegular lg:(text-black)`}

  h1,h2,h3,h4,h5,h6 {
    ${tw`font-primaryBold self-center lg:(self-start)`}
  }

  ol,
  ul {
    ${tw`pl-4`}
    list-style: auto;
  }
`;
