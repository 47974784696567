import { useState } from "react";
import { useCustomEffect, useNavigation } from "../../hooks";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FieldValues } from "react-hook-form";
import {
  useCaseCoupons,
  useCaseProfile,
  useCaseSubscriptions,
} from "../../../domain/useCases";
import {
  authenticationDataService,
  couponsDataService,
  subscriptionsDataService,
} from "../../../data/services";
import { SubscriptionEntity } from "../../../domain/entities";
import { contactModalSchema } from "../../validators/contactModalSchema";
import companySubscription from "../../assets/images/companySubscription.svg";
import subscriptionsUfo from "../../assets/images/subscriptionsUfo.svg";
import { useSelector } from "react-redux";
import {
  getSessionToken,
  getSubscriptionStatus,
} from "../../../data/dto/selectors";

export function useSubscriptionsViewModel() {
  const { navigateTo } = useNavigation();
  const token = useSelector(getSessionToken);
  const userSubscription = useSelector(getSubscriptionStatus);

  const [subscriptionsList, setSubscriptionsList] = useState<
    Array<SubscriptionEntity> | []
  >([]);
  const [showContactFormModal, setShowContactFormModal] = useState(false);
  const [showContactFormSuccessModal, setShowContactFormSuccessModal] =
    useState(false);
  const [showGoToLoginModal, setShowGoToLoginModal] = useState(false);
  const [newLocationState, setNewLocationState] = useState<{
    [key: string]: any;
  }>({ previousPathname: "/subscriptions" });

  const breadcrumbItemsElements = [
    {
      name: "Inicio",
      url: "/",
    },
    {
      name: "Suscripciones",
    },
  ];
  const { get, submitContactForm } = useCaseSubscriptions(
    subscriptionsDataService()
  );

  /*Contact Form Companies */
  const onSubmit = (data: FieldValues) => {
    const contactFormData = {
      company: data.company,
      email: data.email,
      description: data.description,
      success: () => {
        setShowContactFormModal(false);
        setShowContactFormSuccessModal(true);
      },
    };
    submitContactForm(contactFormData);
  };

  const handleCloseModal = () => {
    setShowContactFormModal(false);
  };

  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
  } = useForm({ mode: "onChange", resolver: yupResolver(contactModalSchema) });

  /* handle subscriptions */
  const onSuccessSubscriptionList = (data: Array<SubscriptionEntity>) => {
    setSubscriptionsList([
      {
        name: "Alquiler",
        description:
          "Podrás alquilar cualquiera de los títulos que componen nuestro catálogo",
        items: [
          "Podrás alquilar cualquiera de los títulos que componen nuestro catálogo",
          "El alquiler estará habilitado durante un periodo de 48 horas.",
        ],
        images: [subscriptionsUfo],
        tag: "",
        tyc: "terminos-y-condiciones",
        minimumTermLabel: "Valor variable según contenido",
        callToAction: () => {
          navigateTo("/rentals");
        },
        callToActionLabel: "Ver precios",
      },
      ...data,
      {
        name: "Empresas",
        items: [
          "Si necesita una propuesta personalizada o información sobre nuestros planes, por favor contáctanos: empresas@storytraining.com",
        ],
        images: [companySubscription],
        tag: "",
        tyc: "content/terminos-y-condiciones",
        minimumTermLabel: "",
        callToAction: () => {
          setShowContactFormModal(true);
        },
        callToActionLabel: "Contáctanos",
      },
    ]);
  };

  const handleCloseGoToLoginModal = () => {
    setShowGoToLoginModal(false);
  };

  const handleCallToActionCard = (priceId: string) => {
    if (token) {
      navigateTo(`/subscription-detail?priceId=${priceId}`);
    } else {
      setNewLocationState({
        ...newLocationState,
        nextPathname: "/subscriptions",
      });
      setShowGoToLoginModal(true);
    }
  };

  /*Code Validation */
  const handlePromoCodeCallToAction = () => {
    if (token) {
      navigateTo(`/code-validation`);
    } else {
      setNewLocationState({
        ...newLocationState,
        nextPathname: "/code-validation",
      });
      setShowGoToLoginModal(true);
    }
  };

  useCustomEffect(() => {
    if (userSubscription?.stripeStatus === "active") {
      navigateTo("/profile");
    } else {
      get({
        success: onSuccessSubscriptionList,
      });
    }
  }, []);

  return {
    navigateTo,
    newLocationState,
    breadcrumbItemsElements,

    subscriptionsList,
    handleCallToActionCard,
    showContactFormModal,
    showContactFormSuccessModal,
    showGoToLoginModal,
    handleCloseGoToLoginModal,

    handleCloseModal,
    onSubmit,
    control,
    errors,
    handleSubmit,
    register,

    handlePromoCodeCallToAction,
  };
}
