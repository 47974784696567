import { LegalService } from "../../domain/services/legal.service";
import { handleResponse } from "../../domain/shared";
import { httpService } from "../http/services/http";
import { Endpoints } from "./endpoints";
import { LegalModel } from "../models";

export interface LegalResponse {
  data: {
    data: {
      legal: LegalModel;
    };
  };
}

export function legalDataService(): LegalService {
  const { get } = httpService();
  return {
    async getLegalFiles({ success, error }: handleResponse) {
      try {
        const response: LegalResponse = await get(Endpoints.legalData);
        if (success) {
          success(response.data.data.legal);
        }
      } catch (e) {
        if (error) {
          error(e);
        }
      }
    },
  };
}
