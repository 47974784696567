import * as yup from "yup";
import {
  alphaLetterSchema,
  emailSchema,
  passwordSchema,
} from "../commonSchemas";

export const editProfileAboutMeSchema = yup.object({
    name: alphaLetterSchema.required("Debes escribir al menos un nombre"),
    lastName: alphaLetterSchema.required("Debes escribir al menos un apellido"),
    country: yup
    .string()
    .notOneOf(["none"], "Campo requerido")
    .required("Campo requerido"),
})

export const editProfilePasswordSchema = yup.object({
  password: passwordSchema,
  newPassword: passwordSchema,
  confirmPassword: passwordSchema
})