import { Dispatch, FC, SetStateAction } from "react";
import { Control, FieldErrorsImpl, FieldValues, UseFormHandleSubmit } from "react-hook-form";
import { CountryEntity, OptionsSelectEntity, UserEntity } from "../../../../../domain/entities";
import { Button, Modal, PasswordField, PasswordFieldControlled, Select, SelectControlled, TextField, TextFieldControlled } from "../../../../components";
import {StyledEditProfileSection, StyledFormSection } from "./EditProfileSection.styles";

interface EditProfileProps {
    countriesData?:  {
        countries: Array<CountryEntity>;
        countriesOptions: Array<OptionsSelectEntity>;
    }
    handleSubmitAboutMe:  UseFormHandleSubmit<FieldValues>;
    controlAboutMe: Control<FieldValues, any>;
    errorsAboutMe: Partial<FieldErrorsImpl<{[x: string]: any;}>>
    editProfile: boolean;
    handleEditProfile: (data?: FieldValues | undefined) => void;
    handleSubmitPassword: UseFormHandleSubmit<FieldValues>;
    controlPassword: Control<FieldValues, any>;
    errorsPassword:  Partial<FieldErrorsImpl<{[x: string]: any;}>>;
    handleEditProfilePassword: (data?: FieldValues | undefined) => void;
    handleEditProfileAboutMe: (data?: FieldValues | undefined) => void;
    showConfirmationModal: boolean;
    handleCloseEditSection: () => void;

}

export const EditProfileSection: FC<EditProfileProps> = ({countriesData, handleSubmitAboutMe, controlAboutMe, errorsAboutMe, editProfile, handleEditProfile,
    handleSubmitPassword, controlPassword, errorsPassword, handleEditProfilePassword, handleEditProfileAboutMe, showConfirmationModal, handleCloseEditSection }) => {
    return(
        <StyledEditProfileSection>
        <Modal showModal={showConfirmationModal} backgroundColor="white" className="text-center">
            <h3 className="font-primaryBold text-primary-100 text-3xl">¡Tu información ha</h3>
            <h3 className="font-primaryBold text-primary-100 text-3xl">sido actualizada!</h3>
            <Button className="max-w-fit mx-auto mt-8 mb-3" onClick={() => handleCloseEditSection()}>Cerrar</Button>
        </Modal>
            
           <StyledFormSection>
            <h2>SOBRE TÍ</h2>
            <form className="px-4 mt-5 flex flex-col gap-y-6 lg:mx-24 lg:px-0">
                <TextFieldControlled
                id={"name"}
                name="name"
                label="Nombres:"
                placeholder="Escribe aquí tus nombres"
                error={!!errorsAboutMe.name}
                helperText={errorsAboutMe?.name?.message}
                control={controlAboutMe} 
                required
                />
                <TextFieldControlled
                id={"lastName"}
                name="lastName"
                label="Apellidos:"
                placeholder="Escribe aquí tus apellidos"
                error={!!errorsAboutMe.lastName}
                helperText={errorsAboutMe?.lastName?.message}
                control={controlAboutMe} 
                required
                />
            {!!countriesData?.countries.length && (
                <SelectControlled
                id={"country"}
                name="country"
                label="País"
                placeholder="Elige tu país de origen"
                error={!!errorsAboutMe.country}
                helperText={errorsAboutMe?.country?.message}
                control={controlAboutMe} 
                options={countriesData?.countriesOptions}
                defaultValue="none"
                required
                />
             )}
                <Button className="max-w-fit mx-auto mt-8 mb-8 lg:mb-16"  onClick={handleSubmitAboutMe(handleEditProfileAboutMe)}>Guardar cambios</Button>
            </form>
           
           </StyledFormSection>

            <StyledFormSection>
            <h2>CONTRASEÑA</h2>
            <form className="mt-5 px-4 flex flex-col gap-y-6 lg:mx-24 lg:px-0">
                <PasswordFieldControlled
                id={"password"}
                name="password"
                label="Contraseña actual:"
                placeholder="Ingresa tu contraseña"
                error={!!errorsPassword.password}
                helperText={errorsPassword?.password?.message}
                control={controlPassword}
                required
                useTooltip={true}
                />
                <PasswordFieldControlled
                id={"newPassword"}
                name="newPassword"
                label="Nueva contraseña:"
                placeholder="Ingresa tu contraseña"
                error={!!errorsPassword.newPassword}
                helperText={errorsPassword?.newPassword?.message}
                control={controlPassword}
                required
                useTooltip={true}
                />
                <PasswordFieldControlled
                id={"confirmPassword"}
                name="confirmPassword"
                label="Repetir nueva contraseña:"
                placeholder="Ingresa tu contraseña"
                error={!!errorsPassword.confirmPassword}
                helperText={errorsPassword?.confirmPassword?.message}
                control={controlPassword} 
                required
                useTooltip={true}
                />
                <Button className="max-w-fit mx-auto mt-8 mb-8 lg:mb-16"  onClick={handleSubmitPassword(handleEditProfilePassword)}>Guardar cambios</Button>
            </form>
            </StyledFormSection> 

        </StyledEditProfileSection>
    )
}