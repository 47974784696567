import { FC } from "react";
import { BannerModel } from "../../../../../data/models";
import { Button, VideoJS } from "../../../../components";
import {
  StyledBannerSection,
  StyledBannerBackground,
  StyledBannerInfoContainer,
  StyledCallToActionContainer,
} from "./Banner.styles";
import dummyBannerImage from "../../../../assets/images/dummyBannerImage.png";
import { useBanner } from "./useBanner";

interface BannerProps {
  bannerData: Partial<BannerModel>;
  subscribed?: string;
}

export const Banner: FC<BannerModel> = ({
  title,
  description,
  video,
  production,
  subscribed
}) => {
  const { videoOptions, navigateTo } = useBanner(video);
  return (
    <StyledBannerSection>
      <StyledBannerBackground>
        {!!video && <VideoJS options={videoOptions} />}
      </StyledBannerBackground>
      <StyledBannerInfoContainer>
        <h1>{title?.value}</h1>
        <p>{description?.value}</p>
      </StyledBannerInfoContainer>
      <StyledCallToActionContainer>
      {!subscribed && <Button
          onClick={() =>
            navigateTo(
             "/subscriptions"
            )
          }
        >
        Suscríbete
        </Button>}
        <h3>{production?.text?.value}</h3>
      </StyledCallToActionContainer>
    </StyledBannerSection>
  );
};
