import { HomeService } from "../../domain/services/home.service";
import { handleResponse } from "../../domain/shared";
import { httpService } from "../http/services/http";
import { Endpoints } from "./endpoints";
import { HomeModel, ProductionsModel } from "../models";

export interface HomeResponse {
  data: {
    data: {
      home: HomeModel;
    };
  };
}

export interface FavoritesProductionsResponse {
  data: {
    data: ProductionsModel;
  };
}

export function homeDataService(): HomeService {
  const { get } = httpService();
  return {
    async getHomeData({ success, error }: handleResponse) {
      // TODO Home logic
      try {
        const response: HomeResponse = await get(Endpoints.homeData);
        if (success) {
          success(response.data.data.home);
        }
      } catch (e) {
        if (error) {
          error(e);
        }
      }
    },
    async getFavoritesProductionsHome({ success, error }: handleResponse) {
      try {
        const response: FavoritesProductionsResponse = await get(
          Endpoints.homeProductions
        );
        if (success) {
          success(response.data.data.items);
        }
      } catch (e) {
        if (error) {
          error(e);
        }
      }
    },
  };
}
