import tw from "twin.macro";
import styled from "styled-components";
import { Card } from "../../../../components";

export const StyledFavorites = styled.div.attrs({
  className: "StyledFavorites",
})`
  ${tw`items-center mx-auto w-full lg:max-w-favoriteDashboard`}
  h2 {
    ${tw`text-white font-primaryBold text-xl my-8 lg:(text-2xl)`}
  }
`;

export const StyledFavoriteCard = styled(Card).attrs({
  className: "StyledFavoriteCard",
})`
  ${tw`relative px-2.5`}
  grid-template-rows: 120px 30px 20px 100px 30px;

  .StyledImage {
    @media (min-width: 1024px) {
      height: 125px;
    }
  }
  h3 {
    ${tw`lg:text-base`}
  }
  .StyledBadge {
    ${tw`text-xs py-1 h-auto max-h-9 text-center`}
  }
`;
