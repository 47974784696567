import { forwardRef, InputHTMLAttributes, useState } from "react";
import { FieldError, FieldErrorsImpl, Merge } from "react-hook-form";
import {
  StyledInput,
  StyledIconContainer,
  StyledLabel,
  StyledHelperText,
} from "./PasswordField.styles";
import { ErrorIcon, EyeCloseIcon, EyeOpenIcon } from "../../assets/Icons";
import { usePasswordField } from "./usePasswordField";
import { ReactComponent as InformationTooltipIcon } from "../../assets/images/informationCircleForTooltip.svg";
import { Tooltip } from "@mui/material";

export interface PasswordProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, "type"> {
  id?: string;
  name?: string;
  label?: string;
  error?: boolean;
  helperText?:
    | string
    | FieldError
    | Partial<{ type: string | number; message: string }>
    | Merge<FieldError, FieldErrorsImpl<any>>;
  placeholder?: string;
  useTooltip?: boolean;
}

export const PasswordField = forwardRef<HTMLInputElement, PasswordProps>(
  (props, ref) => {
    const {
      className,
      id,
      name,
      label,
      error,
      helperText,
      placeholder,
      required,
      useTooltip = false,
      ...rest
    } = props;

    const { visible, onVisibleChange } = usePasswordField();
    const [tooltipIsOpen, setTooltipIsOpen] = useState(false);

    return (
      <div
        className={`relative flex flex-col w-full gap-y-1 items-start text-left ${className}`}
      >
        {!!label && (
          <div>
            {/*  {useTooltip && <Tooltip title="Incluye 5 letras, 1 mayúscula, 2 números y 1 símbolo" onClose={() => setTooltipIsOpen(false)} open={tooltipIsOpen} onOpen={() => setTooltipIsOpen(true)}>
              <InformationTooltipIcon/>
            </Tooltip>} */}
            <StyledLabel htmlFor={id}>
              {label}{" "}
              {!!required && <span className="text-primary-100"> *</span>}
            </StyledLabel>
          </div>
        )}
        <div className="w-full relative">
          <StyledInput
            type={visible ? "text" : "password"}
            error={error}
            id={id}
            name={name}
            placeholder={placeholder}
            ref={ref}
            {...rest}
          />

          <StyledIconContainer>
            {!!error && (
              <Tooltip
                title="Incluye 5 letras, 1 mayúscula, 2 números y 1 símbolo"
                onClose={() => setTooltipIsOpen(false)}
                open={tooltipIsOpen}
                onOpen={() => setTooltipIsOpen(true)}
              >
                <div>
                  <ErrorIcon width="20px" height="20px" />
                </div>
              </Tooltip>
            )}
            {visible ? (
              <EyeOpenIcon
                className="cursor-pointer"
                onClick={() => onVisibleChange()}
              />
            ) : (
              <EyeCloseIcon
                className="cursor-pointer"
                onClick={() => onVisibleChange()}
              />
            )}
          </StyledIconContainer>
        </div>
        {!!error && !!helperText && (
          <StyledHelperText>{helperText as string}</StyledHelperText>
        )}
      </div>
    );
  }
);
