import { useState } from "react";

export const usePasswordField = () => {
  const [visible, setVisible] = useState<boolean>(false);
  const onVisibleChange = () => {
    setVisible(!visible);
  };
  return {
    visible,
    onVisibleChange,
  };
};
