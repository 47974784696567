import { FC } from "react";
import { NotificationsModel } from "../../../../../data/models";
import { NotificationEntity } from "../../../../../domain/entities";
import { ReactComponent as BellNotificationLogo } from "../../../../assets/images/bellNotificationIcon.svg";
import { ReactComponent as DownArrowLogo } from "../../../../assets/images/downArrowForNotifications.svg";
import { Notification } from "../../../../components";
import { StyledNotificationEmpty, StyledNotificationSection, } from "./NotificationSection.styles";

interface NotificationsProps {
    notifications: NotificationsModel;
    handleDeleteNotification: (notification: NotificationEntity) => void;
    handleGetNotifications: (pageNumber: number) => void; 
}

export const NotificationSection: FC<NotificationsProps> = ({notifications, handleGetNotifications, handleDeleteNotification}) => {

    return (
        <StyledNotificationSection>
                <div className="flex justify-center">
                <hr className="pt-0 w-full opacity-50 border-t-2 sm:hidden border-white  mx-6" />
                </div>
                <div className="flex flex-row items-center m-6">
                    <h2>NOTIFICACIONES</h2>
                    <BellNotificationLogo className="bellNotificationLogo"/>
                </div>
                <div className="flex flex-col items-center">
                    {notifications.items.length > 0 ? 
                    
                    notifications.items?.map((notification, index) => (
                        <Notification key={`N-${index}`} notification={notification} handleDeleteNotification={handleDeleteNotification}/>
                    ))
                    :
                    <StyledNotificationEmpty>
                        <p>Sin notificaciones</p>
                    </StyledNotificationEmpty>
                    } 
                    {notifications.currentPage < notifications.pages && <DownArrowLogo className="downArrowLogo" onClick={() => {handleGetNotifications(notifications.currentPage+1)}}/>}
                </div>
        </StyledNotificationSection>
    )
}