import { FC } from "react";
import { HistoryInfoCard } from "../../../../components/HistoryInfoCard";
import {
  StyledContainerCarouselHistory,
  StyledHistorySection,
} from "./HistorySection.styles";
import computerLogo from "../../../../assets/images/computerLogo.svg";
import coursesLogo from "../../../../assets/images/iconCourses.svg";
import spaceshipLogo from "../../../../assets/images/spaceship.svg";
import { Carousel } from "../../../../components";

export interface HistorySectionProps {
  className?: string;
}

export const HistorySection: FC<HistorySectionProps> = ({
  className,
}: HistorySectionProps) => {
  return (
    <StyledHistorySection>
      <div className="w-full flex justify-center mt-8">
        <hr className="pt-0 w-72 opacity-50 border-t-2 sm:hidden border-white mb-10" />
      </div>
      <h1 className="px-9">¡Forma parte de nuestra historia!</h1>
      <div className="flex flex-row justify-center mt-16 max-w-4xl mx-auto ">
        <StyledContainerCarouselHistory>
          <Carousel
            slidesToShow={3}
            slidesToScroll={1}
            infinite={false}
            dots={false}
            centerMode={true}
            responsive={[
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  infinite: true,
                  dots: true,
                  arrows: false,
                  centerMode: true,
                  centerPadding: "45px",
                },
              },
            ]}
          >
            <HistoryInfoCard
              imageLogo={computerLogo}
              title="Cuando quieras, donde quieras"
              description="Mejora tus habilidades profesionales a través del poder de las historias, desde cualquier lugar y en cualquier momento."
            />
            <HistoryInfoCard
              imageLogo={coursesLogo}
              title="Nuestro contenido"
              description="Entretenido, divertido y fácil. Series de Tv, películas y documentales serán tu vehículo de formación."
            />
            <HistoryInfoCard
              imageLogo={spaceshipLogo}
              title="¡Comienza ya!"
              description="Elige una suscripción y disfruta de producciones originales y nuevos lanzamientos."
            />
          </Carousel>
        </StyledContainerCarouselHistory>
      </div>
    </StyledHistorySection>
  );
};

export default HistorySection;
