import { StyledDefaultHeader, StyledSearchBar } from "./Header.styles";
import { ReactComponent as StoryTrainingLogo } from "../../assets/images/storyTrainingLogo.svg";
import searchIcon from "../../assets/images/searchIcon.svg";
import {
  Button,
  Navbar,
  ProfileDropDown,
  TextFieldControlled,
} from "../../components";
import { FC } from "react";
import { useLoginViewModel } from "../../viewModels";
import { useNavigation } from "../../hooks";
import { useHeader } from "./useHeader";

export interface HeaderProps {
  className?: string;
  isAbsolute?: boolean;
}

export const Header: FC<HeaderProps> = ({ className, isAbsolute }) => {
  const {
    token,
    isDesktop,
    navigateTo,
    primaryButtonData,
    handleSubmit,
    control,
    onSubmit,
  } = useHeader();
  return (
    <StyledDefaultHeader
      className={`StyledDefaultHeader ${className} ${
        isAbsolute ? "absolute" : "relative"
      }`}
    >
      <StoryTrainingLogo
        onClick={() => navigateTo("/")}
        className="StoryTrainingLogo cursor-pointer"
      />
      <nav>
        <ul className="flex gap-x-4 items-center  h-full">
          <li className="CatalogOption">
            <Button color="secondary" onClick={() => navigateTo("/catalog")}>
              Catálogo
            </Button>
          </li>
          <li className="FaqButtonOption">
            <Button color="secondary" onClick={() => navigateTo("/faqs")}>
              Preguntas frecuentes
            </Button>
          </li>
          <li className="SearchBarOption">
            <StyledSearchBar>
              <TextFieldControlled
                iconSrc={searchIcon}
                placeholder="Buscar soft skills, áreas o series"
                id="searchTerm"
                name="searchTerm"
                control={control}
              />
              <Button onClick={handleSubmit(onSubmit)}>Buscar</Button>
            </StyledSearchBar>
          </li>
          {!token ? (
            <li>
              <Button
                color={primaryButtonData.isAuth ? "secondary" : "primary"}
                colorText={
                  primaryButtonData.isAuth
                    ? `${isDesktop ? "#C1101A" : "#FFFFFF"}`
                    : "#FFFFFF"
                }
                onClick={() => navigateTo(primaryButtonData.redirectTo)}
              >
                {primaryButtonData.label}
              </Button>
            </li>
          ) : (
            <li>
              <ProfileDropDown />
            </li>
          )}
        </ul>
      </nav>
      {/* <div className="SearchBarOptionMobile mt-4">
          <StyledSearchBar>
            <TextFieldControlled iconSrc={searchIcon} placeholder="Buscar soft skills, áreas o series" id="searchTerm" name="searchTerm" control={control}/>
            <Button onClick={handleSubmit(onSubmit)}>Buscar</Button>
          </StyledSearchBar>
      </div>  */}
    </StyledDefaultHeader>
  );
};
