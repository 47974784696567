import { FC } from "react";
import { DefaultLayout } from "../../layouts";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  CheckBoxControlled,
  Modal,
  PasswordFieldControlled,
} from "../../components";
import { useCreatePasswordViewModel } from "../../viewModels";

export const CreatePassword: FC = () => {
  const {
    breadcrumbItemsElements,

    showModalResetPasswordSuccess,
    handleCloseModal,

    handleSubmit,
    onSubmit,
    control,
    errors,
  } = useCreatePasswordViewModel();

  return (
    <DefaultLayout>
      <Breadcrumb
        className="px-8 lg:px-14 "
        breadcrumbItems={breadcrumbItemsElements}
      />

      <section className="flex flex-col items-center max-w-screen-md mx-auto my-14 lg:my-20 px-8 lg:px-0 text-white text-center">
        <h1 className="font-primaryBold text-white text-3xl lg:text-3.5 text-center">
          Crea una nueva contraseña
        </h1>
        <p className="text-white text-center font-primaryRegular text-base lg:text-2xl my-4">
          Actualiza tu contraseña
        </p>
        <form className="mt-16 w-full flex flex-col gap-y-6  max-w-inputForm">
          <PasswordFieldControlled
            id={"password"}
            name="password"
            label="Contraseña Nueva:"
            placeholder="Incluye 5 letras y 2 caracteres números"
            error={!!errors.password}
            helperText={errors?.password?.message}
            control={control}
            required
          />
          <PasswordFieldControlled
            id={"passwordConfirmation"}
            name="passwordConfirmation"
            label="Repetir Contraseña:"
            placeholder="Incluye 5 letras y 2 caracteres números"
            error={!!errors.passwordConfirmation}
            helperText={errors?.passwordConfirmation?.message}
            control={control}
            required
          />
          <CheckBoxControlled
            id={"remindMe"}
            name="remindMe"
            label="Recordar mis datos"
            error={!!errors.remindMe}
            helperText={errors?.remindMe?.message}
            control={control}
          />
          <div className="max-w-fit mx-auto">
            <Button onClick={handleSubmit(onSubmit)} className="">
              Guardar
            </Button>
          </div>
        </form>
        <p className="text-white text-center font-primarySemibold text-sm lg:text-base mb-4 mt-8">
          Para volver a la pantalla de Iniciar Sesión
          <Link className="text-primary" to={"/login"}>
            {" "}
            haz click aquí
          </Link>
        </p>
      </section>
      <Modal showModal={showModalResetPasswordSuccess} backgroundColor="white">
        <div className="px-4 lg:px-12 lg:py-4 text-center flex flex-col items-center justify-center gap-y-8 max-w-lg">
          <h3 className="text-2xl lg:text-2 font-primaryBold text-primary-100">
            ¡Tu contraseña ha sido actualizada!
          </h3>
          <Button onClick={handleCloseModal}>Volver a iniciar sesión</Button>
        </div>
      </Modal>
    </DefaultLayout>
  );
};
