import * as yup from "yup";
import { passwordSchema } from "../commonSchemas";

export const createPasswordSchema = yup.object({
  password: passwordSchema,
  passwordConfirmation: passwordSchema.oneOf(
    [yup.ref("password"), null],
    "Las contraseñas deben coincidir"
  ),
});
