import tw from "twin.macro";
import styled from "styled-components";

export const StyledCarouselPreview = styled.div.attrs({

})`
  ${tw`max-w-preview mt-16 px-4 flex flex-col items-center gap-y-4 mx-auto w-full`}
  img {
    max-height: 209px;
  }
  .Badge {
    display: none;
  }
`;
