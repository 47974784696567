import tw from "twin.macro";
import styled from "styled-components";
import { Button } from "../../components";
import profileBannerImage from "../../assets/images/profileBannerImage.png";

export const StyledProfileContainer = styled.div.attrs({
  className: "StyledProfileContainer",
})`
  ${tw``}
`;

export const StyledProfileInfoSection = styled.section.attrs({
  className: "StyledProfileInfoSection",
})`
  ${tw` w-full  p-12 `}
`;

export const StyledUserInfoContainer = styled.div.attrs({
  className: "StyledUserInfoContainer",
})`
  ${tw` max-w-theme pb-6 text-white flex flex-col gap-4 items-center justify-center text-center lg:(pb-0 flex-row text-left)`}

  h2, h1 {
    ${tw`tracking-tight font-primaryBold text-2xl lg:(hidden)`}
  }
  h3 {
    ${tw`tracking-tight font-primaryBold text-2xl lg:(text-2 leading-9 tracking-tight font-primaryBold)`}
  }

  p {
    ${tw`relative flex items-center gap-x-3.5  tracking-tight font-primaryBold lg:(text-base)`}
  }
`;

export const StyledProductionsInfoContainer = styled.div.attrs({
  className: "StyledProductionsInfoContainer",
})`
  ${tw` max-w-theme flex gap-x-16 items-center `}

  h4 {
    ${tw`text-primary-100 text-2 leading-9 tracking-tight font-primaryBold`}
  }

  p {
    ${tw`relative flex items-center gap-x-2 text-white lg:(text-2xl tracking-tight font-primaryBold)`}
    span {
      ${tw`absolute left-[-24px]`}
    }
  }
`;

export const StyledButtonEdit = styled(Button).attrs({
  className: "StyledButtonEdit",
})`
  ${tw`p-0 lg:(mr-auto)`}
`;

export const StyledProfileSubscriptionSection = styled.section.attrs({
  className: "StyledProfileSubscriptionSection",
})`
  ${tw`w-full h-80 bg-cover  text-white px-6 py-7 flex flex-col justify-between mb-28 lg:(mb-0 px-12 bg-center)`}
  background-image: url(${profileBannerImage});

  h2 {
    ${tw`tracking-tight font-primaryBold flex items-center text-xl gap-x-2 lg:(text-2  gap-x-4)`}
  }

  p {
    ${tw`tracking-tight font-primaryBook  flex gap-x-2  text-base lg:(text-2xl)`}
    span {
      ${tw`text-primary-50 font-primarySemibold capitalize`}
    }
  }
`;

export const StyledSubscriptionImage = styled.img.attrs({
  className: "StyledSubscriptionImage",
})`
  ${tw`absolute bottom-[-20%] left-[5vw] lg:( left-[15vw])`}
`;
