import { AxiosError } from "axios";
import { useState } from "react";
import { useSelector } from "react-redux";
import {
  getSessionToken,
  getSubscriptionStatus,
} from "../../../data/dto/selectors";
import {
  MyStorytrainingInProgressModel,
  SavedProductionsModel,
} from "../../../data/models";
import { homeDataService } from "../../../data/services";
import { dashboardDataService } from "../../../data/services/dashboard.data.service";
import {
  CategoryEntity,
  MedalEntity,
  MiniMedalEntity,
  ProductionEntity,
  ProgressByAreaEntity,
} from "../../../domain/entities";
import { useCaseHome } from "../../../domain/useCases";
import { useCaseDashboard } from "../../../domain/useCases/dashboard";
import { useCustomEffect, useNavigation } from "../../hooks";

export function useDashboardViewModel() {
  const [savedProductions, setSavedProductions] = useState<
    Array<ProductionEntity>
  >([]);
  const [savedPodcastProductions, setSavedPodcastProductions] = useState<
    Array<ProductionEntity>
  >([]);
  const [productionsInProgress, setProductionsInProgress] = useState<
    Array<MyStorytrainingInProgressModel> | []
  >([]);
  const [podcastInProgress, setPodcastInProgress] = useState<
    Array<MyStorytrainingInProgressModel> | []
  >([]);
  const [favoritesProductions, setFavoritesProductions] = useState<
    Array<CategoryEntity> | []
  >([]);
  const [medalsCompletedByUser, setMedalsCompletedByUser] = useState<
    Array<MiniMedalEntity> | []
  >([]);
  const [medalsInProgressByUser, setMedalsInProgressByUser] = useState<
    Array<MiniMedalEntity> | []
  >([]);
  const [progressByArea, setProgressByArea] = useState<
    Array<ProgressByAreaEntity> | []
  >([]);
  const {
    getSavedProductionsDashboard,
    getInProgressProductionsDashboard,
    getMedalsUserDashboard,
    getProgressByArea,
  } = useCaseDashboard(dashboardDataService());
  const { getFavorites } = useCaseHome(homeDataService());
  const { navigateTo } = useNavigation();
  const token = useSelector(getSessionToken);
  const userSubscription = useSelector(getSubscriptionStatus);
  function successSavedProduction(productions: SavedProductionsModel) {
    const productionList = productions.items;

    const videoProductionsList = productionList.filter(
      (currentProduction) =>
        currentProduction.productionType !== "podcast" &&
        currentProduction.productionType !== "juegos"
    );

    const podcastProductionsList = productionList.filter(
      (currentProduction) => currentProduction.productionType === "podcast"
    );

    setSavedProductions(videoProductionsList);
    setSavedPodcastProductions(podcastProductionsList);
  }

  function errorSavedProduction(e: AxiosError) {
    console.log("errorProductionSaved", e);
  }

  function successInProgressProduction(
    productions: Array<MyStorytrainingInProgressModel>
  ) {
    const videoProductionsList = productions.filter(
      (currentProduction) => currentProduction.title.toUpperCase() !== "PODCAST"
    );

    const podcastProductionsList = productions.filter(
      (currentProduction) => currentProduction.title.toUpperCase() === "PODCAST"
    );
    setProductionsInProgress(videoProductionsList);
    setPodcastInProgress(podcastProductionsList);
  }

  function errorInProgressProduction(e: AxiosError) {
    console.log("errorProductionInProgress", e);
  }

  const onGetHomeFavoritesSuccess = (
    favoritesProductions: Array<CategoryEntity>
  ) => {
    setFavoritesProductions(favoritesProductions);
  };

  function successMedalsUser(medals: Array<MiniMedalEntity>) {
    const completedMedals = medals.filter(
      (medal) => medal.status === "completed"
    );
    const inProgressMedals = medals.filter(
      (medal) => medal.status !== "completed"
    );
    setMedalsCompletedByUser(completedMedals);
    setMedalsInProgressByUser(inProgressMedals);
  }

  function errorMedalsUser(e: AxiosError) {
    console.log("errorMedalsUser", e);
  }

  function successProgressByArea(progress: Array<ProgressByAreaEntity>) {
    setProgressByArea(progress);
  }

  function errorProgressByArea(e: AxiosError) {
    console.log("errorProgressByArea", e);
  }

  useCustomEffect(() => {
    getFavorites({
      success: onGetHomeFavoritesSuccess,
    });
  }, []);

  useCustomEffect(() => {
    if (token) {
      getSavedProductionsDashboard({
        success: successSavedProduction,
        error: errorSavedProduction,
      });
      getInProgressProductionsDashboard({
        success: successInProgressProduction,
        error: errorInProgressProduction,
      });
      getMedalsUserDashboard({
        success: successMedalsUser,
        error: errorMedalsUser,
      });
      getProgressByArea({
        success: successProgressByArea,
        error: errorProgressByArea,
      });
    } else {
      navigateTo("/");
    }
  }, []);

  return {
    navigateTo,
    savedProductions,
    productionsInProgress,
    podcastInProgress,
    favoritesProductions,
    medalsCompletedByUser,
    medalsInProgressByUser,
    savedPodcastProductions,
    userSubscription,
    progressByArea,
  };
}
