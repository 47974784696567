import tw from "twin.macro";
import styled from "styled-components";

export const StyledSaved = styled.div.attrs({
  className: "StyledSaved",
})`
  ${tw`px-4 items-center mx-auto w-full max-w-lg lg:(px-0 max-w-favoriteDashboard)`}
  h2 {
    ${tw`text-white font-primaryBold text-xl lg:(text-2xl)`}
  }
  hr {
    ${tw`hidden lg:(block opacity-50 border-t-2 border-white mb-6 )`}
  }
`;
