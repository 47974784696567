import tw from "twin.macro";
import styled from "styled-components";

export const StyledContainerModal = styled.div.attrs((props) => ({
  className: props.className,
}))`
  ${tw`
  fixed
        w-full
        h-full
        top-0
        left-0
        z-10
        bg-secondary-200
        bg-opacity-50
        
        place-content-center
    `}
`;

export const StyledModal = styled.div.attrs((props) => ({
  className: props.className,
}))`
  ${tw`
  m-4
  relative
  flex 
  justify-between
  rounded-3xl
  lg:(min-w-[26rem])
    `}
`;
