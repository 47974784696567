import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { ratingProductionSchema } from "../../../validators";

export function useRating() {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(ratingProductionSchema),
  });
  return {
    handleSubmit,
    control,
    errors,
  };
}
