import tw from "twin.macro";
import styled from "styled-components";
import { ProductionEntity } from "../../../../../domain/entities";

interface VideoPreviewProps {
  timeLeft: number;
  chapterName: string;
  productionName: string;
  currentSeasonNumber: number;
  nextChapterName?: string;
  nextChapterImage?: string;
  similarProductions: Array<ProductionEntity>;
}

export const StyledVideoPreview = styled.div.attrs({
  className: "StyledVideoPreview",
})<VideoPreviewProps>`
  ${tw`
  // h-[285px]
  // lg:(h-[562px])
  w-full
  mx-auto
  max-w-theme
 `};

  ${({ productionName }) =>
    productionName &&
    `
    .StyledVideo{
    .vjs-control-bar{
      .vjs-title-video{
        width: max-content;
        height: max-content;
        padding: 72px 40px;
        &:before{
          font-size: 24px;
          line-height: 28px;
          font-family: Carnero-Bold, sans-serif;
          color: white;
          content: "${productionName}";
        }
        @media (max-width: 1024px) {
          padding: 24px 24px;
          &:before{
            font-size: 1.4vw;
            line-height: 1.6vw;
          }
        }
      }
    }
}

`}
  ${({ chapterName, currentSeasonNumber }) =>
    chapterName &&
    `
  .StyledVideo{
    .vjs-control-bar{
      .vjs-new-chapter-name{
        position: absolute;
        bottom: 48px;
        left: 40px;
        padding: 16px;
        width: max-content;
        height: max-content;
        background: rgba(13, 13, 13, 0.5);
        border-radius: 12px;
        .span{
          display: none;
        }
        &:before{
          background: rgba(13, 13, 13, 0.5);
          border-radius: 12px;
          font-family: Carnero-Bold, sans-serif;
          color: white;
          font-size: 1.4vw;
          line-height: 2vw;
          content: "${
            currentSeasonNumber
              ? `Temporada ${currentSeasonNumber} - ${chapterName}`
              : chapterName
          }";
        }
        @media (max-width: 1024px) {
          left: 20px;
          padding: 8px;
          border-radius: 8px;
        }
      }
    }
  }
`}

  ${({ nextChapterImage, nextChapterName }) =>
    nextChapterImage &&
    nextChapterName &&
    `
  .StyledVideo {
    .vjs-control-bar {
      .vjs-next-chapter-card{
        position:absolute;
        width: 34%;
        height: 38%;
        top: 50%;
        right: 0;
        border-radius: 0.75rem;
        transform: translate(-56px, -50%);
        background-color: #0D0D0D;
        &:before {
          position:absolute;
          right: 0;
          top: -10%;
          font-family: Carnero-Bold, sans-serif;
          font-size: 1.2vw;
          line-height: 1.4vw;
          height: 1.4vw;
          overflow: hidden;
          text-align: start;
          color: white;
          content: "Siguiente capítulo";
        }
        .vjs-icon-placeholder:before{
          top:5%;
          left:3%;
          width: 94%;
          height: 75%;
          content: "";
          background-image: url(${nextChapterImage});
          background-size: cover;
        }
        .vjs-icon-placeholder:after{
          position: absolute;
          top: 85%;
          left: 3%;
          font-family: Carnero-Bold, sans-serif;
          font-size: 1.2vw;
          line-height: 1.4vw;
          height: 1.4vw;
          overflow: hidden;
          text-align: start;
          content: "${nextChapterName}";
        }
        @media (max-width: 1024px) {
          transform: translate(-48px, -50%);
          padding: 8px;
          border-radius: 8px;
        }
      }
  
    }
  }
`}

  ${({ nextChapterImage, nextChapterName, similarProductions }) =>
    !nextChapterImage && !nextChapterName && similarProductions.length >= 3
      ? `
      .vjs-ended{
        .vjs-play-control{
          display: none;
        }
      }
  .StyledVideo {
    .vjs-control-bar {
      .vjs-similar-productions-container {
        // ${tw`absolute flex gap-x-6 items-center justify-between px-12 py-6  w-full h-[50%] top-1/2`}
        position:absolute;
        width: 100%;
        height: 60%;
        max-height: 384px;
        top: 45%;
        padding: 40px 56px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1.5rem;
        transform: translate(0,-50%);
        @media (max-width: 1024px) {
          max-height: 200px;
        }
        >span {
          display: none;
        }
        .vjs-button {
          // ${tw`h-full p-4 rounded-xl bg-secondary-300 overflow-hidden`}
          height: 100%;
          padding: 8px;
          border-radius: 0.75rem;
          background-color: #0D0D0D;
          overflow: hidden;
          flex: 1 1 auto;
          @media (max-width: 1024px) {
            border-radius: 8px;
          }
        }
        .vjs-first-production{
          .vjs-icon-placeholder{
            width: 100%;
            height: 70%;
            background-image: url(${
              similarProductions[0].imgPreview
            }) !important;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            &:before{
              overflow: hidden;
              height: 34px;
              width: calc(100% - 16px);
              top: 72%;
              left: 16px;
              text-align: left;
              font-family: Carnero-Bold, sans-serif;
              font-size: 1.4vw;
              color: white;
              content:"${similarProductions[0].title}";
              @media (max-width: 1024px) {
                left: 8px;
                width: calc(100% - 16px);
              }
            }
            &:after{
              position: absolute;
              height: min-content;
              width: calc(100% - 16px);
              top: 88%;
              left: 8px;
              text-align: left;
              font-family: Carnero-Book, sans-serif;
              font-size: 1.2vw;
              color: white;
              text-transform: capitalize;
              content:"${similarProductions[0].format}";
              @media (max-width: 1024px) {
                left: 8px;
                width: calc(100% - 16px);
              }
            }
          }
        }
        .vjs-second-production{
          .vjs-icon-placeholder{
            width: 100%;
            height: 70%;
            background-image: url(${similarProductions[1].imgPreview});
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            &:before{
              overflow: hidden;
              height: 34px;
              width: calc(100% - 16px);
              top: 72%;
              left: 8px;
              text-align: left;
              font-family: Carnero-Bold, sans-serif;
              font-size: 1.4vw;
              color: white;
              content:"${similarProductions[1].title}";
              @media (max-width: 1024px) {
                left: 8px;
                width: calc(100% - 16px);
              }
            }
            &:after{
              position: absolute;
              height: min-content;
              width: calc(100% - 16px);
              top: 88%;
              left: 8px;
              text-align: left;
              font-family: Carnero-Book, sans-serif;
              font-size: 1.2vw;
              color: white;
              text-transform: capitalize;
              content:"${similarProductions[1].format}";
              @media (max-width: 1024px) {
                left: 8px;
                width: calc(100% - 16px);
              }
            }
          }
        }
        .vjs-third-production{
          .vjs-icon-placeholder{
            width: 100%;
            height: 70%;
            background-image: url(${similarProductions[2].imgPreview});
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            &:before{
              overflow: hidden;
              height: 34px;
              width: calc(100% - 16px);
              top: 72%;
              left: 8px;
              text-align: left;
              font-family: Carnero-Bold, sans-serif;
              font-size: 1.4vw;
              color: white;
              content:"${similarProductions[2].title}";
              @media (max-width: 1024px) {
                left: 8px;
                width: calc(100% - 16px);
              }
            }
            &:after{
              position: absolute;
              height: min-content;
              width: calc(100% - 48px);
              top: 88%;
              left: 8px;
              text-align: left;
              font-family: Carnero-Book, sans-serif;
              font-size: 1.2vw;
              color: white;
              text-transform: capitalize;
              content:"${similarProductions[2].format}";
              @media (max-width: 1024px) {
                left: 8px;
                width: calc(100% - 16px);
              }
            }
          }
        }
      }
    }
  }
        `
      : ` .StyledVideo {
          .vjs-control-bar {
            .vjs-similar-productions-container {
              display:none;
              .vjs-first-production{display:none;}
              .vjs-second-production{display:none;}
              .vjs-third-production{display:none;}
            }
            `}
`;

export const StyledVideoContainer = styled.div.attrs({
  className: "StyledVideoContainer",
  id: "styled-video-container",
})`
  ${tw`
  relative
  // h-[285px]
  mx-auto
  w-full
  bg-no-repeat
  bg-center
  bg-cover
  // lg:(h-[562px])
 `};
  video {
    object-fit: contain;
  }
`;
export const StyledVideoInfo = styled.ul.attrs((props) => ({
  className: props.className,
}))`
  ${tw`
  flex
  flex-wrap
  gap-x-3
  
  text-white
  bg-gray-700
  py-4
  w-full

  text-sm
  lg:(text-base)
`}

  p {
    ${tw`font-primaryBold`}
  }
  span {
    ${tw`font-primaryBook`}
  }
`;
