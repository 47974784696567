import { FC, ChangeEvent, PropsWithChildren } from "react";
import { ReactComponent as PreviousPageIcon } from "../../assets/images/previousPageIcon.svg";
import { ReactComponent as NextPageIcon } from "../../assets/images/nextPageIcon.svg";

import {
  StyledPaginatorContainer,
  StyledPaginationWithChildren,
} from "./ArrowsPaginator.styles";
import { PaginationItem } from "@mui/material";

interface PaginatorProps {
  className?: string;
  page?: number;
  totalPages?: number;
  onPageChange?: (event: ChangeEvent<unknown>, page: number) => void;
}

export const ArrowsPaginator: FC<PropsWithChildren<PaginatorProps>> = ({
  className,
  page,
  totalPages,
  onPageChange,
  children,
}) => {
  return (
    <StyledPaginatorContainer className={className}>
      <StyledPaginationWithChildren
        renderItem={(item) => (
          <PaginationItem
            slots={{ previous: PreviousPageIcon, next: NextPageIcon }}
            {...item}
          />
        )}
        page={page}
        count={totalPages}
        onChange={onPageChange}
        color={"secondary"}
        shape="rounded"
        boundaryCount={1}
        size="large"
      />
      {children}
    </StyledPaginatorContainer>
  );
};
