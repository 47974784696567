import {
  CouponRedemption,
  CouponsService,
} from "../../services/coupons.service";

export function useCaseCoupons(service: CouponsService) {
  return {
    redeem({ coupon, success, error }: CouponRedemption) {
      return service.redeemCode({ coupon, success, error });
    },
  };
}
