import tw from "twin.macro";
import styled from "styled-components";

export const StyledContactFormSection = styled.section.attrs({
  className: "StyledContactFormSection",
})`
  ${tw`px-5  pb-16 w-full flex flex-col items-center bg-transparent lg:(gap-y-4 pt-16) `}
`;

export const StyledContactForm = styled.form.attrs({
  className: "StyledContactForm",
})`
  ${tw`px-5 lg:px-8 text-center flex flex-col items-center justify-center  max-w-registerModal `}

  >h3 {
    ${tw`
          text-2xl font-primaryBold text-white my-4
      `}
  }

  #information option:checked {
    ${tw`
        bg-primary-100
        text-white
    `}
  }
`;
