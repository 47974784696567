import { useState } from "react"
import { useCustomEffect } from "./useHookEffect"

export function useWindowSize() {
    const [isDesktop, setIsDesktop] = useState(false)
    let width = window.innerWidth

    useCustomEffect(() => {
      if(width > 1023.98){
        setIsDesktop(true)
      }
      else{
        setIsDesktop(false)
      }
    }, [width])

    return {isDesktop}
    
}