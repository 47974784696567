import tw from "twin.macro";
import styled from "styled-components";
import { Table, Select, Modal } from "../../components";

export const StyledPpvSection = styled.section.attrs({
  className: "StyledPpvSection",
})`
  ${tw`w-full h-full max-w-rentals flex flex-col items-center mx-auto py-12  lg:px-8`}
  > h1 {
    ${tw`relative mx-6 text-3xl text-center leading-9 font-primaryBold text-white mb-16 lg:(mb-4 text-[2rem] leading-[2.375rem])`}
    @media (max-width: 1024px) {
      :after {
        content: "";
        left: 0;
        bottom: -32px;
        position: absolute;
        display: block;
        width: 100%;
        height: 0;
        border-bottom: 2px solid #434343;
      }
    }
  }

  > p {
    ${tw`px-6 max-w-[67.5rem] text-center text-base leading-6 text-white mb-8`}
  }
`;

export const StyledTable = styled(Table).attrs({
  className: "StyledTable",
})`
  ${tw`h-full w-full hidden pt-8 lg:block bg-secondary-200 p-8 rounded-[24px]`}
`;

export const StyledSelectTable = styled(Select).attrs({
  className: "StyledSelectTable",
})`
  ${tw`max-w-[260px] mx-auto pb-8 lg:(m-0 p-0)`}

  .MuiInputBase-root {
    ${tw`font-primaryBold text-sm text-center`}
    @media (min-width: 1320px) {
      ${tw`text-lg`}
    }
    @media (max-width: 1024px) {
      ${tw`text-base`}
    }
  }
`;

export const StyledRentalsMobile = styled.div.attrs({
  className: "StyledRentalsMobile",
})`
  ${tw`w-full max-w-xl lg:hidden flex flex-col`}
`;

export const StyledRentalsMobileInnerContainer = styled.div.attrs({
  className: "StyledRentalsMobileInnerContainer",
})`
  ${tw`w-full h-full mx-auto flex flex-col gap-8`}
  .StyledCardRental:nth-of-type(odd) {
    ${tw`bg-secondary-300`}
  }

  .StyledCardRental:nth-of-type(even) {
    ${tw`bg-secondary-50`}
  }
`;

export const StyledCardRental = styled.article.attrs({
  className: "StyledCardRental",
})`
  ${tw`relative w-full grid p-2 gap-6  text-white text-xs sm:text-base`}
  grid-template-columns: 15% auto;
  :after {
    content: "";
    left: 5%;
    bottom: -16px;
    position: absolute;
    display: block;
    height: 0;
    width: 90%;
    opacity: 0.5;
    border-bottom: 2px solid #ffffff;
  }
  > div {
    :before {
      content: "";
      left: -12px;
      top: 0;
      position: absolute;
      display: block;
      width: 0;
      height: 100%;
      opacity: 0.5;
      border-left: 2px solid #ffffff;
    }
  }
  ul > li {
    ${tw`flex gap-4 sm:gap-16 items-center`}
    strong {
      ${tw`min-w-[6rem] w-24 sm:(w-32 min-w-[8rem])`}
    }
    span {
      ${tw`font-primaryBook`}
    }
  }
`;

export const StyledModal = styled(Modal).attrs({
  className: "StyledModal",
})`
  ${tw`place-content-start justify-center absolute w-full`}
  top:0;
  bottom: 0;
  left: 0;
  right: 0;

  .StyledInformationSection,
  .StyledSubscribedContent {
    > * {
      ${tw`max-w-favoriteDashboard mx-auto`}
    }
  }
`;
