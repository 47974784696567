import { FC } from "react";
import { DefaultLayout } from "../../layouts";
import { Link } from "react-router-dom";
import {
  TextFieldControlled,
  Breadcrumb,
  Button,
  Modal,
} from "../../components";
import { useRecoveryPasswordViewModel } from "../../viewModels";
import { EmailIcon } from "../../assets/Icons";

export const RecoverPassword: FC = () => {
  const {
    showModalRecoveryPasswordSuccess,
    handleCloseModal,

    seconds,
    isRunning,

    handleSubmit,
    onSubmit,
    control,
    errors,
  } = useRecoveryPasswordViewModel();
  const breadcrumbItemsElemets = [
    {
      name: "Inicio",
      url: "/",
    },
    {
      name: "Inicia Sesión",
      url: "/login",
    },
    {
      name: "Recuperar contraseña",
    },
  ];
  return (
    <DefaultLayout>
      <Breadcrumb
        className="px-8 lg:px-14 "
        breadcrumbItems={breadcrumbItemsElemets}
      />

      <section className="flex flex-col items-center max-w-screen-md mx-auto my-14 lg:my-20 px-8 lg:px-0 text-white text-center">
        <h1 className="font-primaryBold text-white text-3xl lg:text-3.5 text-center">
          Recupera tu contraseña
        </h1>
        <p className="text-white text-center font-primaryRegular text-base lg:text-2xl my-4">
          Enviaremos un link de recuperación a tu correo electrónico
        </p>
        <form className="mt-16 w-full flex flex-col gap-y-6  max-w-inputForm">
          <TextFieldControlled
            id="email"
            name="email"
            label="Correo electrónico:"
            placeholder="tucorreo@dominio.com"
            error={!!errors.email}
            helperText={errors?.email?.message}
            control={control}
          />
          <Button className="max-w-fit mx-auto" onClick={handleSubmit(onSubmit)}>
            Enviar
          </Button>
        </form>
        <p className="text-white text-center font-primarySemibold text-sm lg:text-base mb-4 mt-8">
          Para volver a la pantalla de Iniciar Sesión
          <Link className="text-primary" to={"/login"}>
            {" "}
            haz click aquí
          </Link>
        </p>
      </section>
      <Modal
        showModal={showModalRecoveryPasswordSuccess}
        backgroundColor="white"
      >
        <div className="px-4 lg:px-9 text-center flex flex-col items-center justify-center gap-y-5 max-w-xl">
          <h3 className="text-2xl lg:text-3xl font-primaryBold text-primary-100">
            Te enviamos un correo electrónico
          </h3>
          <EmailIcon width="96px" height="96px" />

          <p className="font-primaryBook">
            Hemos enviado un correo para el restablecimiento de tu contraseña.
            Revisa tu bandeja de entrada y haz click en el link para continuar
            el proceso
          </p>

          <p className="flex gap-x-1 font-primaryBold">
            ¿No has recibido el link?{" "}
            <span
              className={`flex gap-x-1 text-primary-50 ${
                isRunning ? "cursor-not-allowed" : "cursor-pointer"
              }`}
              onClick={isRunning ? () => {} : handleSubmit(onSubmit)}
            >
              {" "}
              Volver a enviar
              {isRunning && (
                <span className="flex text-primary-50 w-12 max-w-[3.5rem]">
                  {`(${60 - seconds}s)`}
                </span>
              )}
            </span>
          </p>
          <Button onClick={handleCloseModal}>Volver a Inicio de sesión</Button>
        </div>
      </Modal>
    </DefaultLayout>
  );
};
