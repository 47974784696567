import tw from "twin.macro";
import styled from "styled-components";

export const StyledSubscriptionsSection = styled.section.attrs({
  className: "StyledSubscriptionsSection",
})`
  ${tw`relative max-w-theme w-full mx-auto pt-16 flex flex-col gap-4 items-center justify-center text-center lg:pb-16`}
  > h3 {
    ${tw`text-2 font-primaryBold text-white `}
  }
  > p {
    ${tw`pt-4 max-w-[67.5rem] text-base leading-6 text-white lg:(pt-0)`}
  }
`;
