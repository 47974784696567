import { FC, PropsWithChildren } from "react";
import { CloseIcon } from "../../assets/Icons";
import { StyledContainerModal, StyledModal } from "./Modal.styles";

interface ModalProps {
  className?: string;
  title?: string;
  showModal: boolean;
  onClose?: (data?: any) => void;
  backgroundColor?: "black" | "white";
}

export const Modal: FC<PropsWithChildren<ModalProps>> = ({
  className,
  title,
  showModal,
  onClose,
  backgroundColor = "white",
  children,
}) => {
  return (
    <StyledContainerModal
      className={`StyledContainerModal ${className} ${
        showModal ? "grid" : "hidden"
      }`}
    >
      <StyledModal
        className={`StyledModal flex flex-col py-4 lg:py-6 ${
          backgroundColor === "black" ? "bg-black" : "bg-white"
        } `}
      >
        <div className="StyledModalInnerContainer w-full flex justify-between items-center px-4 lg:px-6">
          {!!title && (
            <h3
              className={`font-primaryBold font-bold text-3xl ${
                backgroundColor === "black" ? "text-white" : "text-black"
              }`}
            >
              {title}
            </h3>
          )}
          {!!onClose && (
            <CloseIcon
              className="ml-auto cursor-pointer"
              onClick={onClose}
              color={backgroundColor === "black" ? "#ffffff" : "#8C8C8C"}
            />
          )}
        </div>
        {children}
      </StyledModal>
    </StyledContainerModal>
  );
};
