import { useState } from "react";
import { LegalModel } from "../../../../data/models";
import { legalDataService } from "../../../../data/services";
import { useCaseLegal } from "../../../../domain/useCases";
import { useCustomEffect, useNavigation } from "../../../hooks";

export function useFooter() {
  const { navigateTo } = useNavigation();
  const { getLegal } = useCaseLegal(legalDataService());
  const [legalData, setLegalData] = useState<LegalModel>({} as LegalModel);

  const handleLegalDataSuccess = (legalData: LegalModel) => {
    setLegalData(legalData);
  };

  useCustomEffect(() => {
    getLegal({ success: handleLegalDataSuccess });
  }, []);
  return { legalData, navigateTo };
}
