import { FC } from "react";
import { Button, Modal } from "../../../../components";
import { ReactComponent as RocketLogo } from "../../../../assets/images/rocketLogo.svg";
import { ReactComponent as RocketLogoDown } from "../../../../assets/images/rocketLogoDown.svg";
import { useNavigation } from "../../../../hooks";

interface StatusModalProps {
  status: "none" | "success" | "error";
  showModal: boolean;
  priceId?: string;
  handleSuccessSubscription: (url: string) => void;
  handleRedirectToCheckout?: (priceId: string) => void;
  handleCloseModal?: () => void;
  codeError?: number;
}

export const StatusModal: FC<StatusModalProps> = ({
  status,
  showModal,
  priceId,
  handleSuccessSubscription,
  handleRedirectToCheckout,
  handleCloseModal,
  codeError,
}) => {
  const { navigateTo } = useNavigation();
  let errorMessage = "";

  if (priceId) {
    errorMessage =
      "No hemos podido completar tu solicitud de compra para suscripción, por favor revisa los datos ingresados e inténtalo de nuevo";
  }
  if (!priceId && codeError !== 707) {
    errorMessage =
      "No hemos podido completar la validación de tu código, por favor revisa los dígitos ingresados e inténtalo de nuevo";
  } else if (!priceId && codeError === 707) {
    errorMessage =
      "El código ingresado ya ha sido redimido, por favor revisa los dígitos ingresados e inténtalo nuevamente.";
  }

  return (
    <Modal showModal={showModal && status !== "none"} backgroundColor="white">
      {status === "success" ? (
        <div className="px-4 lg:px-8 text-center flex flex-col items-center justify-center gap-y-4 max-w-registerModal">
          <h3 className="text-2xl font-primaryBold text-primary-100">
            ¡Te damos la bienvenida a storytraining!
          </h3>
          <RocketLogo />
          <p className="font-primaryBook text-sm lg:text-xl">
            Ahora puedes disfrutar de nuestro contenido para mejorar tus soft
            skills; todo tu proceso será registrado en tu perfil ¡Te invitamos a
            explorar nuestra plataforma!
          </p>
          <div className="flex flex-row gap-4 lg:gap-6 w-full justify-center">
            <Button onClick={() => handleSuccessSubscription("/catalog")}>
              Ver el Catálogo
            </Button>
            <Button onClick={() => handleSuccessSubscription("/profile")}>
              Ir a Mi Perfil
            </Button>
          </div>
        </div>
      ) : (
        <div className="px-4 lg:px-8 text-center flex flex-col items-center justify-center gap-y-4 max-w-registerModal">
          <h3 className="text-2xl font-primaryBold text-primary-100">
            {priceId ? "¡Ups! Ocurrió un error" : "Código inválido"}
          </h3>
          <RocketLogoDown />
          <p className="font-primaryBook text-sm lg:text-xl">{errorMessage}</p>
          <div className="flex flex-row gap-6 w-full justify-center">
            <Button color="third" onClick={() => navigateTo("/")}>
              Ir a Inicio
            </Button>
            {handleRedirectToCheckout && priceId ? (
              <Button onClick={() => handleRedirectToCheckout(priceId)}>
                Volver a intentar
              </Button>
            ) : (
              <Button
                onClick={handleCloseModal ? () => handleCloseModal() : () => {}}
              >
                Volver a intentar
              </Button>
            )}
          </div>
        </div>
      )}
    </Modal>
  );
};
