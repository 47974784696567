import { FC, PropsWithChildren } from "react";
import { Header, HeaderProps, Footer } from "../../components";
import { StyledDefaultLayout } from "./DefaultLayout.styles";

export const DefaultLayout: FC<
  PropsWithChildren<Pick<HeaderProps, "isAbsolute">>
> = ({ children, isAbsolute }) => {
  return (
    <StyledDefaultLayout>
      <Header isAbsolute={isAbsolute} />
      <main>{children}</main>
      <Footer />
    </StyledDefaultLayout>
  );
};
