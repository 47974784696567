import { ProductionsSearch, SearchService } from "../../domain/services/search.service";
import { httpService } from "../http/services/http";
import { ProductionsModel, SearchModel } from "../models";
import { Endpoints } from "./endpoints";

export interface ProductionsSearchResponse {
    data: {
      data: SearchModel;
    };
  }

export function searchDataService(): SearchService {
    const { get } = httpService();
    return {
        async getProductionsSearch({search, success, error}: ProductionsSearch){
            try {
                const response: ProductionsSearchResponse = await get(`${Endpoints.search}?q=${search}`);
                if (success) {
                    success(response.data.data);
                }
            } catch (e) {
                if (error) {
                    error(e);
                  }
            }
        }
    }
}