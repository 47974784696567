import {
  SubscriptionService,
  SubscriptionCheckout,
  CompanyContactFormInput,
} from "../../services/subscriptions.service";
import { handleResponse } from "../../shared";

export function useCaseSubscriptions(service: SubscriptionService) {
  return {
    get({ success, error }: handleResponse) {
      return service.getSubscriptionsList({ success, error });
    },
    getCheckout({ priceId, success, error }: SubscriptionCheckout) {
      return service.getCheckoutSubscription({ priceId, success, error });
    },
    submitContactForm({
      company,
      email,
      description,
      success,
      error,
    }: CompanyContactFormInput) {
      return service.sendCompanyContactForm({
        company,
        email,
        description,
        success,
        error,
      });
    },
    getBilling({ success, error }: handleResponse) {
      return service.getBillingUrl({ success, error });
    },
  };
}
