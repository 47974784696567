import tw from "twin.macro";
import styled from "styled-components";
import { styled as styledMUI } from "@mui/material/styles";
import { LinearProgress } from "@mui/material";

export const StyledProgressAreaSection = styled.div.attrs({
  className: "StyledProgressAreaSection",
})`
  ${tw`px-[22px] lg:(px-0)`}
  h3 {
    ${tw`text-2xl font-primaryBold text-white mb-6`}
  }
  p {
    ${tw`text-white py-1 lg:(w-1/2)`}
  }
`;

export const StyledLinearProgressArea = styledMUI(LinearProgress)((props) => ({
  height: "10px",
  width: "100%",
  display: "relative",
  bottom: "0px",
  zIndex: "10",
  backgroundColor: "#FFFFFF",

  [props.theme.breakpoints.up(1024)]: {
    width: "162px",
  },
  "& .MuiLinearProgress-bar1Determinate": {
    backgroundColor: "#E30613",
  },
  "& .MuiLinearProgress-bar2Determinate": {
    backgroundColor: "#FFFFFF",
  },
}));
