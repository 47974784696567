import { useDispatch } from "react-redux";
import {
  contactFormInput,
  FaqsService,
} from "../../domain/services/faqs.service";
import { handleResponse } from "../../domain/shared";
import { httpService } from "../http/services/http";
import { ContactFormTermsModel, FaqsModel, } from "../models";
import { Endpoints } from "./endpoints";

export interface FaqsResponse {
  data: {
    data: FaqsModel;
  };
}

interface contactFormResponse {
  data: {
    data: FaqsModel;
  };
}

interface formContactMatterResponse {
  data: {
    data: ContactFormTermsModel;
  }
}

export function faqsDataService(): FaqsService {
  const { get, post } = httpService();
  return {
    async getFaqsList({ success, error }: handleResponse) {
      try {
        const response: FaqsResponse = await get(Endpoints.faqs);
        if (success) {
          success(response.data.data.items);
        }
      } catch (e) {
        if (error) {
          error(e);
        }
      }
    },

    async sendContactForm({
      contactMatter,
      email,
      contactProductMatter,
      description,
      success,
      error,
    }: contactFormInput) {
      try {
        await post(Endpoints.contactForm, {
          formType: "general",
          contactMatter,
          email,
          contactProductMatter,
          description,
        });
        if (success) {
          success();
        }
      } catch (e) {
        if (error) {
          error(e);
        }
      }
    },
    async getContactFormMatterTerms({ success, error }: handleResponse) {
      try {
        const response: formContactMatterResponse = await get(Endpoints.contactFormMatter);
        if (success) {
          success(response.data.data.terms);
        }
      } catch (e) {
        if (error) {
          error(e);
        }
      }
    },
    async getContactFormProductMatterTerms({success, error}: handleResponse) {
      try {
        const response: formContactMatterResponse = await get(Endpoints.contactFormProductMatter);
        if (success) {
          success(response.data.data.terms);
        }
      } catch (e) {
        if (error) {
          error(e);
        }
      }
    }
  };
}
