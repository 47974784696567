import { FC, PropsWithChildren } from "react";
import {
  StyledAccordionMedal,
  StyledAccordionMedalSummary,
  StyledAccordionMedalDetails,
  StyledStepContainer,
} from "./AccordionMedal.styles";
import { ArrowDownIcon } from "../../assets/Icons";
import blueMedalBadge from "../../assets/images/blueMedalBadgeTwo.png";
import { StatusCard } from "../StatusCard";
import checkedCircle from "./../../assets/images/checkCircle.svg";
import { StyledDescriptionGold } from "../MedalCard/MedalCard.styles";
import { PriceEntity } from "../../../domain/entities";

interface AccordionProps {
  className?: string;
  title: string;
  description?: string;
  image?: string;
  backgroundColor: string;
  backgroundColorForDetail: string;
  path?: Array<any>;
  location?: string;
  status?: string;
  startedPath?: boolean;
  navigateTo: (url: string, state?: { [key: string]: any } | undefined) => void;
  medalId?: string;
  handlePayMedal?: (learningPathId: string, medalId: string) => void;
  learningPathId?: string;
  certifiedBy?: Array<{ name: string }>;
  price?: PriceEntity;
  currentPath?: string;
  scheduled?: {};
}
enum CurrencySymbol {
  eur = "€",
  usd = "$",
}

export const AccordionMedal: FC<PropsWithChildren<AccordionProps>> = ({
  className,
  title,
  image,
  description,
  backgroundColor,
  backgroundColorForDetail,
  path,
  location,
  status,
  startedPath,
  navigateTo,
  medalId,
  handlePayMedal,
  learningPathId,
  certifiedBy,
  price,
  currentPath,
  scheduled,
}) => {
  return (
    <div className="mx-5">
      <StyledAccordionMedal
        className={className}
        backgroundColor={backgroundColor}
        status={status}
        startedPath={startedPath}
      >
        <StyledAccordionMedalSummary
          backgroundColor={backgroundColor}
          expandIcon={
            <ArrowDownIcon color="#FFFFFF" width="32px" height="32px" />
          }
        >
          <img src={image} className="h-16" />
          <h3 className="text-white text-2 font-primaryBold w-full">{title}</h3>
        </StyledAccordionMedalSummary>
        {currentPath === "/learning-path" ? (
          <StyledAccordionMedalDetails
            backgroundColorForDetail={backgroundColorForDetail}
            dangerouslySetInnerHTML={{ __html: description ? description : "" }}
          ></StyledAccordionMedalDetails>
        ) : !path && medalId !== "blue_4eC39Hq" ? (
          <div>
            {!!price && (
              <p
                className={`font-primarySemibold text-xl mb-2 ${
                  medalId === "gold_4eC39Hq" ? "text-black" : "text-white"
                }
              `}
              >{`${CurrencySymbol[price.currency]}${
                price.unitAmount / 100
              } ${price.currency.toUpperCase()}`}</p>
            )}
            <p
              className={`font-primaryBold text-base ${
                medalId === "gold_4eC39Hq" ? "text-black" : "text-white"
              }`}
            >
              {medalId === "gold_4eC39Hq"
                ? "Asistencia Curso"
                : "Prueba de conocimientos"}
            </p>
            <StyledDescriptionGold
              className="mb-2"
              dangerouslySetInnerHTML={{
                __html: description ? description : "",
              }}
            />
            {!!certifiedBy?.length && (
              <div className="mb-6">
                <p className="font-primaryBold text-sm">Certificado Por:</p>
                {certifiedBy.map((university) => {
                  return (
                    <p className="font-primaryRegular text-xs">
                      {university.name}
                    </p>
                  );
                })}
              </div>
            )}
          </div>
        ) : (
          <>
            {!!path?.length &&
              path.map((step) => {
                return (
                  <StyledStepContainer className="text-left flex flex-row text-white  bg-blue-100 py-4 px-4">
                    {step.isChecked && startedPath && (
                      <img src={checkedCircle} />
                    )}
                    <div className="stepInfo flex flex-col">
                      <p className="font-bold text-base">Paso {step.step}:</p>
                      <p
                        className="font-primaryRegular text-sm cursor-pointer"
                        onClick={() => {
                          !step.isChecked &&
                            navigateTo(`/preview?uuid=${step.id}`);
                        }}
                      >
                        {step.title}
                      </p>
                    </div>
                  </StyledStepContainer>
                );
              })}
          </>
        )}
        {location === "detail" && status && medalId && (
          <StatusCard
            medalId={medalId}
            status={status}
            isScheduled={!!scheduled}
            learningPathId={learningPathId}
            handlePayMedal={handlePayMedal}
          />
        )}
      </StyledAccordionMedal>
    </div>
  );
};
