import { runInContext } from "lodash";
import { contactFormInput, FaqsService } from "../../services/faqs.service";
import { handleResponse } from "../../shared";

export function useCaseFaqs(service: FaqsService) {
  return {
    get({ success, error }: handleResponse) {
      return service.getFaqsList({ success, error });
    },
    post({
      contactMatter,
      email,
      contactProductMatter,
      description,
      success,
      error,
    }: contactFormInput) {
      return service.sendContactForm({
        contactMatter,
        email,
        contactProductMatter,
        description,
        success,
        error,
      });
    },
    getContactFormMatterTerms({ success, error }: handleResponse) {
      return service.getContactFormMatterTerms({ success, error });
    },
    getContactFormProductMatterTerms({ success, error }: handleResponse) {
      return service.getContactFormProductMatterTerms({ success, error });
    },
  };
}
