import { useState } from "react";
import { VideoJsPlayerOptions } from "video.js";
import { HomeVariableEntity } from "../../../../../../domain/entities";
import { useCustomEffect, useNavigation } from "../../../../../hooks";

export function useBanner(video: HomeVariableEntity) {
  const [videoOptions, setVideoOptions] = useState<VideoJsPlayerOptions>({
    autoplay: true,
    controls: false,
    loop: true,
    muted: true,
  });
  const { navigateTo } = useNavigation();

  const handleNewVideoSource = (source: string) => {
    setVideoOptions({
      ...videoOptions,
      sources: [
        {
          src: source,
          type: "video/mp4",
        },
      ],
    });
  };

  useCustomEffect(() => {
    video?.value && handleNewVideoSource(video.value);
  }, [video]);

  return {
    videoOptions,
    navigateTo,
  };
}
