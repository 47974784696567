import { FC } from "react";

interface IconProps {
  className?: string;
  color?: string;
  width?: string;
  height?: string;
}

export const ProductionsIcon: FC<IconProps> = ({
  className,
  color = "#C1101A",
  width = "18px",
  height = "18px",
}) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.84392 17.9973C0.580704 17.9973 0.336483 17.8434 0.222513 17.596C0.0949749 17.3212 0.160101 16.9942 0.382613 16.7908C1.04472 16.1808 1.4789 14.8342 1.58744 13.0754C0.548141 11.6794 0 10.047 0 8.33496C0 3.74015 4.02694 0 8.97377 0C12.0374 0 15.3154 1.82748 16.9408 4.44641C18.1999 6.47176 18.341 8.74992 17.3397 10.8605C16.6586 12.2977 15.4348 13.4024 13.6004 14.2406C11.994 14.9744 10.2166 15.0211 8.72413 14.3698C7.35377 13.7707 6.36061 12.6605 5.84774 11.1573C5.36201 9.73374 5.4597 7.74962 5.54654 6.00183C5.57639 5.40275 5.60352 4.83664 5.60352 4.35847C5.60352 4.11939 5.72563 3.89679 5.92644 3.77038C6.12724 3.64397 6.37689 3.63023 6.59126 3.73466L14.059 7.43084C14.2707 7.53527 14.4145 7.74412 14.4389 7.98321C14.4633 8.22229 14.3656 8.45588 14.1784 8.60153L9.80684 12.0394C9.50835 12.2757 9.07689 12.2208 8.84624 11.9185C8.61287 11.6162 8.66714 11.1792 8.96563 10.9456L12.4689 8.18931L6.94402 5.45496C6.93588 5.65557 6.92503 5.86168 6.91417 6.07053C6.83548 7.69466 6.74322 9.53588 7.14211 10.7066C7.52473 11.8305 8.25739 12.655 9.26684 13.0947C10.4065 13.5921 11.7796 13.5481 13.036 12.9765C14.5637 12.2785 15.5677 11.3908 16.1023 10.2614C17.1145 8.12611 16.4849 6.32336 15.7794 5.1884C14.4118 2.98718 11.549 1.39053 8.97106 1.39053C4.7786 1.39053 1.36764 4.50687 1.36764 8.33771C1.36764 9.81618 1.86965 11.226 2.81668 12.4186C2.92251 12.5505 2.97407 12.7154 2.96864 12.8831C2.92523 13.8696 2.78141 15.0623 2.39065 16.1038C3.25628 15.7273 4.67819 15.0211 5.63066 13.9685C5.88573 13.6855 6.3199 13.6663 6.5994 13.9273C6.8789 14.1856 6.89789 14.6253 6.6401 14.9084C4.30643 17.4834 1.05829 17.978 0.936181 17.9945C0.906332 17.9973 0.873769 18 0.84392 18V17.9973Z"
        fill="#C1101A"
      />
    </svg>
  );
};
