import tw from "twin.macro";
import styled from "styled-components";
import { Card } from "../../../../components";

export const StyledLearningPathSection = styled.div.attrs({
  className: "StyledLearningPathSection",
})`
  ${tw`text-white text-center items-center `}
  > h3 {
    ${tw`text-xl font-primaryBold pb-[40px] lg:(text-2xl pb-4)`}
  }
  > p {
    ${tw`text-sm font-primarySemibold pb-[40px] lg:(text-base pb-4)`}
  }
  > img {
    ${tw`w-[200px] h-[52px] lg:(w-[154px] h-[37px])`}
  }
  .AnahuacLogo {
    ${tw`w-[200px] h-[52px]  lg:(w-[154px] h-[37px])`}
  }
`;
