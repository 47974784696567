import { FC } from "react";
import { Control, FieldValues, useController } from "react-hook-form";
import toast from "react-hot-toast";
import { AvatarField, AvatarProps } from "./AvatarField";

interface TextFieldControlledProps extends AvatarProps {
  control?: Control<FieldValues, any>;
  id: string;
  name: string;
  defaultAvatar?: string;
}

export const AvatarFieldControlled: FC<TextFieldControlledProps> = ({
  className,
  id,
  name,
  defaultAvatar = "",
  control,
  ...rest
}) => {
  const {
    field: { onChange, value, ref },
  } = useController({
    name,
    control,
    rules: {
      required: true,
    },
    defaultValue: { base64: defaultAvatar, file: { fileUrl: defaultAvatar } },
  });

  const onChangeFile = (e: any) => {
    const fileTypes = ["image/jpeg", "image/png"];
    e.preventDefault();
    if (e.target.files.length > 0) {
      const { type } = e.target.files[0];
      // eslint-disable-next-line react/prop-types
      if (!fileTypes.some((s) => type.includes(s))) {
        toast.error("Tipo de imagen invalida");
      } else {
        if (e.target.files[0].size < 2000000) {
          const aux = e.target.files;
          const reader = new FileReader();
          aux.length && reader.readAsDataURL(aux[0]);
          reader.onload = () => {
            const dataFile = { base64: reader.result, file: aux[0] };
            onChange(dataFile);
          };
        } else {
          toast.error(
            "Imagen demasiado grande, inténtalo de nuevo con 2MB o menos"
          );
        }
      }
    }
  };

  return (
    <AvatarField
      onChange={onChangeFile}
      value={value}
      ref={ref}
      className={className}
      id={id}
      name={name}
      {...rest}
    />
  );
};
