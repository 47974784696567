import { FC } from "react";

interface IconProps {
  className?: string;
  color?: string;
  width?: string;
  height?: string;
}

export const EmailIcon: FC<IconProps> = ({
  className,
  color = "#636363",
  width = "22px",
  height = "22px",
}) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.5"
        d="M2.75 7.33337L9.9825 12.155C10.2838 12.356 10.6378 12.4633 11 12.4633C11.3622 12.4633 11.7162 12.356 12.0175 12.155L19.25 7.33337M4.58333 17.4167H17.4167C17.9029 17.4167 18.3692 17.2236 18.713 16.8797C19.0568 16.5359 19.25 16.0696 19.25 15.5834V6.41671C19.25 5.93048 19.0568 5.46416 18.713 5.12034C18.3692 4.77653 17.9029 4.58337 17.4167 4.58337H4.58333C4.0971 4.58337 3.63079 4.77653 3.28697 5.12034C2.94315 5.46416 2.75 5.93048 2.75 6.41671V15.5834C2.75 16.0696 2.94315 16.5359 3.28697 16.8797C3.63079 17.2236 4.0971 17.4167 4.58333 17.4167Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
