import { LearningPathService } from "../../services/learningPath.service";
import { handleResponse } from "../../shared";

export function useCaseLearningPath(service: LearningPathService){
    return {
        getListMedals({success, error}: handleResponse){
            return service.getListMedals({success, error});
        },
        getListLearningPaths({success, error}: handleResponse){
            return service.getListLearningPaths({success, error});
        }
    }
}