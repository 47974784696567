import { FC } from "react";
import { GameEntity, ProductionEntity } from "../../../domain/entities";
import { useNavigation } from "../../hooks";
import {
  StyledImage,
  StyledPodcastCard,
  StyledLinearProgress,
} from "./GameCard.styles";
import LinearProgress from "@mui/material/LinearProgress";
import { ReactComponent as PlayButtonIcon } from "../../assets/images/playButtonIcon.svg";

export interface GameCardCardProps {
  className?: string;
  gameData: GameEntity;
  whitSeeMore?: boolean;
  onClickCard: (uuid: string) => void;
}

export const GameCard: FC<GameCardCardProps> = ({
  className,
  gameData,
  whitSeeMore,
  onClickCard,
}) => {
  const { uuid, name, description, img } = gameData;
  const nameColored = name
    .toUpperCase()
    .replace(
      "GAME",
      (match) => `<span style="color: #E30613">${match} </span>`
    );
  const { navigateTo } = useNavigation();
  return (
    <StyledPodcastCard
      onClick={() => onClickCard(uuid)}
      className={`StyledPodcastCard ${className} cursor-pointer`}
    >
      <div className="relative flex flex-col justify-center">
        <StyledImage
          src={img}
          alt={`Imagen juego ${name}`}
          title={`Imagen juego ${name}`}
        />
      </div>
      <div className="w-full h-full flex flex-col text-center lg:text-left">
        <h3
          className={`text-white font-primaryBold text-ellipsis text-xl uppercase`}
          dangerouslySetInnerHTML={{ __html: nameColored }}
        />
        <p className="text-white font-primaryBook capitalize ">{description}</p>
        {whitSeeMore && (
          <p className="hidden lg:block text-primary-100 font-primarySemibold mt-auto">
            Ver más
          </p>
        )}
      </div>
    </StyledPodcastCard>
  );
};
