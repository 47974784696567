import {
  GameById,
  ProductionById,
  ProductionsByCategory,
  ProductionSeoById,
  ProductionsService,
  ProductionsWithFilter,
} from "../../services/productions.service";
import { handleResponse } from "../../shared";

export function useCaseProductions(service: ProductionsService) {
  return {
    getAll({ success, error }: handleResponse) {
      return service.getAllProductions({ success, error });
    },
    getByCategory({ id, success, error }: ProductionsByCategory) {
      return service.getProductionsByCategory({ id, success, error });
    },
    getById({ id, success, error }: ProductionById) {
      return service.getProductionById({ id, success, error });
    },
    getSeo({ id, success, error }: ProductionSeoById) {
      return service.getProductionSeoById({ id, success, error });
    },
    getGameById({ id, success, error }: GameById) {
      return service.getGameById({ id, success, error });
    },
    getFiltered({ filter, success, error }: ProductionsWithFilter) {
      return service.getProductionsWithFilter({ filter, success, error });
    },
    getChapter({ id, success, error }: ProductionById) {
      return service.getProductionChapter({ id, success, error });
    },
  };
}
