import {
  AuthenticationService,
  updateProfileInput,
  deleteNotificationInput,
  getNotificationInput
} from "../../services/authentication.service";
import { handleResponse } from "../../shared";

export function useCaseProfile(service: AuthenticationService) {
  return {
    getProfile({ success, error }: handleResponse) {
      return service.getProfile({ success, error });
    },
    updateProfile({
      name,
      lastName,
      email,
      oldPassword,
      password,
      photo,
      countryId,
      passwordConfirmation,
      success,
      error,
    }: updateProfileInput) {
      return service.updateProfile({
        name,
        lastName,
        email,
        oldPassword,
        password,
        photo,
        countryId,
        passwordConfirmation,
        success,
        error,
      });
    },
    getNotifications({page, success, error }: getNotificationInput) {
      return service.getNotifications({page, success, error})
    },
    deleteNotification({id, success, error }: deleteNotificationInput) {
      return service.deleteNotification({id, success, error})
    }
  };
}
