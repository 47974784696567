import { FC } from "react";
import { StyledLearningPathCard, StyledImage } from "./LearningPathCard.styles";
import { IconStar } from "../../assets/Icons";
import { Badge } from "../Badge";
import { FolderIcon, HeartIcon } from "../../assets/Icons";
import { ProductionEntity } from "../../../domain/entities/production.entity";
import { PillEntity } from "../../../domain/entities";
import { useNavigation } from "../../hooks";
import { ReactComponent as PlayButtonIcon } from "../../assets/images/playButtonIcon.svg";
import { Button } from "../Button";

export interface LearningPathCardProps {
  className?: string;
  withPlayButton?: boolean;
  learningPathData: {
    uuid: string;
    title: string;
    imgPreview: string;
  };
}

export const LearningPathCard: FC<LearningPathCardProps> = ({
  className,
  withPlayButton,
  learningPathData,
}: LearningPathCardProps) => {
  const { uuid, title, imgPreview } = learningPathData;
  const { navigateTo } = useNavigation();
  return (
    <StyledLearningPathCard className={`StyledLearningPathCard ${className}`}>
      <div className="relative flex justify-center ">
        <StyledImage src={imgPreview} />
      </div>

      <h3
        className={`uppercase self-center items-center text-white text-center font-primaryBold text-base lg:text-2xl`}
      >
        {title}
      </h3>

      <Button
        onClick={() => navigateTo(`/learning-path-detail?uuid=${uuid}`)}
        className="max-w-fit mx-auto lg:mb-16"
      >
        Saber más
      </Button>
    </StyledLearningPathCard>
  );
};
