import { FC, useState } from "react";
import { StyledSubscriptionsContainer } from "./Subscriptions.styles";

import { SubscriptionEntity } from "../../../../../domain/entities";
import {
  Button,
  Carousel,
  Modal,
  SubscriptionCard,
} from "../../../../components";
import { HomeModel } from "../../../../../data/models";
import { GoSubscribe } from "../GoSubscribe";
import { useNavigation } from "../../../../hooks";

export interface SubscriptionsSectionProps {
  token?: string;
  subscriptionsList: Array<SubscriptionEntity>;
  handleCallToActionCard: (data: any) => void;
  homeData: HomeModel;
}

export const Subscriptions: FC<SubscriptionsSectionProps> = ({
  token,
  subscriptionsList,
  handleCallToActionCard,
  homeData,
}) => {
  const [showModalLogin, setShowModalLogin] = useState(false);
  const { navigateTo } = useNavigation();
  return (
    <>
      <StyledSubscriptionsContainer>
        <h3>Elige tu plan Storytraining</h3>
        <p>
          Forma parte de nuestra historia mejorando tus soft skills a través de
          nuestro contenido
        </p>
        <hr className="pt-0 w-72 opacity-50 border-t-2 sm:hidden border-white " />

        <div className="w-full 2xl:max-w-theme xl:max-w-6xl lg:max-w-4xl  max-w-inProgressDashboard flex flex-col lg:pt-8 ">
          <Carousel
            slidesToShow={4}
            slidesToScroll={1}
            infinite={true}
            dots={false}
            responsive={[
              {
                breakpoint: 1536,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 1,
                  centerMode: true,
                  centerPadding: "72px",
                },
              },
              {
                breakpoint: 1280,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                  centerMode: true,
                  centerPadding: "92px",
                },
              },
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  centerMode: true,
                  centerPadding: "0px",
                },
              },
            ]}
          >
            {subscriptionsList.map((currentSubscription, index) => (
              <SubscriptionCard
                className={"h-full w-full px-6 lg:px-2 mx-auto lg:mx-0"}
                key={`S-${index}`}
                subscriptionData={currentSubscription}
                callToActionCard={
                  currentSubscription?.callToAction
                    ? currentSubscription.callToAction
                    : handleCallToActionCard
                }
              />
            ))}
          </Carousel>
        </div>
        <h4>¿Tienes un código de suscripción? Valídalo aquí</h4>
        <Button
          onClick={() => {
            !token
              ? setShowModalLogin(!showModalLogin)
              : navigateTo(`/code-validation`);
          }}
        >
          Validar mi código
        </Button>
      </StyledSubscriptionsContainer>
      {!token && (
        <>
          <h2 className="mb-4">
            {homeData?.suscribe?.section?.title?.value
              ? homeData.suscribe.section.title.value
              : "SUSCRÍBETE Y OBTÉN UNA PRUEBA GRATIS"}
          </h2>
          <div>
            <p className="max-w-paragraphMobile lg:max-w-homeParagraph text-white text-sm lg:text-2xl">
              {homeData?.suscribe?.section?.paragraph?.value
                ? homeData.suscribe.section.paragraph.value
                : "El mejor contenido para el desarrollo de tus soft skills a través de Series Tv, Películas, Documentales...¡Cuando quieras y donde quieras!"}
            </p>
          </div>
          <GoSubscribe />
        </>
      )}
      <Modal
        showModal={showModalLogin}
        onClose={() => setShowModalLogin(false)}
        backgroundColor="white"
      >
        <div className="px-4 lg:px-8 text-center flex flex-col items-center justify-center gap-y-6 max-w-inputForm">
          <h3 className="text-2xl lg:text-2 font-primaryBold text-primary-100 tracking-tight">
            ¡Necesitas loguearte!
          </h3>
          <p className="font-primaryBook text-sm tracking-tight lg:text-base">
            Para validar tu código y empezar a disfrutar de nuestros contenidos
            debes ingresar a tu cuenta.
          </p>
          <p className="flex gap-x-2 font-primaryBook text-sm tracking-tight lg:text-base">
            ¿Aún no cuentas con una?{" "}
            <span
              className="text-primary-100 cursor-pointer font-primaryBold"
              onClick={() => navigateTo("/signup")}
            >
              Regístrate aquí
            </span>
          </p>
          <Button
            onClick={() =>
              navigateTo("/login", {
                previousPathname: "/",
                nextPathname: "/code-validation",
              })
            }
          >
            Ingresar
          </Button>
        </div>
      </Modal>
    </>
  );
};
