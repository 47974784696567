import { FC } from "react";
import { StyledMiniMedalCard } from "./MiniMedalCard.styles";
import blueMedalBadge from "../../assets/images/blueMedalBadgeTwo.png";

export interface MiniMedalCardProps {
  title: string;
  image: string;
  id: string;
}

export const MiniMedalCard: FC<MiniMedalCardProps> = ({ title, image, id }) => {
  return (
    <div className="flex justify-center ">
      <StyledMiniMedalCard id={id}>
        <img src={image} alt="medal" title="medal" className="w-16 h-24" />
        <h3>{title}</h3>
      </StyledMiniMedalCard>
    </div>
  );
};
