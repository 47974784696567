import { FC } from "react";
import { Button } from "../../../../components";
import { StyledLearningPathBanner } from "./LearningPathBanner.styles";
import { ReactComponent as AnahuacLogo } from "../../../../assets/images/sponsorAnahuacLogo.svg";
import SponsorVitoriaLogo from "../../../../assets/images/Vitoria.png";

export interface LearningPathBannerProps {
  navigateTo: (url: string, state?: { [key: string]: any } | undefined) => void;
}

export const LearningPathBanner: FC<LearningPathBannerProps> = ({
  navigateTo,
}) => {
  return (
    <StyledLearningPathBanner>
      <div className="text-center">
        <h2>CAMINOS DE APRENDIZAJE</h2>
        <p>Certifica tus conocimientos con nuestros aliados estratégicos</p>
        <Button className="my-5" onClick={() => navigateTo("/learning-path")}>
          Saber más
        </Button>
      </div>
      {/*<div className="mt-5 lg:mr-32">
        <AnahuacLogo className="AnahuacLogo" />
        <img
          src={SponsorVitoriaLogo}
          alt="sponsorVitoriaLogo"
          title="sponsorVitoriaLogo"
        />
      </div>*/}
    </StyledLearningPathBanner>
  );
};
