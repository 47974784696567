import tw from "twin.macro";
import styled from "styled-components";

export const StyledSubscriptionsContainer = styled.div.attrs({
  className: "StyledSubscriptionsContainer",
})`
  ${tw`relative max-w-full w-full mx-auto px-6 lg:px-16 pb-16 flex flex-col gap-4 items-center justify-center text-center`}
  > h3 {
    ${tw`text-2 font-primaryBold text-white `}
  }
  > h4 {
    ${tw`text-xl font-primaryBold text-white pt-4 lg:(text-2xl)`}
  }
  > p {
    ${tw`pt-4 max-w-[67.5rem] text-base leading-6 text-white lg:(pt-0)`}
  }

  .StyledSubscriptionCard {
    .StyledDescriptionList {
      ${tw` my-auto lg:(w-auto my-0)`}
    }
    button {
      ${tw`w-full lg:(w-auto)`}
    }
  }
`;
