import tw from "twin.macro";
import styled from "styled-components";
import { styled as styledMUI } from "@mui/material/styles";

import {
  Accordion,
  AccordionProps,
  AccordionSummary,
  AccordionSummaryProps,
  AccordionDetails,
  AccordionDetailsProps,
} from "@mui/material";

export interface CustomAccordionProps extends AccordionProps {
  backgroundColor?: string;
  status?: string;
  startedPath?: boolean;
}

export interface CustomAccordionDetailsProps extends AccordionDetailsProps {
  backgroundColorForDetail?: string;
}

export interface CustomAccordionSummaryProps extends AccordionSummaryProps {
  backgroundColor?: string;
}

export const StyledAccordionMedal = styledMUI(Accordion)<CustomAccordionProps>(
  ({ backgroundColor, status, startedPath }) => {
    let backgroundSwitch = "";
    let opacitySwitch = "";
    status === null && startedPath === true ? (opacitySwitch = `0.5;`) : ``;
    switch (backgroundColor) {
      case "Blue":
        backgroundSwitch = `#011373`;
        break;
      case "Green":
        backgroundSwitch = `#124A18`;
        break;
      case "Gold":
        backgroundSwitch = `#FFB500`;
        break;
      default:
        break;
    }

    return {
      background: backgroundSwitch,
      textAlign: "center",
      opacity: opacitySwitch,
      borderRadius: "8px !important",
      "& .MuiAccordion-root": {
        margin: "0px 19px",
      },
      "& .MuiAccordion-region": {
        padding: "24px",
      },
    };
  }
);

export const StyledAccordionMedalSummary = styledMUI(
  AccordionSummary
)<CustomAccordionSummaryProps>(({ backgroundColor }) => {
  let colorSwitch = "#FFFFFF";
  let backgroundSwitch = "";

  switch (backgroundColor) {
    case "Blue":
      backgroundSwitch = `linear-gradient(341.28deg, #001273 12.6%, #00ABE4 77.64%);`;
      break;
    case "Green":
      backgroundSwitch = `linear-gradient(156.71deg, #45AA4F 28.16%, #0C3F11 100%);`;
      break;
    case "Gold":
      backgroundSwitch = `linear-gradient(125.21deg, #FFB500 3.61%, #FFFFFF 16.89%, #FFB500 31.54%, #FFB500 72.41%, #7C5903 100.92%);`;
      colorSwitch = `#000000`;
      break;
    default:
      break;
  }
  return {
    display: "flex",
    alignItems: "center",
    background: backgroundSwitch,
    height: "100%",
    padding: "0px 24px",
    borderRadius: "8px",
    boxShadow: " 0px 4px 4px rgba(0, 0, 0, 0.25)",

    "& .MuiAccordionSummary-content": {
      width: "100%",
      margin: "20px 0",
      display: "flex",
      alignItems: "center",
      "& h3": {
        color: colorSwitch,
      },
    },
    "& .MuiAccordionSummary-expandIconWrapper": {
      "& svg": {
        "& path": {
          stroke: colorSwitch,
        },
      },
    },
  };

  1;
});

export const StyledAccordionMedalDetails = styledMUI(
  AccordionDetails
)<CustomAccordionDetailsProps>(({ theme, backgroundColorForDetail }) => ({
  background:
    backgroundColorForDetail === "Blue"
      ? `#011373`
      : backgroundColorForDetail === "Green"
      ? `#124A18`
      : backgroundColorForDetail === "Gold"
      ? `#FFB500`
      : "",
  color: backgroundColorForDetail === "Gold" ? "#000000" : "#FFFFFF",
  padding: "24px",
  borderBottomRightRadius: "8px",
  borderBottomLeftRadius: "8px",
}));

export const StyledStepContainer = styled.div.attrs((props) => ({
  className: props.className,
}))`
  ${tw`grid gap-2 items-center`}
  grid-template-columns: 24px auto;
  .stepInfo {
    grid-column: 2 / 3;
  }
`;
