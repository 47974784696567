import tw from "twin.macro";
import styled from "styled-components";
import { Notification } from "../../../../components";

export const StyledNotificationSection= styled.div.attrs({
  className: "StyledNotificationSection",
})`
${tw`bg-black w-full lg:(px-24 py-4.5)`}

h2 {
${tw`text-white font-primaryBold text-xl lg:(text-[32px])`}    
}
.downArrowLogo {
  ${tw`hover:cursor-pointer`}
}

`

export const StyledNotificationEmpty = styled.div.attrs({className: "StyledNotificationEmpty"})`
${tw`flex flex-row justify-between  w-80   bg-secondary-50  my-2 lg:( w-full h-32 rounded-3xl bg-secondary-200 items-center justify-around)`}

p {
    ${tw`text-white font-primaryBold text-center text-sm w-full break-words my-auto px-4 py-6 lg:(font-primaryRegular text-base w-[735px])`}
}
`