import tw from "twin.macro";
import styled from "styled-components";

export interface StyledCardProps {
  className?: string;
  withBadge?: boolean;
}

export const StyledBadge = styled.p.attrs((props) => ({
  className: props.className,
}))`
  ${tw`text-white bg-gray-500 align-baseline rounded-full px-4 font-primaryRegular  capitalize pt-1`}
  width: fit-content;
`;
