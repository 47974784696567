import { FC } from "react";
import { RatingProps as RatingPropsMUI } from "@mui/material";
import { FieldError, FieldErrorsImpl, Merge } from "react-hook-form";
import { StyledHelperText, StyledRating } from "./Rating.styles";
import { RatingStarIcon } from "../../assets/Icons";

export interface RatingProps extends RatingPropsMUI {
  id?: string;
  name?: string;
  error?: boolean;
  helperText?:
    | string
    | FieldError
    | Partial<{ type: string | number; message: string }>
    | Merge<FieldError, FieldErrorsImpl<any>>;
}

export const Rating: FC<RatingProps> = ({
  className,
  id,
  name,
  error,
  helperText,
  onChange,
  value,
  ref,
  readOnly,
  precision = 0.5,
  ...rest
}) => {
  // const classes = useStyles();
  return (
    <div
      className={`relative flex flex-col items-center w-full gap-y-1 ${className}`}
    >
      <StyledRating
        id={id}
        name={name}
        onChange={onChange}
        value={value}
        ref={ref}
        readOnly={readOnly}
        precision={precision}
        emptyIcon={<RatingStarIcon color={"#434343"} />}
        // icon={<RatingStarIcon />}
        {...rest}
      />

      {!!error && !!helperText && (
        <StyledHelperText>{helperText as string}</StyledHelperText>
      )}
    </div>
  );
};
