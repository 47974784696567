import { FC } from "react";
import { Breadcrumb, Button, Navbar } from "../../components";
import { DefaultLayout } from "../../layouts";
import {
  StyledDashboard,
  StyledInProgress,
  StyledInProgressInnerContainer,
} from "./dashboard.styles";
import { ReactComponent as InProgressIcon } from "../../assets/images/InProgressIcon.svg";
import { MiniMedalEntity } from "../../../domain/entities";
import { useDashboardViewModel } from "../../viewModels/dashboardViewModel";
import {
  PodcastInProgress,
  WeeklyFavorites,
  SavedProductions,
  MedalsCompleted,
  ProductionsInProgress,
  ProgressByAreaSection,
  LearningPathsSection,
} from "./components";

const breadcrumbItemsElements = [
  {
    name: "Inicio",
    url: "/",
  },
  {
    name: "Mi storytraining",
  },
];

export const Dashboard: FC = () => {
  const {
    navigateTo,
    savedProductions,
    productionsInProgress,
    podcastInProgress,
    favoritesProductions,
    medalsCompletedByUser,
    medalsInProgressByUser,
    savedPodcastProductions,
    userSubscription,
    progressByArea,
  } = useDashboardViewModel();

  return (
    <DefaultLayout>
      <div className="w-full lg:hidden">
        <Navbar />
      </div>
      <div className="px-8 pb-8 lg:px-14 hidden lg:block">
        <Breadcrumb breadcrumbItems={breadcrumbItemsElements} />
      </div>
      <StyledDashboard>
        <div className="pt-14 flex flex-col-reverse justify-end lg:flex-col lg:justify-start gap-y-12">
          <WeeklyFavorites
            favoritesProductions={favoritesProductions}
            navigateTo={navigateTo}
          />

          {userSubscription?.stripeStatus === "active" && (
            <SavedProductions
              savedProductions={savedProductions}
              savedPodcastProductions={savedPodcastProductions}
              navigateTo={navigateTo}
            />
          )}
        </div>
        <div>
          <div className="flex flex-col-reverse lg:flex-col">
             {medalsCompletedByUser.length > 0 && (
              <MedalsCompleted medalsCompletedByUser={medalsCompletedByUser} />
            )}
            <h1 className="flex justify-center lg:justify-start">
              MI STORYTRAINING
            </h1>
          </div>
          <StyledInProgress>
            <div className="flex flex-row ">
              <h2>EN CURSO</h2>
              <InProgressIcon />
            </div>
            {productionsInProgress.length > 0 ? (
              <div>
                <hr className="pt-0 opacity-50 border-t-2 sm:hidden border-white mx-6" />
                <h3 className="text-primary-100 text-2xl font-primaryBold text-center mb-8 lg:text-xl lg:flex justify-start lg:mt-0">
                  NUESTRAS PRODUCCIONES
                </h3>
                <ProductionsInProgress
                  productionsInProgress={productionsInProgress}
                />
                {!!podcastInProgress[0]?.chapters?.length && (
                  <StyledInProgressInnerContainer>
                    <PodcastInProgress
                      podcastList={podcastInProgress[0].chapters}
                    />
                  </StyledInProgressInnerContainer>
                )}
                 <div className="px-6 py-9 lg:px-0">

                  <hr className="pt-0 opacity-20 border-t-2 border-white " />
                </div>

                {/* <ProgressByAreaSection progressByArea={progressByArea} />

                <div className="px-6 py-9 lg:px-0">
                  <hr className="pt-0 opacity-20 border-t-2 border-white " />
                </div> */}

                {/* <LearningPathsSection
                  medalsInProgressByUser={medalsInProgressByUser}
                  navigateTo={navigateTo}
                /> */}
              </div>
            ) : (
              <div className="mx-5 text-center text-white">
                <p className="font-primaryRegular py-8">
                  Aquí encontrarás enlaces directos para retomar el contenido
                  que tengas en curso.
                </p>
                <p className="py-2 font-primaryBold text-xl">
                  ¿Aún no tienes contenido en esta sección?
                </p>
                <p className="py-2 font-primaryBold text-xl">¡Comencemos!</p>
                <Button
                  onClick={() => navigateTo("/catalog")}
                  className="mb-16 mt-4"
                >
                  Ver todo el catálogo
                </Button>
              </div>
            )}
          </StyledInProgress>
        </div>
      </StyledDashboard>
    </DefaultLayout>
  );
};
