import tw from "twin.macro";
import styled from "styled-components";

export interface StyledMiniMedalCardProps {
  color?: string;
  id: string;
}

export const StyledMiniMedalCard = styled.div.attrs({
  className: "StyledMiniMedalCard",
})<StyledMiniMedalCardProps>`
  ${({ id }) =>
    id === "blue_4eC39Hq"
      ? `background: linear-gradient(341.28deg, #001273 12.6%, #00ABE4 77.64%);`
      : id === "green_4eC39Hq"
      ? `background: linear-gradient(156.71deg, #45AA4F 28.16%, #0C3F11 100%);`
      : id === "gold_4eC39Hq" &&
        `background: linear-gradient(125.21deg, #FFB500 3.61%, #FFFFFF 16.89%, #FFB500 31.54%, #FFB500 72.41%, #7C5903 100.92%);`}

  ${tw`text-white rounded-xl text-center flex flex-col  items-center w-[135px] h-[184px] gap-y-2 py-6`}
  h3 {
    ${tw`font-primaryRegular text-xs`}
  }
`;
