import tw from "twin.macro";
import styled from "styled-components";
import { Button } from "../../components";

export const StyledBlurWall = styled.div.attrs({
  className: "StyledBlurWall",
})`
  ${tw`absolute top-0 left-0 right-0 w-full h-full z-10`}
  background: linear-gradient(180deg, rgba(29, 29, 29, 0) 0%, rgba(29, 29, 29, 0.5) 100%);
  backdrop-filter: blur(3px);
`;


export const StyledButton = styled(Button).attrs({
  className: "StyledButton"
})`
 ${tw`
  lg:hidden
  inline-flex
  mx-auto
  absolute
  z-10
  bottom-0
  left-1/2
 `}
 transform: translate(-50%, -72px)
`