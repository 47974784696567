import { FC } from "react";
import { Breadcrumb, Button, Modal } from "../../components";
import { DefaultLayout } from "../../layouts";
import { useCodeValidationViewModel } from "../../viewModels";
import { CodeValidationSection } from "./components";
import { StyledSubscriptionContainer } from "./codeValidation.styles";

export const CodeValidation: FC = () => {
  const {
    navigateTo,

    breadcrumbItemsElements,

    showModalStatusCode,
    finalStatusCode,
    handleCloseStatusModal,
    handleCloseStatusModalOnSuccess,
    onSubmitValidationCode,
    handleValidationCodeForm,

    showModalProductionRedemption,
    codeRedemptionData,
    codeError,

    showGoToLoginModal,
    handleCloseGoToLoginModal,
    newLocationState,
  } = useCodeValidationViewModel();

  return (
    <DefaultLayout>
      <Breadcrumb
        className="pb-12 px-8 lg:px-14 "
        breadcrumbItems={breadcrumbItemsElements}
      />
      <StyledSubscriptionContainer>
        <CodeValidationSection
          showModalStatusCode={showModalStatusCode}
          finalStatusCode={finalStatusCode}
          handleCloseStatusModal={handleCloseStatusModal}
          handleCloseStatusModalOnSuccess={handleCloseStatusModalOnSuccess}
          onSubmitValidationCode={onSubmitValidationCode}
          handleValidationCodeForm={handleValidationCodeForm}
          showModalProductionRedemption={showModalProductionRedemption}
          productionId={codeRedemptionData?.productionUuid}
          productionName={codeRedemptionData?.title}
          codeError={codeError}
        />
      </StyledSubscriptionContainer>
      <Modal
        showModal={showGoToLoginModal}
        onClose={handleCloseGoToLoginModal}
        backgroundColor="white"
      >
        <div className="px-4 lg:px-8 text-center flex flex-col items-center justify-center gap-y-6 max-w-inputForm">
          <h3 className="text-2 font-primaryBold text-primary-100 tracking-tight">
            ¡Necesitas loguearte!
          </h3>
          <p className="font-primaryBook text-xs tracking-tight lg:text-base">
            Antes de elegir una de nuestras suscripciones debes ingresar a tu
            cuenta.
          </p>
          <p className="flex gap-x-2 font-primaryBook text-xs tracking-tight lg:text-base">
            ¿Aún no cuentas con una?{" "}
            <span
              className="text-primary-100 cursor-pointer font-primaryBold"
              onClick={() => navigateTo("/signup")}
            >
              Regístrate aquí
            </span>
          </p>
          <Button onClick={() => navigateTo("/login", newLocationState)}>
            Ingresar
          </Button>
        </div>
      </Modal>
    </DefaultLayout>
  );
};
