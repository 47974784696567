import { FC } from "react";
import { ProductionEntity } from "../../../domain/entities";
import { useNavigation } from "../../hooks";
import {
  StyledImage,
  StyledPodcastCard,
  StyledLinearProgress,
} from "./PodcastCard.styles";
import LinearProgress from "@mui/material/LinearProgress";
import { ReactComponent as PlayButtonIcon } from "../../assets/images/playButtonIcon.svg";

export interface PodcastCardProps {
  className?: string;
  podcastData: ProductionEntity;
  whitSeeMore?: boolean;
}

export const PodcastCard: FC<PodcastCardProps> = ({
  className,
  podcastData,
  whitSeeMore,
}) => {
  const { uuid, title, format, imgPreview, alt } = podcastData;
  const { navigateTo, getCurrentPathName } = useNavigation();
  return (
    <StyledPodcastCard
      onClick={() => {
        const currentPathName = getCurrentPathName();

        if (window.innerWidth >= 1024 && currentPathName === "/catalog") {
          navigateTo(`/catalog?uuid=${uuid}`);
        } else {
          navigateTo(`/preview?uuid=${uuid}`);
        }
      }}
      className={`StyledPodcastCard ${className} cursor-pointer`}
    >
      <div className="relative flex flex-col justify-center">
        <PlayButtonIcon className="playButton" />
        <StyledImage
          src={imgPreview}
          alt={alt ? alt : `Imagen producción ${title}`}
          title={alt ? alt : `Imagen producción ${title}`}
        />
        {/* <StyledLinearProgress
          variant="determinate"
          value={(progress * 100) / duration}
        /> */}
      </div>
      <div className="w-full h-full flex flex-col text-center lg:text-left">
        <h3
          className={`text-white font-primaryBold text-ellipsis text-base pt-2`}
        >
          {title.toUpperCase()}
        </h3>
        <p className="text-white font-primarySemibold capitalize text-base`">
          {format}
        </p>
        {whitSeeMore && (
          <p className="hidden lg:block text-primary-50 font-primarySemibold text-base mt-auto">
            Ver más
          </p>
        )}
      </div>
    </StyledPodcastCard>
  );
};
