import tw from "twin.macro";
import styled from "styled-components";
import { styled as styledMUI } from "@mui/material/styles";
import LinearProgress from "@mui/material/LinearProgress";

export const StyledChapterCard = styled.article.attrs((props) => ({
  className: props.className,
}))`
  ${tw`w-full relative min-w-[20rem] h-[5.5rem] flex gap-x-4 lg:(h-[8.5rem])`}
`;

export const StyledContainerImage = styled.div.attrs({
  className: "StyledContainerImage",
})`
  ${tw`relative flex flex-col rounded-t-xl min-w-[8.5rem] max-w-[8.5rem] lg:(min-w-[14rem] max-w-[14rem] h-[8.5rem]) overflow-hidden cursor-pointer`}
  >.playButton {
    ${tw`absolute top-1/2 left-1/2`}
    width: 30px;
    height: 30px;
    transform: translate(-50%, -50%);
  }
  img {
    ${tw`min-w-[8.5rem] max-w-[8.5rem] h-full rounded-t-xl object-cover lg:(min-w-[14rem] max-w-[14rem] h-[8.5rem])`}
  }
`;

export const StyledLinearProgress = styledMUI(LinearProgress)((props) => ({
  height: "10px",
  width: "100%",
  position: "absolute",
  display: "block",
  bottom: "0px",
  zIndex: "2",
  backgroundColor: "#FFFFFF",

  "& .MuiLinearProgress-bar1Determinate": {
    backgroundColor: "#E30613",
  },
  "& .MuiLinearProgress-bar2Determinate": {
    backgroundColor: "#FFFFFF",
  },
}));
