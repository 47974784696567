import { FC, useState } from "react";
import {
  AccordionMedal,
  Breadcrumb,
  Button,
  MedalCard,
  Modal,
} from "../../components";
import { DefaultLayout } from "../../layouts";
import { StyledLearningPathDetailContainer } from "./learningPathDetail.styles";
import { ModalsLearningPath } from "./components/modals";
import { useLearningPathDetailViewModel } from "../../viewModels";
import { Tooltip } from "@mui/material";
import { ReactComponent as InformationTooltipIcon } from "../../assets/images/informationCircleForTooltip.svg";

const breadcrumbItemsElements = [
  {
    name: "Inicio",
    url: "/",
  },
  {
    name: "Caminos de Aprendizaje",
    url: "/learning-path",
  },
  {
    name: "Camino",
  },
];

export const LearningPathDetail: FC = () => {
  const {
    sizeScreen,
    detailLearningPath,
    detailBlueMedal,
    detailGreenMedal,
    detailGoldMedal,
    handleStartLearningPath,
    showLogInModal,
    setShowLoginModal,
    setShowStartPathModal,
    showStartPathModal,
    showSuccessModal,
    navigateTo,
    handlePayMedal,
    handleRedirectToFirstProduction,
    handleNotSubscribedModal,
    showNotSubscribedModal,
    handleStartPath,
    showTransactionModal,
    handleTransactionModal,
    transactionFinalStatus,
  } = useLearningPathDetailViewModel();
  return (
    <DefaultLayout>
      <div className="px-8 pb-8 lg:px-14 hidden lg:block">
        <Breadcrumb breadcrumbItems={breadcrumbItemsElements} />
      </div>
      <StyledLearningPathDetailContainer>
        <h1>
          Camino de Aprendizaje: {detailLearningPath?.title?.toLowerCase()}
        </h1>
        <h1></h1>
        {sizeScreen.x > 1023 && detailLearningPath?.medals?.length ? (
          <div className="flex flex-row justify-center items-center lg:py-8 text-center">
            {detailBlueMedal && (
              <MedalCard
                title={detailBlueMedal.title}
                path={detailBlueMedal.path}
                image={detailBlueMedal.image}
                size="big"
                location="detail"
                status={detailBlueMedal.status}
                startedPath={detailLearningPath?.started}
                navigateTo={navigateTo}
                medalId={detailBlueMedal.id}
              />
            )}
            <div
              className={`${
                detailGreenMedal && detailGoldMedal ? "grid" : "flex"
              }grid grid-rows-2 gap-y-8 h-full`}
            >
              {detailGreenMedal && (
                <MedalCard
                  title={detailGreenMedal.title}
                  description={detailGreenMedal.body}
                  price={detailGreenMedal.price}
                  image={detailGreenMedal.image}
                  size="small"
                  location="detail"
                  status={detailGreenMedal.status}
                  startedPath={detailLearningPath?.started}
                  learningPathId={detailLearningPath?.uuid}
                  medalId={detailGreenMedal.id}
                  handlePayMedal={handlePayMedal}
                  navigateTo={navigateTo}
                />
              )}
              {detailGoldMedal && (
                <MedalCard
                  title={detailGoldMedal.title}
                  description={detailGoldMedal.body}
                  certifiedBy={detailGoldMedal.certifiedBy}
                  price={detailGoldMedal.price}
                  image={detailGoldMedal.image}
                  size="small"
                  location="detail"
                  status={detailGoldMedal.status}
                  startedPath={detailLearningPath?.started}
                  learningPathId={detailLearningPath?.uuid}
                  medalId={detailGoldMedal.id}
                  handlePayMedal={handlePayMedal}
                  navigateTo={navigateTo}
                  scheduled={detailGoldMedal.session}
                />
              )}
            </div>
          </div>
        ) : (
          detailLearningPath?.medals?.length && (
            <div className="w-full max-w-5xl flex flex-col gap-y-1 lg:gap-y-6 my-4">
              {detailBlueMedal && (
                <AccordionMedal
                  title={detailBlueMedal.title}
                  path={detailBlueMedal.path}
                  backgroundColor={detailBlueMedal.title}
                  backgroundColorForDetail={detailBlueMedal.title}
                  image={detailBlueMedal.image}
                  location="detail"
                  status={detailBlueMedal.status}
                  startedPath={detailLearningPath?.started}
                  navigateTo={navigateTo}
                  medalId={detailBlueMedal.id}
                />
              )}
              {detailGreenMedal && (
                <AccordionMedal
                  title={detailGreenMedal.title}
                  description={detailGreenMedal.body}
                  backgroundColor={detailGreenMedal.title}
                  backgroundColorForDetail={detailGreenMedal.title}
                  price={detailGreenMedal.price}
                  image={detailGreenMedal.image}
                  location="detail"
                  status={detailGreenMedal.status}
                  startedPath={detailLearningPath?.started}
                  medalId={detailGreenMedal.id}
                  learningPathId={detailLearningPath?.uuid}
                  handlePayMedal={handlePayMedal}
                  navigateTo={navigateTo}
                />
              )}
              {detailGoldMedal && (
                <AccordionMedal
                  title={detailGoldMedal.title}
                  description={detailGoldMedal.body}
                  certifiedBy={detailGoldMedal.certifiedBy}
                  price={detailGoldMedal.price}
                  backgroundColor={detailGoldMedal.title}
                  backgroundColorForDetail={detailGoldMedal.title}
                  image={detailGoldMedal.image}
                  location="detail"
                  status={detailGoldMedal.status}
                  startedPath={detailLearningPath?.started}
                  medalId={detailGoldMedal.id}
                  learningPathId={detailLearningPath?.uuid}
                  handlePayMedal={handlePayMedal}
                  navigateTo={navigateTo}
                  scheduled={detailGoldMedal.session}
                />
              )}
            </div>
          )
        )}
        {detailBlueMedal || detailGreenMedal || detailGoldMedal ? (
          <div className="flex justify-center mb-11 pb-16">
            {detailLearningPath.started === false ? (
              <Button
                onClick={() => {
                  handleStartPath();
                }}
                className="max-w-fit mx-auto lg:mb-16"
              >
                Iniciar camino
              </Button>
            ) : (
              <div className="flex flex-col text-center  lg:flex-row items-center gap-y-3">
                <p className="text-white font-primaryRegular text-base">
                  ¡Genial! Haz iniciado este camino de aprendizaje.
                </p>
                <Tooltip title="Los caminos de aprendizaje se inician automáticamente una vez empieces a ver el contenido listado en “blue”">
                  <InformationTooltipIcon />
                </Tooltip>
              </div>
            )}
          </div>
        ) : (
          <div className="flex text-center align-center justify-center mb-11 pb-16 px-4">
            <p className="text-white font-primaryRegular text-base">
              No existe ninguna medalla activa para este camino de aprendizaje.
            </p>
          </div>
        )}
      </StyledLearningPathDetailContainer>
      <ModalsLearningPath
        showLogInModal={showLogInModal}
        showStartPathModal={showStartPathModal}
        setShowStartPathModal={setShowStartPathModal}
        handleStartLearningPath={handleStartLearningPath}
        learningPathId={detailLearningPath.uuid}
        learningPathTitle={detailLearningPath.title}
        medalImage={detailBlueMedal ? detailBlueMedal.image : ""}
        setShowLoginModal={setShowLoginModal}
        showSuccessModal={showSuccessModal}
        handleRedirectToFirstProduction={handleRedirectToFirstProduction}
        handleNotSubscribedModal={handleNotSubscribedModal}
        showNotSubscribedModal={showNotSubscribedModal}
        showTransactionModal={showTransactionModal}
        handleTransactionModal={handleTransactionModal}
        transactionFinalStatus={transactionFinalStatus}
      />
    </DefaultLayout>
  );
};
