import { FC } from "react";
import { FaqsModel } from "../../../../../data/models";
import { FaqsEntity } from "../../../../../domain/entities";
import { Accordion } from "../../../../components";
import { StyledFaqsSection } from "./FaqsSection.styles";

interface FaqsSectionProps {
  faqsData: Array<FaqsEntity>;
}

export const FaqsSection: FC<FaqsSectionProps> = ({ faqsData }) => {
  return (
    <StyledFaqsSection>
      <h1>PREGUNTAS FRECUENTES</h1>
      <p className="font-primaryBook text-white text-sm lg:text-xl mb-8">
        Resolvemos todas tus dudas sobre Storytraining
      </p>
      <div className="w-full max-w-5xl flex flex-col gap-y-1 lg:gap-y-6 ">
        {faqsData.map((faq, index) => (
          <Accordion key={`A-${index}`} title={faq.question}>
            <div dangerouslySetInnerHTML={{ __html: faq.answer }} />
          </Accordion>
        ))}
      </div>
    </StyledFaqsSection>
  );
};
