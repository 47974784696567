import { FC } from "react";
import { HeartIcon } from "../../../../assets/Icons";

import { StyledSaved } from "./SavedProductions.styles";

import { ProductionEntity } from "../../../../../domain/entities";
import { Button, Carousel, SavedCard } from "../../../../components";
import { PodcastSaved } from "../PodcastSection";
interface SavedProductionsProps {
  savedProductions: ProductionEntity[];
  savedPodcastProductions: Array<ProductionEntity>;
  navigateTo: (
    url: string,
    state?:
      | {
          [key: string]: any;
        }
      | undefined
  ) => void;
}

export const SavedProductions: FC<SavedProductionsProps> = ({
  savedProductions,
  savedPodcastProductions,
  navigateTo,
}) => {
  return (
    <StyledSaved>
      <div className="flex flex-row items-center gap-x-3 ">
        <h2>MIS FAVORITOS</h2>
        <HeartIcon color="#C1101A" />
      </div>
      <hr />
      <div className="mx-auto w-full pt-6">
        {!!savedProductions.length && (
          <>
            <h3 className="text-xl font-primaryBold text-primary-100 w-full text-left pb-6">
              NUESTRAS PRODUCCIONES
            </h3>

            <Carousel
              slidesToShow={1}
              slidesToScroll={1}
              infinite={false}
              dots={true}
              arrows={false}
              responsive={[
                {
                  breakpoint: 1280,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    arrows: false,
                    centerMode: true,
                    centerPadding: "0px",
                  },
                },
              ]}
            >
              {savedProductions?.map((production: ProductionEntity, index) => {
                return (
                  <SavedCard
                    key={`s-${index}`}
                    className="px-2"
                    productionData={production}
                  />
                );
              })}
            </Carousel>
          </>
        )}
        {!!savedPodcastProductions.length && (
          <PodcastSaved podcastList={savedPodcastProductions} />
        )}
      </div>
      <div className="hidden lg:w-full lg:flex justify-center">
        <Button onClick={() => navigateTo("/catalog")} className="my-8">
          Ver todo el catálogo
        </Button>
      </div>
    </StyledSaved>
  );
};
