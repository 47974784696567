import tw from "twin.macro";
import styled from "styled-components";

export const StyledDefaultHeader = styled.header.attrs((props) => ({
  className: props.className,
}))`
  ${tw`  w-full flex p-5 justify-between lg:(p-10) z-10`}

  .StoryTrainingLogo {
    ${tw`w-[80px] h-[45px] sm:(w-[193px] h-[109px])`}
  }
  .CatalogOption {
    ${tw` hidden lg:(inline-flex)`}
  }
  .FaqButtonOption {
    ${tw` hidden xl:(inline-flex)`}
  }
  .SearchBarOption {
    ${tw` hidden lg:(inline-flex)`}
  }
  .SearchBarOptionMobile {
    ${tw`inline-block lg:(hidden)`}
  }
`;

export const StyledSearchBar = styled.form.attrs({
  className: "StyledSearchBar",
})`
  ${tw`flex  min-w-[288px] xl:(w-96)`}

  input {
    ${tw`rounded-r-none pr-12`}
  }

  button {
    ${tw`rounded-l-none`}
  }
`;
