import { FC } from "react";
import { ChapterInProgressEntity } from "../../../domain/entities";
import { useNavigation } from "../../hooks";
import {
  StyledImage,
  StyledPodcastInProgressCard,
  StyledLinearProgress,
} from "./PodcastInProgressCard.styles";
import LinearProgress from "@mui/material/LinearProgress";
import { ReactComponent as PlayPodcastIcon } from "../../assets/images/playPodcastIcon.svg";

export interface PodcastInProgressCardProps {
  className?: string;
  podcastData: ChapterInProgressEntity;
}

export const PodcastInProgressCard: FC<PodcastInProgressCardProps> = ({
  className,
  podcastData,
}) => {
  const {
    uuid,
    name,
    productionFormat,
    productionUuid,
    imgPreview,
    progress,
    duration,
  } = podcastData;
  const progressInPercents = progress;
  const { navigateTo } = useNavigation();

  return (
    <StyledPodcastInProgressCard
      onClick={() =>
        navigateTo(`/preview?uuid=${productionUuid}&chapterUuid=${uuid}`)
      }
      className={`StyledPodcastInProgressCard ${className} cursor-pointer`}
    >
      <div className="relative flex items-center justify-center">
        <StyledImage
          src={imgPreview}
          alt={`Imagen podcast en progreso ${name}`}
          title={`Imagen podcast en progreso ${name}`}
        />
      </div>
      <div className="w-full h-full flex flex-col  gap-1 justify-center py-1">
        <h3 className={`text-white font-primaryBold text-ellipsis`}>
          {name.toUpperCase()}
        </h3>
        <div className="flex lg:hidden items-center gap-x-4">
          <StyledLinearProgress
            variant="determinate"
            // value={(progress * 100) / duration}
            value={(progress * 100) / duration}
          />
          <PlayPodcastIcon />
        </div>
        <p className="text-white font-primarySemibold capitalize">
          {productionFormat}
        </p>
      </div>
    </StyledPodcastInProgressCard>
  );
};
