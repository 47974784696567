import { FC } from "react";
import {
  Breadcrumb,
  Button,
  Navbar,
  Select,
  SelectControlled,
} from "../../components";
import { DefaultLayout } from "../../layouts";
import { useLearningPathCalendarViewModel } from "../../viewModels/learningPathCalendarViewModel";
import goldBadge from "./../../assets/images/calendarGoldBadge.png";
import { DatePicker } from "./components/DatePicker/DatePicker";
import { ModalsCalendar } from "./components/ModalsCalendar";
import {
  StyledCalendarSection,
  StyledLearningPathCalendarContainer,
  StyledTitledDescripcionCalendar,
} from "./learningPathCalendar.styles";

export const LearningPathCalendar: FC = () => {
  const {
    breadcrumbItemsElements,
    sessionDateHours,
    onChangeDate,
    hoursForSelectedDate,
    control,
    handleSubmit,
    onSubmit,
    errors,
    useCalendarDateUTCFormatter,
    setShowDateModal,
    showDateModal,
    handleOpenModal,
    isValid,
    shouldDisableDate,
    showSuccessModal,
    handleSuccess,
    dateForModal,
    hoursForModal,
    showErrorModal,
    setShowErrorModal,
  } = useLearningPathCalendarViewModel();

  return (
    <DefaultLayout>
      <div className="w-full lg:hidden">
        <Navbar />
      </div>
      <div className="px-8 pb-8 lg:px-14 hidden lg:block">
        <Breadcrumb breadcrumbItems={breadcrumbItemsElements} />
      </div>
      <StyledLearningPathCalendarContainer>
        <StyledTitledDescripcionCalendar>
          <img src={goldBadge} />
          <h1>ESTÁS A UN PASO DE SER GOLD</h1>
          <p>
            Revisa las fechas planteadas para nuestros cursos en formato
            síncrono, elige la que se adapta a tu disponibilidad y da click en
            Programar.
          </p>
        </StyledTitledDescripcionCalendar>

        <StyledCalendarSection>
          <div className="mx-5 my-8 flex flex-col items-center">
            <h2>
              Camino de Aprendizaje: {sessionDateHours?.learningPath?.title}
            </h2>
            <p className="font-primaryBold mb-6">
              Sesiones sincrónicas programadas:
            </p>
            <div className="flex flex-wrap mb-6 w-80 justify-center">
              {sessionDateHours?.items?.map((date, index) => {
                return (
                  <p key={index} className="font-primaryBook text-white">
                    {useCalendarDateUTCFormatter(date.date)}
                    <span key={index} className="text-primary-50 px-1">
                      |
                    </span>
                  </p>
                );
              })}
            </div>
            <DatePicker
              onChangeDate={onChangeDate}
              shouldDisableDate={shouldDisableDate}
            />
            {!!hoursForSelectedDate?.length && (
              <SelectControlled
                error={!!errors.selectedHours}
                helperText={errors?.selectedHours?.message}
                required
                defaultValue={"none"}
                control={control}
                id="selectedHours"
                name="selectedHours"
                className="select mt-6"
                label="Horario"
                placeholder="Selecciona un horario"
                options={hoursForSelectedDate}
              />
            )}
            <Button
              disabled={!isValid}
              onClick={handleOpenModal}
              className="mt-6"
            >
              Programar
            </Button>
          </div>
        </StyledCalendarSection>
        <ModalsCalendar
          setShowErrorModal={setShowErrorModal}
          showErrorModal={showErrorModal}
          hoursForModal={hoursForModal}
          dateForModal={dateForModal}
          title={sessionDateHours?.learningPath?.title}
          handleSuccess={handleSuccess}
          handleSubmit={handleSubmit}
          showSuccessModal={showSuccessModal}
          onSubmit={onSubmit}
          setShowDateModal={setShowDateModal}
          showDateModal={showDateModal}
        />
      </StyledLearningPathCalendarContainer>
    </DefaultLayout>
  );
};
