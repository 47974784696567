import tw from "twin.macro";
import styled from "styled-components";

export const StyledEditProfileSection = styled.div.attrs({
    className: "StyledEditProfileSection",
  })`
  ${tw`flex flex-col  mb-8  items-center justify-center lg:(flex flex-row )`}
  h2 {
    ${tw`font-primaryBold px-4 text-3xl text-white mt-8 lg:(mt-16 mx-24 px-0)`}
  }
  `


export const StyledFormSection = styled.div.attrs({
    className: "StyledFormSection",
})`
${tw`bg-secondary-200 mb-8 w-full  lg:(w-[532px] mx-16 rounded-xl)`}
`