import { useState } from "react";
import { useCustomEffect, useNavigation } from "../../hooks";
import { useCaseSubscriptions, useCaseProfile } from "../../../domain/useCases";
import {
  subscriptionsDataService,
  authenticationDataService,
} from "../../../data/services";
import { SubscriptionEntity } from "../../../domain/entities";
import companySubscription from "../../assets/images/companySubscription.svg";
import subscriptionsUfo from "../../assets/images/subscriptionsUfo.svg";
import { useSelector } from "react-redux";
import {
  getSessionToken,
  getSubscriptionStatus,
} from "../../../data/dto/selectors";

export function useSubscriptionDetailViewModel() {
  const breadcrumbItemsElements = [
    {
      name: "Inicio",
      url: "/",
    },
    {
      name: "Suscripciones",
      url: "/subscriptions",
    },
    {
      name: "Mensual",
    },
  ];
  const { get, getCheckout } = useCaseSubscriptions(subscriptionsDataService());
  const { getProfile } = useCaseProfile(authenticationDataService());

  const { navigateTo, getQueryParam } = useNavigation();
  const token = useSelector(getSessionToken);
  const userSubscription = useSelector(getSubscriptionStatus);

  const [subscriptionsList, setSubscriptionsList] = useState<
    Array<SubscriptionEntity> | []
  >([]);
  const [transactionFinalStatus, setTransactionFinalStatus] = useState<
    "none" | "success" | "error"
  >("none");

  const [showTransactionModal, setShowTransactionModal] = useState(false);
  const [subscriptionSelected, setSubscriptionSelected] =
    useState<SubscriptionEntity>({} as SubscriptionEntity);

  /* handle subscriptions */
  const onSuccessSubscriptionList = (data: Array<SubscriptionEntity>) => {
    const priceId = getQueryParam("priceId");

    const subscriptionSelected = data.find(
      (currentSubscription) => priceId === currentSubscription.price?.id
    );
    const subscriptions = data.filter(
      (currentSubscription) => priceId !== currentSubscription.price?.id
    );

    if (subscriptionSelected) {
      setSubscriptionSelected(subscriptionSelected);
      setSubscriptionsList([
        ...subscriptions,
        {
          name: "Empresas",
          description:
            "Si necesita una propuesta personalizada o información sobre nuestros planes, por favor contáctanos: empresas@storytraining.com",
          items: [
            "Si necesita una propuesta personalizada o información sobre nuestros planes, por favor contáctanos: empresas@storytraining.com",
          ],
          images: [companySubscription],
          tag: "",
          tyc: "terminos-y-condiciones",
          minimumTermLabel: "",
        },
      ]);
    } else {
      navigateTo("/subscriptions");
    }
  };

  const handleTransactionStatus = (status: string) => {
    switch (status) {
      case "success":
        setTransactionFinalStatus(status);
        setShowTransactionModal(true);
        break;
      case "error":
        setTransactionFinalStatus(status);
        setShowTransactionModal(true);
        break;
      default:
        setTransactionFinalStatus("none");
        setShowTransactionModal(false);
        break;
    }
  };

  const handleSuccessSubscription = (url: string) => {
    getProfile({
      success: () => navigateTo(url),
    });
  };

  const handleCallToActionCard = (priceId: string) => {
    getCheckout({
      priceId,
      success: (url) => {
        window.location.replace(url);
      },
    });
  };

  useCustomEffect(() => {
    if (!token) {
      navigateTo("/subscriptions");
    }
    if (userSubscription?.stripeStatus === "active") {
      navigateTo("/profile");
    } else {
      get({
        success: onSuccessSubscriptionList,
        error: () => navigateTo("/subscriptions"),
      });

      handleTransactionStatus(getQueryParam("status"));
    }
  }, []);
  return {
    showTransactionModal,
    transactionFinalStatus,
    breadcrumbItemsElements,
    subscriptionSelected,
    subscriptionsList,
    handleCallToActionCard,
    handleSuccessSubscription,
  };
}
