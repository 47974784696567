import { FC } from "react";
import { ProductionEntity } from "../../../domain/entities";
import {
  AccordionMedal,
  Breadcrumb,
  Card,
  Carousel,
  LearningPathCard,
  MedalCard,
} from "../../components";
import { DefaultLayout } from "../../layouts";
import { useHomeViewModel, useLearningPathsViewModel } from "../../viewModels";
import {
  StyledLearningPathsLandingContainer,
  StyledLearningPathsSection,
  StyledMedalsSection,
} from "./learningPathsLanding.styles";
import learningPathBanner from "./../../assets/images/learningPathBanner.png";
import blueMedalBadge from "./../../assets/images/blueMedalBadgeTwo.png";

const breadcrumbItemsElements = [
  {
    name: "Inicio",
    url: "/",
  },
  {
    name: "Caminos de Aprendizaje",
  },
];

export const LearningPathsLanding: FC = () => {
  const {
    sizeScreen,
    listOfMedals,
    listLearningPaths,
    getCurrentPathName,
    navigateTo,
  } = useLearningPathsViewModel();
  const currentPath = getCurrentPathName();
  return (
    <DefaultLayout>
      <div className="px-8 pb-8 lg:px-14 hidden lg:block">
        <Breadcrumb breadcrumbItems={breadcrumbItemsElements} />
      </div>
      <StyledLearningPathsLandingContainer>
        <StyledMedalsSection>
          <h1 className="my-4">CAMINOS DE APRENDIZAJE</h1>
          <p className="my-4 mx-8 lg:mx-96">
            Si deseas certificar los conocimientos que has adquirido a través de
            nuestra plataforma puedes conseguirlo si realizas alguno de nuestros
            “Caminos de Aprendizaje”.
          </p>
          {sizeScreen.x > 1023 ? (
            <div className="flex flex-row justify-center lg:py-8">
              {listOfMedals.map((medal: any) => {
                return (
                  <MedalCard
                    title={medal?.title}
                    description={medal?.body}
                    image={medal?.image}
                    size="default"
                    location="landing"
                    currentPath={currentPath}
                    navigateTo={navigateTo}
                  />
                );
              })}
            </div>
          ) : (
            <div className="w-full max-w-5xl flex flex-col gap-y-4 lg:gap-y-6 my-4">
              {listOfMedals.map((medal: any) => {
                return (
                  <AccordionMedal
                    title={medal?.title}
                    description={medal?.body}
                    image={medal?.image}
                    backgroundColor={medal?.title}
                    backgroundColorForDetail={medal?.title}
                    currentPath={currentPath}
                    navigateTo={navigateTo}
                  />
                );
              })}
            </div>
          )}
        </StyledMedalsSection>
        <StyledLearningPathsSection>
          <h2>CAMINOS DE APRENDIZAJE DISPONIBLES</h2>
          <div className="mx-auto w-full lg:px-44 px-0 max-w-dashboard">
            <Carousel
              slidesToShow={listLearningPaths.length == 1 ? 1 : 2}
              slidesToScroll={1}
              infinite={true}
              dots={false}
              responsive={[
                {
                  breakpoint: 1120,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    arrows: false,
                    centerMode: true,
                    centerPadding: "65px",
                  },
                },
              ]}
            >
              {listLearningPaths.map((learningPath: any) => {
                return (
                  <LearningPathCard
                    key={learningPath.uuid}
                    className={"lg:px-4"}
                    learningPathData={{
                      uuid: learningPath.uuid,
                      title: learningPath.title,
                      imgPreview: learningPath.image,
                    }}
                  />
                );
              })}
            </Carousel>
          </div>
        </StyledLearningPathsSection>
      </StyledLearningPathsLandingContainer>
    </DefaultLayout>
  );
};
