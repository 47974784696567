import tw from "twin.macro";
import styled from "styled-components";
import { Modal } from "../Modal";
import { Button } from "../Button";

export const StyledModal = styled(Modal).attrs((props) => {
  className: props.className;
})`
  ${tw`min-w-full p-4`}
  .StyledModal {
    ${tw`p-4 min-w-[288px]`}
  }

  textarea[name="body"] {
    resize: none;
  }
`;

export const StyledRatingContainer = styled.div.attrs({
  className: "StyledRatingContainer",
})`
  ${tw`flex flex-col items-center`}
  > h3, > h4 {
    ${tw`font-primaryBold text-base text-center`}
  }
  > p {
    ${tw`pb-6 pt-1 font-primaryBook self-end text-xs text-right`}
  }
`;

export const StyledButtonRating = styled(Button).attrs({
  className: "StyledButtonRating",
})`
  ${tw`py-1`}
`;
