import { FC } from "react";
import { Control, FieldValues, useController } from "react-hook-form";
import { RatingProps, Rating } from "./Rating";

interface RatingControlledProps extends RatingProps {
  control?: Control<FieldValues, any>;
  id: string;
  name: string;
  defaultValue?: number;
  placeholder?: string;
}

export const RatingControlled: FC<RatingControlledProps> = ({
  className,
  id,
  name,
  error,
  helperText,
  placeholder,
  defaultValue = 0,
  control,
  readOnly,
  precision,
  ...rest
}) => {
  const {
    field: { onChange, value, ref },
  } = useController({
    name,
    control,
    rules: {
      required: true,
    },
    defaultValue: defaultValue,
  });

  return (
    <Rating
      className={className}
      id={id}
      name={name}
      error={error}
      helperText={helperText}
      onChange={onChange}
      value={value}
      ref={ref}
      readOnly={readOnly}
      precision={precision}
      {...rest}
    />
  );
};
