import { LegalService } from "../../services/legal.service";
import { handleResponse } from "../../shared";

export function useCaseLegal(service: LegalService) {
  return {
    getLegal({ success, error }: handleResponse) {
      return service.getLegalFiles({ success, error });
    },
  };
}
