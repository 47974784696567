import tw from "twin.macro";
import styled from "styled-components";
import { Card } from "../../components";
import LinearProgress from "@mui/material/LinearProgress";

export const StyledDashboard = styled.div.attrs({
  className: "StyledDashboard",
})`
  // ${tw`relative flex flex-col-reverse  lg:(overflow-hidden flex flex-nowrap flex-row gap-x-10 mb-16 w-full max-w-theme mx-auto px-4)`}
  ${tw`w-full max-w-dashboard mx-auto relative flex flex-col-reverse  lg:(grid gap-x-10 mb-16 px-8)`}
  grid-template-columns: minmax(0,796px) minmax(0,520px);

  h1 {
    ${tw`text-white font-primaryBold text-2xl text-center my-8`}
  }
`;

export const StyledInProgress = styled.div.attrs({
  className: "StyledInProgress",
})`
  ${tw`mx-auto w-full bg-secondary-100 py-8 lg:(rounded-l-xl  px-6 pr-0 max-w-inProgressDashboard)`}
  h2 {
    ${tw`text-white font-primaryBold text-xl mb-8 mr-2 ml-6 lg:(text-2xl ml-0)`}
  }
`;

export const StyledInProgressInnerContainer = styled.div.attrs({
  className: "StyledInProgressInnerContainer",
})`
  ${tw`w-full px-0`}
  > h4 {
    ${tw`text-white text-lg font-primaryBold text-center my-8 lg:(text-base flex justify-start  mb-5)`}
  }
  .slick-prev {
    display: none !important;
  }
  .slick-next {
    width: 10px !important;
    height: 18px !important;
    left: 100% !important;
    transform: translate(0, -100%);
  }
  .StyledWarpperSliderContainer {
    ${tw` lg:(w-11/12)`}
  }
`;
