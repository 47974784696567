import {
  GeneralSEO,
  GeneralSEOService,
} from "../../services/generalSEO.service";

export function useCaseGeneralSEO(service: GeneralSEOService) {
  return {
    getSEO({ pathName, success, error }: GeneralSEO) {
      return service.getSEOPathname({
        pathName : pathName === '/' ? '/home' : pathName,
        success,
        error
      });
    },
  };
}
