import { FC } from "react";
import { Button, CategoryCarousel, Navbar } from "../../components";
import { DefaultLayout } from "../../layouts"
import { StyledPageNotFound, StyledSection } from "./pageNotFound.styles";
import {ReactComponent as FilmClapLogo} from "../../assets/images/filmClapLogo.svg"
import { usePageNotFoundViewModel } from "../../viewModels/pageNotFoundViewModel";

export const PageNotFound: FC = () => {
    const { favoritesProductions } = usePageNotFoundViewModel();
    return (
        <DefaultLayout >
            <div className="w-full lg:hidden">
                <Navbar/>
            </div>
            <StyledPageNotFound>
                <div className="flex flex-col text-center items-center gap-y-8">
                <h1>¡CORTE!</h1>
                <FilmClapLogo/>
                <h2><span>Lo sentimos,</span> no encontramos la página que buscas</h2>
                <p>Asegúrate de haber escrito la dirección correcta y vuelve a intentar, o regresa al inicio para seguir disfrutando de nuestro contenido</p>
                <Button onClick={() => (window.location.href = "/")}>Volver al inicio</Button>
                </div>
            </StyledPageNotFound>
            <StyledSection withoutPadding>
                {favoritesProductions.map((production, index) => {
                    const {
                    title,
                    productions: productionList,
                    description,
                    } = production;
                    return (
                    <CategoryCarousel
                        key={`F-${index}`}
                        categoryName={title}
                        categoryDescription={description}
                        productions={productionList}
                        currentCardPaddingMobile="72px"
                    />
                    );
                })}
            </StyledSection>
        </DefaultLayout>
    )
}