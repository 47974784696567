import { FC, useState } from "react";
import { StyledCodeValidationSection } from "./CodeValidationSection.styles";
import { Button, Carousel, TextFieldControlled } from "../../../../components";
import { StatusModal } from "../../../subscriptionDetail/components/StatusModal";
import { useNavigation } from "../../../../hooks";
import {
  Control,
  FieldErrorsImpl,
  FieldValues,
  UseFormHandleSubmit,
} from "react-hook-form";

export interface CodeValidationSectionProps {
  className?: string;
  handleCallToAction: (data: any) => void;
}

export const CodeValidationSection: FC<CodeValidationSectionProps> = ({
  className,
  handleCallToAction,
}) => {
  return (
    <StyledCodeValidationSection id="code-validation" className={className}>
      <h4>¿Tienes un código de suscripción? Valídalo aquí</h4>
      <Button onClick={handleCallToAction}>Validar mi código</Button>
    </StyledCodeValidationSection>
  );
};

export default CodeValidationSection;
