import {
  CouponRedemption,
  CouponsService,
} from "../../domain/services/coupons.service";
import { httpService } from "../http/services/http";
import { CouponsRedemptionModel } from "../models/coupons.model";
import { Endpoints } from "./endpoints";

export interface couponRedemptionResponse {
  data: CouponsRedemptionModel;
}

export function couponsDataService(): CouponsService {
  const { post } = httpService();
  return {
    async redeemCode({ coupon, success, error }: CouponRedemption) {
      try {
        const response: couponRedemptionResponse = await post(
          Endpoints.coupons,
          { coupon }
        );
        if (success) {
          success(response.data);
        }
      } catch (e) {
        if (error) {
          error(e);
        }
      }
    },
  };
}
