import tw from "twin.macro";
import styled from "styled-components";

export interface StyledHistorySectionProps {
  className?: string;
}

export const StyledCodeValidationSection = styled.section.attrs((props) => ({
  className: props.className,
}))`
  ${tw`relative max-w-full w-full mx-auto px-6 pt-14 lg:(px-16 pt-12) pb-16 flex flex-col gap-4 items-center justify-center text-center `}

  > h1 {
    ${tw`text-2 font-primaryBold text-white lg:(text-3.5)`}
  }
  > p {
    ${tw`pt-4 max-w-[67.5rem] text-base leading-6 text-white lg:(pt-0 text-2xl)`}
  }
`;

export const StyledContainerCarouselHistory = styled.section.attrs({
  className: "StyledContainerCarouselHistory",
})`
  ${tw`max-w-theme mx-auto w-full mb-20 `}
`;
