import { FC } from "react";
import { StyledHistoryCardInfo } from "./HistoryInfoCard.styles";

export interface HistoryInfoCardProps {
    imageLogo: any,
    title: string,
    description: string

}

export const HistoryInfoCard: FC<HistoryInfoCardProps> = ({imageLogo, title, description }: HistoryInfoCardProps) => {
    return (
        <StyledHistoryCardInfo>
            <img src={imageLogo}/>
            <h2>{title}</h2>
            <p>{description}</p>
        </StyledHistoryCardInfo>
    )
}

export default HistoryInfoCard