import {AuthenticationService} from "../../services/authentication.service";
import {handleResponse} from "../../shared";

export function useCaseLogout(service:AuthenticationService){
    return {
        run({success,error}: handleResponse){
            return service.logout({success,error})
        }
    }
}
