import tw from "twin.macro";
import styled from "styled-components";

export interface StyledCardProps {
  className?: string;
  withBadge?: boolean;
}

export const StyledLearningPathCard = styled.article.attrs((props) => ({
  className: props.className,
}))<StyledCardProps>`
  ${tw`grid gap-y-2 lg:(px-0)`}
  grid-template-rows: 296px 66px 46px;

  @media (min-width: 1024px) {
    ${tw`grid items-center gap-y-1 relative`}
    grid-template-rows: 355px 120px 76px;
  }
`;


export const StyledImage = styled.img.attrs({
  className: "StyledImage",
})`
  ${tw`rounded-3xl w-full object-cover `}
  height: 355px;
  max-width: 355px;
  border-radius: 31px;
  @media (max-width: 1024px) {
    max-height: 296px;
    width: 192px;
    max-width: 192px;
    height: 296px;
  }
`;
