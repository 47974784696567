import {CountryRepository} from "../../repositries";
import {handleResponse} from "../../shared";

export function useCaseCountries(repository: CountryRepository){
    return {
        run({success,error}:handleResponse){
            return repository.getCountries({success,error});
        }
    }
}
