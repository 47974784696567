import { useState } from "react";
import { useNavigation } from "../../../hooks";
import { StatusCardProps } from "../StatusCard";

export function useStatusCard({
  status,
  medalId,
  isScheduled,
  learningPathId,
  handlePayMedal,
}: StatusCardProps) {
  const { navigateTo } = useNavigation();
  function handleCopyButton() {
    if (status === "paid" && medalId === "gold_4eC39Hq" && !isScheduled) {
      return "IR A CALENDARIO";
    } else if (status === "in_progress" || status === "paid") {
      return "EN PROGRESO";
    } else if (status === "completed") {
      return "COMPLETADA";
    } else if (status === "peding_payment") {
      return "IR A PAGAR";
    } else {
      return "";
    }
  }

  function handleOnClickButton() {
    if (status === "paid" && medalId === "gold_4eC39Hq" && !isScheduled) {
      navigateTo(
        `/learning-path-calendar?uuid=${learningPathId}&medalid=${medalId}`
      );
    } else if (status === "peding_payment" && !!learningPathId) {
      handlePayMedal && handlePayMedal(learningPathId, medalId);
    }
  }

  return { handleCopyButton, handleOnClickButton };
}
