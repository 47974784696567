import tw from "twin.macro";
import styled from "styled-components";

import { styled as styledMUI } from "@mui/material/styles";
import {
  Select,
  SelectProps as SelectPropsMUI,
  MenuItem,
  MenuItemProps as MenuItemPropsMUI,
} from "@mui/material";

export interface SelectProps extends SelectPropsMUI {
  selectcolor?: "primary" | "secondary";
}

export interface MenuItemProps extends MenuItemPropsMUI {
  selectcolor?: "primary" | "secondary";
}

interface StyledLabelSelectProps {
  textLabelColor?: string;
}

export const StyledSelect = styledMUI(Select)<SelectProps>((props) => ({
  backgroundColor: `${props.selectcolor === "primary" ? "#FFFFFF" : "#C1101A"}`,
  color: `${props.selectcolor === "primary" ? "#636363" : "#FFFFFF"}`,
  fontFamily: `${
    props.selectcolor === "primary"
      ? "Carnero-Regular, sans-serif"
      : "Carnero-SemiBold, sans-serif"
  }`,
  padding: "0px",
  borderRadius: "8px",

  "& svg": {
    top: "16px",
  },
}));

export const StyledMenuItem = styledMUI(MenuItem)<MenuItemProps>((props) => {
  let menuItemStyles = {
    backgroundColor: "transparent",
    color: `${props.selectcolor === "primary" ? "#FFFFFF" : "#434343"}`,
    borderRadius: "8px",
    padding: "8px 13px",
    fontFamily: `${
      props.selectcolor === "primary"
        ? "Carnero-Regular, sans-serif"
        : "Carnero-SemiBold, sans-serif"
    }`,
  };

  if (props["aria-selected"] === "true") {
    menuItemStyles.backgroundColor = `${
      props.selectcolor === "primary"
        ? "#FFFFFF !important"
        : "#434343 !important"
    }`;
    menuItemStyles.color = `${
      props.selectcolor === "primary"
        ? "#434343 !important"
        : "#FFFFFF !important"
    }`;
  }

  return menuItemStyles;
});

export const StyledHelperText = styled.p.attrs({
  className: "StyledHelperText",
})`
  ${tw`
    text-base
    font-primarySemibold
    leading-6
    text-primary-100
    `}
  word-break: break-word;
`;
