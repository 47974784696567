import {
  ChapterProgressInput,
  DashboardService,
  SaveProductionInput,
} from "../../services/dashboard.service";
import { handleResponse } from "../../shared";

export function useCaseDashboard(service: DashboardService) {
  return {
    getSavedProductionsDashboard({ success, error }: handleResponse) {
      return service.getSavedProductionsDashboard({ success, error });
    },
    getInProgressProductionsDashboard({ success, error }: handleResponse) {
      return service.getInProgressProductionsDashboard({ success, error });
    },
    postSavedProductionDashboard({
      productionUuid,
      success,
      error,
    }: SaveProductionInput) {
      return service.postSavedProductionDashboard({
        productionUuid,
        success,
        error,
      });
    },
    postChapterProgressDashboard({
      progress,
      chapterUuid,
      timeRanges,
      success,
      error,
    }: ChapterProgressInput) {
      return service.postChapterProgressDashboard({
        progress,
        chapterUuid,
        timeRanges,
        success,
        error,
      });
    },
    getMedalsUserDashboard({ success, error }: handleResponse) {
      return service.getMedalsUserDashboard({ success, error });
    },
    getProgressByArea({ success, error }: handleResponse) {
      return service.getProgressByArea({ success, error });
    },
  };
}
