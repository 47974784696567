import { FC } from "react";

interface IconProps {
  className?: string;
  color?: string;
  fill?: string;
  width?: string;
  height?: string;
  onClick?: (data: any) => void;
}

export const FolderIcon: FC<IconProps> = ({
  className,
  color = "#ffffff",
  fill = "none",
  width = "30px",
  height = "24px",
  onClick
}) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 30 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path d="M1 18.5V3.91667C1 3.14312 1.30729 2.40125 1.85427 1.85427C2.40125 1.30729 3.14312 1 3.91667 1H12.6667L15.5833 3.91667H24.3333C25.1069 3.91667 25.8487 4.22396 26.3957 4.77094C26.9427 5.31792 27.25 6.05979 27.25 6.83333V18.5C27.25 19.2735 26.9427 20.0154 26.3957 20.5624C25.8487 21.1094 25.1069 21.4167 24.3333 21.4167H3.91667C3.14312 21.4167 2.40125 21.1094 1.85427 20.5624C1.30729 20.0154 1 19.2735 1 18.5Z" fill={fill} stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};
