import { useState } from "react";
import { ContentModel } from "../../../data/models";
import { contentDataService } from "../../../data/services/content.data.service";
import { useCaseContent } from "../../../domain/useCases";
import { useCustomEffect, useNavigation } from "../../hooks";

export function useContentViewModel() {
  const [content, setContent] = useState<ContentModel>({} as ContentModel);
  const { navigateTo, getPathParam } = useNavigation();
  const { get } = useCaseContent(contentDataService());

  const handleGetContentSuccess = (contentData: ContentModel) => {
    setContent(contentData);
  };

  useCustomEffect(() => {
    const contentSlug = getPathParam("slug");
    get({
      slug: contentSlug ? `${contentSlug}` : "",
      success: handleGetContentSuccess,
      error: () => navigateTo("/404"),
    });
  }, []);
  return {
    content,
  };
}
