import tw from "twin.macro";
import styled from "styled-components";

export const StyledSearchNotFound = styled.div.attrs({className: "StyledSearchNotFound"})`
${tw`flex flex-col lg:( flex flex-row justify-center)`}
`

export const StyledDescription= styled.div.attrs({className: "StyledDescription"})`
${tw`text-center self-center max-w-[487px] p-4`}
p {
    ${tw`font-primaryBook text-white text-base font-bold my-8 lg:(text-2xl)`}
}
span {
    ${tw`text-primary-100`}
}
`