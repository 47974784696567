import tw from "twin.macro"
import styled from "styled-components"

export const StyledNotification = styled.div.attrs({className: "StyledNotification"})`
${tw`flex flex-row justify-between  w-80   bg-secondary-50  my-2 lg:( w-full h-32 rounded-3xl bg-secondary-200 items-center justify-around)`}

.bellNotificationLogo {
    ${tw`w-4 h-4`}
}

.storyTrainingLogo {
    ${tw`w-16 h-16 hidden lg:(block)`}
}

.trashCanLogo {
    ${tw`hover:cursor-pointer  h-[18px] lg:(h-6)`}
}

p {
    ${tw`text-white font-primaryBold text-center text-sm w-64 break-words my-auto px-4 py-6 lg:(font-primaryRegular text-base w-[735px])`}
}
`