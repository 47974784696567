import { FC } from "react";
import { StyledBadge } from "./Badge.styles";

interface BadgeProps {
  title: string;
  className?: string;
}

export const Badge: FC<BadgeProps> = ({ title, className }: BadgeProps) => {
  return (
    <StyledBadge
      // className={`StyledBadge ${
      //   title?.length > 24 ? "text-xs" : "text-base"
      //     } ${className} `}
      className={`StyledBadge`}
    >
      {title}
    </StyledBadge>
  );
};
