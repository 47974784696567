import {
  RentalsService,
  RentalsWithFilter,
  RentProduction,
} from "../../domain/services/rentals.service";
import { httpService } from "../http/services/http";
import { Endpoints } from "./endpoints";
import { FormatsModel, RentalsModel, RentProductionModel } from "../models";
import { handleResponse } from "../../domain/shared";

export interface RentalsResponse {
  data: { data: RentalsModel };
}

export interface FormatsResponse {
  data: { data: FormatsModel };
}

export interface RentProductionResponse {
  data: {
    data: RentProductionModel;
  };
}
export function rentalsDataService(): RentalsService {
  const { get, post } = httpService();
  return {
    async getRentalsFormats({ success, error }: handleResponse) {
      try {
        const response: FormatsResponse = await get(Endpoints.formats);
        if (success) {
          success(response.data.data.items);
        }
      } catch (e) {
        if (error) {
          error(e);
        }
      }
    },
    async getRentalsWithFilter({
      filter = {},
      success,
      error,
    }: RentalsWithFilter) {
      try {
        const response: RentalsResponse = await get(Endpoints.rentals, filter);
        if (success) {
          success(response.data.data);
        }
      } catch (e) {
        if (error) {
          error(e);
        }
      }
    },

    async getRentProduction({ productionId, success, error }: RentProduction) {
      try {
        const response: RentProductionResponse = await post(
          `${Endpoints.productions}/${productionId}/rent`,
          {}
        );
        if (success) {
          success(response.data.data.url);
        }
      } catch (e) {
        if (error) {
          error(e);
        }
      }
    },
  };
}
