import { FC } from "react";
import { StyledSearchCard, StyledImage } from "./SearchCard.styles";
import { IconStar } from "../../assets/Icons";
import { Badge } from "../Badge";
import { FolderIcon, HeartIcon } from "../../assets/Icons";
import { ProductionEntity } from "../../../domain/entities/production.entity";
import { PillEntity } from "../../../domain/entities";
import { useNavigation } from "../../hooks";
import { ReactComponent as PlayButtonIcon } from "../../assets/images/playButtonIcon.svg";

export interface SearchCardProps {
  className?: string;
  showOptions?: boolean;
  isCatalog?: boolean;
  withPlayButton?: boolean;
  productionData: ProductionEntity;
}

export const SearchCard: FC<SearchCardProps> = ({
  className,
  showOptions,
  isCatalog,
  withPlayButton,
  productionData,
}: SearchCardProps) => {
  const {
    uuid,
    title,
    description,
    productionType,
    videoPreview,
    imgPreview,
    duration,
    terms,
    seasonsCount,
    format,
  } = productionData;
  const { navigateTo } = useNavigation();
  return (
    <StyledSearchCard
      onClick={() => navigateTo(`/preview?uuid=${uuid}`)}
      withBadge={!!terms?.length}
      className={`StyledSearchCard ${className} cursor-pointer`}
    >
      <div className="relative">
        <PlayButtonIcon className="lg:hidden playButton" />
        <StyledImage
          src={imgPreview}
          alt={`Imagen producción ${title}`}
          title={`Imagen producción ${title}`}
        />
      </div>

      <div className="hidden lg:flex lg:items-center lg:justify-between">
        <div className="flex items-center justify-center gap-x-2 my-2 lg:m-0">
          {[...Array(5)].map((element: undefined, i: number) => {
            return <IconStar key={`score${i}`} />;
          })}
        </div>
        {showOptions && (
          <div className="flex items-center justify-center gap-x-2">
            <FolderIcon className="folderLogo cursor-pointer" />
            <HeartIcon className="heartLogo cursor-pointer" />
          </div>
        )}
      </div>
      <h3
        className={`uppercase self-center items-center text-left text-white lg:text-left font-primaryBold text-xs ${
          title?.length > 24
            ? title.length > 40
              ? "lg:text-sm"
              : "lg:text-xl"
            : "lg:text-2xl"
        } `}
      >
        {title}
      </h3>

      <div
        className={`items-center text-white space-x-2 text-sm lg:text-base font-primarySemiboldItalic hidden lg:flex`}
      >
        <p className="capitalize">
          {productionType == "serie"
            ? seasonsCount > 1
              ? `${seasonsCount} temporadas`
              : `${seasonsCount} temporada`
            : format}
        </p>
      </div>
      <p className=" text-white text-xs text-left lg:text-base font-primaryBook">
        {`${
          isCatalog
            ? description.substring(0, 80)
            : description.substring(0, 120)
        }...`}
        <a className="font-primarySemiBold text-primary"> Ver más</a>
      </p>
    </StyledSearchCard>
  );
};
