import tw from "twin.macro";
import styled from "styled-components";

export const StyledContainerInfo = styled.div.attrs({
  className: "StyledContainerInfo",
})`
  ${tw`relative w-full py-16 flex flex-col items-center gap-y-8 bg-secondary-200 lg:(p-24 flex-row gap-x-16) xl:gap-x-24`}

  .StoryTrainingLogo {
    ${tw`w-[7.5rem] h-[4.23]`}
  }
`;

export const StyledInnerContainerInfo = styled.div.attrs({
  className: "StyledInnerContainerInfo",
})`
  ${tw`w-full flex flex-col-reverse justify-between gap-y-8 lg:(flex-row)`}
`;

export const StyledList = styled.ul.attrs({
  className: "StyledList",
})`
  ${tw`flex flex-col justify-center text-center gap-y-2 lg:(items-start justify-start text-left)`}

  h3 {
    ${tw`text-xl leading-6 font-primarySemibold text-white`}
  }
  a {
    ${tw`text-sm text-white no-underline font-primaryBook`}
  }
`;

export const StyledContainerCopyright = styled.div.attrs({
  className: "StyledContainerCopyright",
})`
  ${tw`p-8 grid place-content-center text-center`}

  p {
    ${tw`text-sm text-white`}
  }
`;
