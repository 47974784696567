import tw from "twin.macro";
import styled from "styled-components";

export const StyledGamesContainer = styled.div.attrs({
  className: "StyledGamesContainer",
})`
  ${tw`relative max-w-sm w-full mx-auto px-2 items-center lg:(max-w-theme items-start) pb-16 flex flex-col gap-4 justify-center`}
  > h3 {
    ${tw`text-xl font-primaryBold text-primary-100`}
  }
`;
