import {
  CommentsService,
  ProductionComments,
  RateProduction,
} from "../../services/comments.service";

export function useCaseComments(service: CommentsService) {
  return {
    getComments({ filter, productionId, success, error }: ProductionComments) {
      return service.getProductionComments({
        filter,
        productionId,
        success,
        error,
      });
    },
    rateProduction({
      qualification,
      body,
      productionId,
      success,
      error,
    }: RateProduction) {
      return service.rateProductionByUser({
        qualification,
        body,
        productionId,
        success,
        error,
      });
    },
  };
}
