import tw from "twin.macro";
import styled from "styled-components";

export interface StyledHistorySectionProps {
  className?: string;
}

export const StyledCodeValidationSection = styled.section.attrs((props) => ({
  className: props.className,
}))`
  ${tw`relative max-w-full w-full mx-auto px-6 lg:px-16  flex flex-col gap-4 items-center justify-center text-center `}

  > h4 {
    ${tw`text-xl font-primaryBold text-white `}
  }
`;
