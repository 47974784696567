import tw from "twin.macro";
import styled from "styled-components";
import { styled as styledMUI } from "@mui/material/styles";
import LinearProgress from "@mui/material/LinearProgress";

export interface StyledInProgressCardProps {
  className?: string;
}

export const StyledPodcastInProgressCard = styled.article.attrs((props) => ({
  className: props.className,
}))<StyledInProgressCardProps>`
  ${tw`grid items-start gap-x-2 w-full overflow-hidden`}
  grid-template-columns: 96px auto;
`;

export const StyledLinearProgress = styledMUI(LinearProgress)((props) => ({
  height: "10px",
  width: "100%",
  position: "relative",
  bottom: "0px",
  zIndex: "10",
  backgroundColor: "#FFFFFF",

  [props.theme.breakpoints.down(1024)]: {
    height: "16px",
  },
  "& .MuiLinearProgress-bar1Determinate": {
    backgroundColor: "#E30613",
  },
  "& .MuiLinearProgress-bar2Determinate": {
    backgroundColor: "#FFFFFF",
  },
}));

export const StyledImage = styled.img.attrs({
  className: "StyledImage",
})`
  ${tw`relative object-cover rounded-lg w-[96px] h-[96px]`}
`;
