import { FC } from "react";
import { Navbar } from "../../components";
import { DefaultLayout } from "../../layouts";
import { useContentViewModel } from "../../viewModels";
import { StyledContentContainer, StyledContentSection } from "./content.styles";

export const Content: FC = () => {
  const { content } = useContentViewModel();
  return (
    <DefaultLayout>
      <div className="w-full lg:hidden">
        <Navbar/>
      </div>
      <StyledContentSection>
        {!!content?.blocks?.length &&
          content.blocks.map((currentContent, index) => (
            <StyledContentContainer
              key={`C-${index}`}
              dangerouslySetInnerHTML={{ __html: currentContent.html }}
            />
          ))}
      </StyledContentSection>
    </DefaultLayout>
  );
};
