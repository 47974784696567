import tw from "twin.macro";
import styled from "styled-components";

export const StyledPodcastContainer = styled.div.attrs({
  className: "StyledPodcastContainer",
})`
  ${tw`relative   w-full mx-auto  flex flex-col gap-4 items-center lg:(items-start) justify-center`}
  > h3 {
    ${tw`text-xl font-primaryBold text-primary-100 w-full text-left`}
  }
`;
