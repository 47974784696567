import {
  LearningPathCalendarService,
  listSessionsInput,
  postSignUpForSessionInput,
} from "../../domain/services/learningPathCalendar.service";
import { httpService } from "../http/services/http";
import { CalendarDateModel } from "../models";
import { Endpoints } from "./endpoints";

export interface LearningPathCalendarResponse {
  data: {
    data: CalendarDateModel;
  };
}

export function LearningPathCalendarDataService(): LearningPathCalendarService {
  const { get, post } = httpService();
  return {
    async getListSessions({
      learningPathUuid,
      medalId,
      timeZone,
      success,
      error,
    }: listSessionsInput) {
      try {
        const response: LearningPathCalendarResponse = await get(
          `${Endpoints.listLearningPaths}/${learningPathUuid}/medals/${medalId}/sessions`,
          { timeZone }
        );

        if (success) {
          success(response.data.data);
        }
      } catch (e) {
        if (error) {
          error(e);
        }
      }
    },
    async postSignUpForSession({
      learningPathUuid,
      medalId,
      id,
      success,
      error,
    }: postSignUpForSessionInput) {
      try {
        await post(
          `${Endpoints.listLearningPaths}/${learningPathUuid}/medals/${medalId}/sessions`,
          { learningPathUuid, medalId, id: id }
        );
        if (success) {
          success();
        }
      } catch (e) {
        if (error) {
          error(e);
        }
      }
    },
  };
}
