import { useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { CountryEntity, OptionsSelectEntity } from "../../../domain/entities";

import { countryDataService } from "../../../data/services/country.data.service";
import { authenticationDataService } from "../../../data/services/authentication.data.service";
import { useCaseCountries } from "../../../domain/useCases/countries";
import { signUp, useCaseLegal, useCaseSignIn } from "../../../domain/useCases";

import { userSignUpSchema } from "../../validators";
import { useCustomEffect } from "../../hooks/useHookEffect";
import { useNavigation, useObjectToArray } from "../../hooks";
import toast from "react-hot-toast";
import { LegalModel } from "../../../data/models";
import { legalDataService } from "../../../data/services";

interface CountriesData {
  countries: Array<CountryEntity>;
  countriesOptions: Array<OptionsSelectEntity>;
}

export function useRegisterViewModel() {
  const breadcrumbItemsElements = [
    {
      name: "Inicio",
      url: "/",
    },
    {
      name: "Crear Cuenta",
    },
  ];

  const { navigateTo, getStateParam } = useNavigation();
  const [countriesData, setCountriesData] = useState<CountriesData>();
  const [showModalRegisterSuccess, setShowModalRegisterSuccess] =
    useState(false);
  const [legalData, setLegalData] = useState<LegalModel>({} as LegalModel);
  const { run: getCountries } = useCaseCountries(countryDataService());
  const { run: login } = useCaseSignIn(authenticationDataService());
  const { run } = signUp(authenticationDataService());
  const { getLegal } = useCaseLegal(legalDataService());
  const {
    handleSubmit,
    control,
    setValue,
    setError,
    formState: { errors },
  } = useForm({ mode: "onChange", resolver: yupResolver(userSignUpSchema) });

  // get countries
  const onGetCountriesSuccess = (countries: Array<CountryEntity>) => {
    const countriesForSelectComponent = countries.map((country) => ({
      id: country.id,
      name: country.name,
    }));
    setCountriesData({
      countries,
      countriesOptions: [
        { id: "none", name: "Elige tu país de origen" },
        ...countriesForSelectComponent,
      ],
    });
  };

  const handleLegalDataSuccess = (legalData: LegalModel) => {
    setLegalData(legalData);
  };

  // handle get email
  const handlerGetEmail = () => {
    const defaultEmail = getStateParam("email");
    defaultEmail && setValue("email", defaultEmail);
  };

  // handle close modal
  const handleCloseModal = (data: FieldValues) => {
    const signInData = {
      email: data.email,
      password: data.password,
      success: () => {
        setShowModalRegisterSuccess(false);
        navigateTo("/catalog");
      },
    };
    login(signInData);
  };

  // handle register success
  const onSignUpSuccess = () => {
    setShowModalRegisterSuccess(true);
  };

  const onRegisterError = (error: any) => {
    if (error?.response?.status != 422) {
      toast.error(error.response.data.message);
    } else {
      const arrayOfErrors = useObjectToArray(error.response.data.errors);
      arrayOfErrors.forEach((currentError) => {
        setError(currentError.key, {
          type: "custom",
          message: currentError.value,
        });
      });
    }
  };

  // on submit signup
  const onSubmit = (data: FieldValues) => {
    if (countriesData && countriesData.countries) {
      const countryData = countriesData.countries.find(
        (country) => country.id == data.country
      );

      const signUpData = {
        name: data.name,
        email: data.email,
        lastName: data.lastName,
        password: data.password,
        country: countryData as CountryEntity,
        success: onSignUpSuccess,
        error: onRegisterError,
      };
      run(signUpData);
    }
  };

  useCustomEffect(() => {
    handlerGetEmail();
    getCountries({ success: onGetCountriesSuccess });
    getLegal({ success: handleLegalDataSuccess });
  }, []);
  return {
    breadcrumbItemsElements,
    navigateTo,

    countriesData,
    legalData,

    showModalRegisterSuccess,
    handleCloseModal,

    handleSubmit,
    control,
    errors,
    onSubmit,
  };
}
