import { AnyAction } from "redux";
import { ActionsTypes } from "./actions.types";
import { SessionModel, ProfileModel } from "../../models";

export function actSaveSession(session: SessionModel): AnyAction {
  return {
    type: ActionsTypes.SAVE_SESSION,
    payload: session,
  };
}

export function actSaveProfileData(profileData: ProfileModel): AnyAction {
  return {
    type: ActionsTypes.SAVE_PROFILE,
    payload: profileData,
  };
}

export function actLogoutSession(): AnyAction {
  return {
    type: ActionsTypes.LOGOUT_SESSION,
  };
}
