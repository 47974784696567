import tw from "twin.macro";
import styled from "styled-components";

export interface StyledStatusCardProps {
  medalId?: string;
}

export const StyledStatusCard = styled.div.attrs((props) => ({
  className: props.className,
}))<StyledStatusCardProps>`
  ${({ medalId }) =>
    medalId === "blue_4eC39Hq"
      ? `background: #00ABE4;`
      : medalId === "green_4eC39Hq"
      ? `background: #7EAB59;`
      : medalId === "gold_4eC39Hq" && `background: #FFB500;`}

  ${tw`text-white font-primaryBold text-center text-2xl rounded-xl flex flex-row items-center gap-x-2 justify-center px-6 py-3 mt-auto mx-auto`}
  max-width: fit-content;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
    inset 0px -4px 4px rgba(0, 0, 0, 0.25);
`;
