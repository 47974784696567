import tw from "twin.macro";
import styled from "styled-components";
import { styled as styledMUI } from "@mui/material/styles";
import LinearProgress from "@mui/material/LinearProgress";

export interface StyledInProgressCardProps {
  className?: string;
}

export const StyledPodcastCard = styled.article.attrs((props) => ({
  className: props.className,
}))<StyledInProgressCardProps>`
  ${tw`flex flex-col gap-y-4 w-[222px] lg:(w-[256px])`}

  > div {
    ${tw`w-[222px] lg:(w-[256px])`}
  }
`;

export const StyledLinearProgress = styledMUI(LinearProgress)((props) => ({
  height: "10px",
  width: "100%",
  display: "relative",
  bottom: "0px",
  zIndex: "10",
  backgroundColor: "#FFFFFF",

  [props.theme.breakpoints.down(1024)]: {
    height: "16px",
  },
  "& .MuiLinearProgress-bar1Determinate": {
    backgroundColor: "#E30613",
  },
  "& .MuiLinearProgress-bar2Determinate": {
    backgroundColor: "#FFFFFF",
  },
}));

export const StyledImage = styled.img.attrs({
  className: "StyledImage",
})`
  ${tw`relative object-cover mx-auto rounded-lg w-[222px] h-[124px] lg:(w-[256px] h-[144px])`}
`;
