import { ChangeEvent, FC } from "react";
import { ProductionCommentsModel } from "../../../../../data/models";
import { PillEntity } from "../../../../../domain/entities";
import { ArrowsPaginator, ProductionComment } from "../../../../components";
import {
  StyledArrowsPaginator,
  StyledPaginator,
  StyledRelatedAreas,
} from "./UsersCommentsSection.styles";

interface UsersCommentsSectionProps {
  comments: ProductionCommentsModel;
  handleChangeCommentsPage: (event: ChangeEvent<unknown>, page: number) => void;
}

export const UsersCommentsSection: FC<UsersCommentsSectionProps> = ({
  comments,
  handleChangeCommentsPage,
}) => {
  return (
    <StyledRelatedAreas>
      <h2 className="self-start text-white my-2 text-xl font-primaryBold px-4 lg:px-0">
        LO QUE DICEN LOS USUARIOS:
      </h2>
      <StyledArrowsPaginator
        page={comments.currentPage}
        totalPages={comments.pages}
        onPageChange={handleChangeCommentsPage}
      >
        <div className="w-fit flex gap-6 flex-wrap justify-center">
          {comments.items.map((currentComment, index) => {
            const firstNameOnly = currentComment.user.name.split(" ");
            return (
              <ProductionComment
                key={`comment-${index}`}
                ratingValue={currentComment.qualification}
                userName={
                  !!firstNameOnly.length
                    ? firstNameOnly[0]
                    : currentComment.user.name
                }
                comment={`"${currentComment.body}"`}
                userAvatar={currentComment.user.avatar}
              />
            );
          })}
        </div>
      </StyledArrowsPaginator>
      <StyledPaginator
        page={comments.currentPage}
        totalPages={comments.pages}
        onPageChange={handleChangeCommentsPage}
      />
    </StyledRelatedAreas>
  );
};
