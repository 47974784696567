import tw from "twin.macro";
import styled from "styled-components";

export const StyledGoSubscribe = styled.article.attrs({
  className: "StyledGoSubscribe",
})`
  ${tw`w-full relative pt-8 flex flex-col items-center justify-center gap-y-8`}
  h3 {
    ${tw`text-sm leading-5 font-primaryBook text-white lg:(text-2xl leading-7 )`}
    span {
      ${tw`text-primary-50`}
    }
  }
`;

export const StyledBar = styled.div.attrs({
  className: "StyledBar",
})`
  ${tw`flex w-full flex-col items-center gap-y-6 max-w-[320px] lg:(grid items-start max-w-[470px])`}
  grid-template-columns:310px 160px;
  grid-template-rows: 80px;
  .EmailTextField {
    img {
      ${tw`lg:(hidden)`}
    }

    input {
      ${tw`w-full pr-10
       lg:(rounded-r-none )`}
    }
  }

  button {
    ${tw`h-[50px] lg:(rounded-l-none)`}
  }
`;
