import { useState } from "react";
import {
  PreviewChapterEntity,
  OptionsSelectEntity,
  SeasonEntity,
  ChapterEntity,
} from "../../../../../../domain/entities";
import { useCustomEffect } from "../../../../../hooks";

export const useSeasonsSection = (seasons: Array<SeasonEntity>, currentChapter: ChapterEntity) => {
  const [currentSeasonNumber, setCurrentSeasonNumber] = useState<number>(0);
  const [currentSeasonChapters, setCurrentSeasonChapters] = useState<
    Array<PreviewChapterEntity> | []
  >([]);
  const seasonsOptions = seasons.map<OptionsSelectEntity>((e, index) => ({
    id: index,
    name: e?.title?.toUpperCase(),
    complementaryData: {
      chapters: e.chapters
    }
  }));

  const onSeasonSelected = (seasonNumber: number) => {
    setCurrentSeasonNumber(seasonNumber);
    setCurrentSeasonChapters(seasons[seasonNumber].chapters);
  };

  useCustomEffect(() => {
    if (currentChapter.uuid) {
      const currentSeason = seasonsOptions
        .find(season => season.complementaryData?.chapters?.some(chapter => chapter.uuid === currentChapter.uuid))
      if (currentSeason?.id) {
        setCurrentSeasonNumber(Number(currentSeason?.id))
      }
    } else {
      !!seasons.length &&  setCurrentSeasonChapters(seasons[0].chapters);
    }
  }, [currentChapter, seasons]);

  return {
    seasonsOptions,
    currentSeasonNumber,
    currentSeasonChapters,
    onSeasonSelected,
  };
};
