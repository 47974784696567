import tw from "twin.macro";
import styled from "styled-components";

export interface StyledSubscriptionCard {
  className?: string;
  isBestOffer?: boolean;
  flag?: boolean;
}

export interface StyledCardContainerProps {
  flag?: boolean;
}

export const StyledSubscriptionCard = styled.article.attrs((props) => ({
  className: props.className,
}))<StyledSubscriptionCard>`
  ${tw`bg-secondary-300 lg:(bg-secondary-200)  relative  flex flex-col items-center justify-between gap-y-8  rounded-3xl h-full`}
  ${({ flag }) =>
    !flag
      ? tw`lg:(max-h-[640px] border-primary-100 border-8 py-20)`
      : tw`max-h-80`}
`;

export const StyledImage = styled.img.attrs({
  className: "StyledImage",
})`
  ${tw`max-h-36`}
`;

export const StyledHorizontalImage = styled.img.attrs({
  className: "StyledHorizontalImage",
})`
  ${tw``}
`;

export const StyledCardContainer = styled.div.attrs({
  className: "StyledCardContainer",
})<StyledCardContainerProps>`
  ${({ flag }) =>
    flag
      ? tw`grid items-center justify-center w-full h-full gap-x-6 py-2 pl-8 pr-6`
      : tw`px-10 lg:px-20`}
  grid-template-columns: 20% 75%;
  grid-template-rows: 100%;
`;

export const StyledDescriptionList = styled.ul.attrs({
  className: "StyledDescriptionList",
})`
  ${tw`lg:(flex flex-col gap-y-2 )`}
`;

export const StyledInformation = styled.div.attrs({
  className: "StyledInformation",
})`
  ${tw`mx-auto flex flex-row items-center justify-center gap-6 lg:flex-col`}
`;

export const StyledDescriptionItem = styled.li.attrs({
  className: "StyledDescriptionItem",
})`
  p {
    ${tw`text-base text-white font-primaryBook text-center`}
  }
`;

export const StyledParagraphTip = styled.p.attrs((props) => ({
  className: props.className,
}))`
  ${tw`text-xs text-white font-primaryBook text-center`}
`;
