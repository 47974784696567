import { FC } from "react";
import { PillEntity } from "../../../../../domain/entities";
import { Badge } from "../../../../components";
import { StyledRelatedAreas } from "./RelatedAreas.styles";

interface RelatedAreasProps {
  terms: Array<PillEntity>;
}

export const RelatedAreas: FC<RelatedAreasProps> = ({ terms }) => {
  return (
    <StyledRelatedAreas>
      <h2 className="text-white my-2 text-xl font-primaryBold">
        ÁREAS RELACIONADAS
      </h2>
      <div className="flex flex-wrap">
        {terms?.map((category: PillEntity, i: number) => {
          return (
            <Badge
              key={`categoryBadge-${i}`}
              className="inline-block mx-2 my-2"
              title={category.title}
            />
          );
        })}
      </div>
    </StyledRelatedAreas>
  );
};
