import { FC } from "react";
import {
  StyledSubscriptionCard,
  StyledImage,
  StyledDescriptionItem,
  StyledParagraphTip,
  StyledRibbon,
  StyledDescriptionList,
  StyledLink,
} from "./SubscriptionCard.styles";
import { ReactComponent as PlusDescriptionIcon } from "../../assets/images/plusDescriptionIcon.svg";
import imagesSample from "../../assets/images/imagesSample.svg";
import { SubscriptionEntity } from "../../../domain/entities";
import { useNavigation } from "../../hooks";
import { Button } from "../Button";
import { indexOf } from "lodash";

export interface SubscriptionCardProps {
  className?: string;
  subscriptionData: SubscriptionEntity;
  callToActionCard?: (data?: any) => void;
}

enum CurrencySymbol {
  eur = "€",
  usd = "$",
}

export const SubscriptionCard: FC<SubscriptionCardProps> = ({
  className,
  subscriptionData,
  callToActionCard,
}) => {
  const {
    name,
    images,
    tag,
    tyc,
    highlight,
    items,
    price,
    callToActionLabel,
    discount,
    minimumTermLabel,

    // subscriptionPrice,
    // isPriceVariable,
    // isBestOffer,
  } = subscriptionData;
  const subscriptionCallToActionCard = () => {
    if (price?.id) {
      callToActionCard && callToActionCard(price?.id);
    } else {
      if (callToActionCard) {
        callToActionCard && callToActionCard();
      }
    }
  };
  const finalPrice = price
    ? (price?.unitAmount / 100.0).toString().replace(".", ",")
    : "";
  const discountPrice = discount?.priceAfterDiscount
    ? (discount?.priceAfterDiscount / 100.0).toString().replace(".", ",")
    : "";
  return (
    <StyledSubscriptionCard
      // onClick={() => navigateTo(`/preview?uuid=${uuid}`)}
      isBestOffer={highlight}
      className={`StyledSubscriptionCard ${className}`}
    >
      {!!tag && (
        <StyledRibbon>
          <span>{tag}</span>
        </StyledRibbon>
      )}

      <div className="mx-auto flex flex-row items-center gap-x-6 lg:flex-col lg:gap-y-2">
        {/* <div className="flex items-end h-36 max-h-36"> */}
        <div className="flex items-end">
          <StyledImage src={images[0]} />
        </div>

        <h4 className="flex flex-col items-center text-2xl text-white font-primaryBold">
          {name}
          {discount && (
            <p className="font-primarySemibold text-xs">
              Antes: <span className="line-through">{finalPrice}</span> / Ahora:
            </p>
          )}
          {!!price?.unitAmount ? (
            <span className="text-primary-100">
              {CurrencySymbol[price.currency]}
              {!discount ? finalPrice : discountPrice}
            </span>
          ) : (
            <StyledParagraphTip>
              *{minimumTermLabel ? minimumTermLabel : "Valor variable"}
            </StyledParagraphTip>
          )}
        </h4>
      </div>

      <StyledDescriptionList>
        {items.map((currentSubscription, index) => (
          <StyledDescriptionItem key={`D-${index}`}>
            <PlusDescriptionIcon />
            <p>{currentSubscription}</p>
          </StyledDescriptionItem>
        ))}
      </StyledDescriptionList>
      <StyledParagraphTip className="mt-auto hidden lg:block">
        {/* {minimumTermLabel} */}
      </StyledParagraphTip>
      <Button onClick={subscriptionCallToActionCard}>
        {!!callToActionLabel ? callToActionLabel : "Comenzar"}
      </Button>
      <StyledLink className="cursor-pointer" href={tyc} target="_blank">
        *Aplican términos y condiciones
      </StyledLink>
    </StyledSubscriptionCard>
  );
};
