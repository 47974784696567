import { FC, ChangeEvent } from "react";
import { GridRowProps, GridColumns, GridRowsProp } from "@mui/x-data-grid";

import { StyledPaginatorContainer, StyledPagination } from "./Paginator.styles";

interface PaginatorProps {
  className?: string;
  page?: number;
  totalPages?: number;
  onPageChange?: (event: ChangeEvent<unknown>, page: number) => void;
}

export const Paginator: FC<PaginatorProps> = ({
  className,
  page,
  totalPages,
  onPageChange,
}) => {
  return (
    <StyledPaginatorContainer className={className}>
      <StyledPagination
        page={page}
        count={totalPages}
        onChange={onPageChange}
        color={"secondary"}
        shape="rounded"
      />
    </StyledPaginatorContainer>
  );
};
