import { useDispatch } from "react-redux";
import {
  SubscriptionCheckout,
  SubscriptionService,
  CompanyContactFormInput,
} from "../../domain/services/subscriptions.service";
import { handleResponse } from "../../domain/shared";
import { httpService } from "../http/services/http";
import {
  SubscriptionsListModel,
  CheckoutSubscriptionModel,
  BillingPortalModel,
} from "../models";
import { Endpoints } from "./endpoints";

export interface SubscriptionsResponse {
  data: {
    data: SubscriptionsListModel;
  };
}

export interface CheckoutSubscriptionResponse {
  data: {
    data: CheckoutSubscriptionModel;
  };
}

export interface BillingPortalResponse {
  data: {
    data: BillingPortalModel;
  };
}

export function subscriptionsDataService(): SubscriptionService {
  const { get, post } = httpService();
  return {
    async getSubscriptionsList({ success, error }: handleResponse) {
      try {
        const response: SubscriptionsResponse = await get(
          Endpoints.subscriptionsList
        );
        if (success) {
          success(response.data.data.items);
        }
      } catch (e) {
        if (error) {
          error(e);
        }
      }
    },
    async getCheckoutSubscription({
      priceId,
      success,
      error,
    }: SubscriptionCheckout) {
      try {
        const response: CheckoutSubscriptionResponse = await post(
          Endpoints.subscriptionCheckout,
          { price_id: priceId }
        );
        if (success) {
          success(response.data.data.url);
        }
      } catch (e) {
        if (error) {
          error(e);
        }
      }
    },

    async sendCompanyContactForm({
      company,
      email,
      description,
      success,
      error,
    }: CompanyContactFormInput) {
      try {
        await post(Endpoints.contactForm, {
          formType: "company",
          company,
          email,
          description,
        });
        if (success) {
          success();
        }
      } catch (e) {
        if (error) {
          error(e);
        }
      }
    },
    async getBillingUrl({ success, error }: handleResponse) {
      try {
        const response: BillingPortalResponse = await get(
          Endpoints.billingPortal
        );
        if (success) {
          success(response.data.data.url);
        }
      } catch (e) {
        if (error) {
          error(e);
        }
      }
    },
  };
}
