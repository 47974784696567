import {
  StyledContainerInfo,
  StyledInnerContainerInfo,
  StyledList,
  StyledContainerCopyright,
} from "./Footer.styles";
import { ReactComponent as StoryTrainingLogo } from "../../assets/images/storyTrainingLogo.svg";
import { ReactComponent as FacebookLogo } from "../../assets/images/facebook.svg";
import { ReactComponent as InstagramLogo } from "../../assets/images/instagram.svg";
import { ReactComponent as LinkedinLogo } from "../../assets/images/linkedin.svg";
import { ReactComponent as TwitterLogo } from "../../assets/images/twitter.svg";
import { ReactComponent as TikTokLogo } from "../../assets/images/tiktok.svg";
import { ReactComponent as YoutubeLogo } from "../../assets/images/youtube.svg";
import { useFooter } from "./useFooter";
import { Link } from "react-router-dom";

export const Footer = () => {
  const { legalData, navigateTo } = useFooter();

  return (
    <footer className="w-full">
      <StyledContainerInfo>
        <StoryTrainingLogo
          onClick={() => navigateTo("/")}
          className="StoryTrainingLogo cursor-pointer"
        />
        <StyledInnerContainerInfo>
          <section className="flex items-center lg:items-start flex-col gap-y-8 lg:flex-row lg:gap-x-16 xl:gap-x-24">
            <StyledList>
              <h3>Legales</h3>
              <li>
                <Link
                  className="cursor-pointer"
                  target="_blank"
                  to={`/content/${legalData?.tyc?.page?.value}`}
                >
                  Términos y condiciones
                </Link>
              </li>
              {/* <li>
                <a href="/">Política de tratamiento de datos</a>
              </li> */}
              <li>
                <Link
                  className="cursor-pointer"
                  target="_blank"
                  to={`/content/${legalData?.politics?.page?.value}`}
                >
                  Aviso de privacidad
                </Link>
              </li>
            </StyledList>
            {/* <hr className="w-72 h-0  opacity-10 border-2 lg:hidden" /> */}
            <StyledList>
              <h3>Acerca de nosotros</h3>
              {/* <li>
                <a href="/">¿Quiénes somos?</a>
              </li> */}
              <li>
                <Link className="cursor-pointer" target="_blank" to="/faqs">
                  Preguntas frecuentes
                </Link>
              </li>
              <li>
                <Link to="/faqs#contactForm">Contáctanos</Link>
              </li>
            </StyledList>
          </section>
          <section>
            <ul className="px-12 py-2 gap-6 flex items-center self-stretch flex-wrap justify-center content-center lg:px-24">
              <li className="flex p-2.5 md:p-0"><a href="https://www.facebook.com/storytrainingoficial" target="_blank" ><FacebookLogo /></a></li>
              <li className="flex p-2.5 md:p-0"><a href="https://www.instagram.com/eyeknowfilms" target="_blank" ><InstagramLogo /></a></li>
              <li className="flex p-2.5 md:p-0"><a href="https://www.linkedin.com/company/storytrainingoficial" target="_blank" ><LinkedinLogo /></a></li>
              <li className="flex p-2.5 md:p-0"><a href="https://twitter.com/eyeknowfilms" target="_blank"><TwitterLogo /></a></li>
              <li className="flex p-2.5 md:p-0"><a href="https://www.tiktok.com/@storytraining_oficial" target="_blank"><TikTokLogo /></a></li>
              <li className="flex p-2.5 md:p-0"><a href="https://www.youtube.com/@storytraining/featured" target="_blank"><YoutubeLogo /></a></li>
            </ul>
          </section>
        </StyledInnerContainerInfo>
      </StyledContainerInfo>

      <StyledContainerCopyright>
        <p>© StoryTraining 2022</p>
      </StyledContainerCopyright>
    </footer>
  );
};
