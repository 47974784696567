import tw from "twin.macro";
import styled from "styled-components";

export const StyledInProgressProductions = styled.div.attrs({
  className: "StyledInProgressProductions",
})`
  ${tw` lg:( max-h-[980px] overflow-y-auto overflow-x-hidden)`}
`;

export const StyledInProgressInnerContainer = styled.div.attrs({
  className: "StyledInProgressInnerContainer",
})`
  ${tw`w-full px-0`}
  > h4 {
    ${tw`text-white text-lg font-primaryBold text-center my-8 lg:(text-base flex justify-start  mb-5)`}
  }
  .slick-prev {
    display: none !important;
  }
  .slick-next {
    width: 10px !important;
    height: 18px !important;
    left: 100% !important;
    transform: translate(0, -100%);
  }
  .StyledWarpperSliderContainer {
    ${tw` lg:(w-11/12)`}
  }
`;
