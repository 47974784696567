import { FieldValues, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { userLoginSchema } from "../../validators";
import { useCaseSignIn } from "../../../domain/useCases";
import { authenticationDataService } from "../../../data/services/authentication.data.service";
import { useSelector } from "react-redux";
import { getSessionToken } from "../../../data/dto/selectors/session.selector";
import { useCustomEffect, useNavigation, useObjectToArray } from "../../hooks";
import toast from "react-hot-toast";
import { useState } from "react";

export function useLoginViewModel() {
  const [newLocationState, setNewLocationState] = useState<{
    [key: string]: any;
  }>({});
  const { run } = useCaseSignIn(authenticationDataService());
  const token = useSelector(getSessionToken);
  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
  } = useForm({ mode: "onChange", resolver: yupResolver(userLoginSchema) });
  const { navigateTo, getStateParam } = useNavigation();

  const onLogInError = (error: any) => {
    console.log({ error });
    if (error?.response?.status != 422) {
      toast.error(error.response.data.message);
    } else {
      const arrayOfErrors = useObjectToArray(error.response.data.errors);
      arrayOfErrors.forEach((currentError) => {
        setError(currentError.key, {
          type: "custom",
          message: currentError.value,
        });
      });
    }
  };

  const handleOnSuccessLogin = () => {
    if (!!newLocationState.nextPathname) {
      navigateTo(newLocationState.nextPathname, { previousPathname: "/login" });
    } else {
      navigateTo("/", { previousPathname: "/login" });
    }
  };
  const onSubmit = (data: FieldValues) => {
    const signInData = {
      email: data.email,
      password: data.password,
      success: handleOnSuccessLogin,
      error: onLogInError,
    };
    run(signInData);
  };

  useCustomEffect(() => {
    const nextPathname = getStateParam("nextPathname");
    const previousPathname = getStateParam("previousPathname");

    setNewLocationState({ nextPathname, previousPathname });
  }, []);

  return {
    handleSubmit,
    control,
    errors,
    onSubmit,
    token,
  };
}
