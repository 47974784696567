import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import {
  getSessionToken,
  getSubscriptionStatus,
} from "../../../data/dto/selectors";
import { SearchModel } from "../../../data/models";
import {
  productionsDataService,
  searchDataService,
} from "../../../data/services";
import { CategoryEntity, ProductionEntity } from "../../../domain/entities";
import { useCaseProductions, useCaseSearch } from "../../../domain/useCases";
import { useNavigation } from "../../hooks";

export function useSearchPageViewModel() {
  const breadcrumbItemsElements = [
    {
      name: "Inicio",
      url: "/",
    },
    {
      name: "Búsqueda",
    },
  ];
  const token = useSelector(getSessionToken);
  const userSubscription = useSelector(getSubscriptionStatus);
  const { navigateTo, getQueryParam } = useNavigation();

  const [productions, setProductions] = useState<Array<ProductionEntity> | []>(
    []
  );
  const [podcastProductions, setPodcastProductions] = useState<
    Array<ProductionEntity> | []
  >();
  const [gameProductions, setGameProductions] = useState<
    Array<ProductionEntity> | []
  >();

  const [gameCategory, setGameCategory] = useState<CategoryEntity>();
  const [podcastsCategory, setPodcastCategory] = useState<CategoryEntity>();
  const [categories, setCategories] = useState<Array<CategoryEntity> | []>([]);
  const searchTerm = getQueryParam("searchTerm");

  const { getProductionsSearch } = useCaseSearch(searchDataService());
  const { getGameById } = useCaseProductions(productionsDataService());
  function successProductionSearched(data: SearchModel) {
    const productionList = data.productions;
    const categoryList = data.categories;
    const videoProductionsList = productionList.filter(
      (currentProduction) =>
        currentProduction.format !== "Podcast" &&
        currentProduction.format !== "Juegos"
    );
    const podcastProductionsList = productionList.filter(
      (currentProduction) => currentProduction.format === "Podcast"
    );
    const gameProductionsList = productionList.filter(
      (currentProduction) => currentProduction.format === "Juegos"
    );

    const categoryProductionsList = categoryList.filter(
      (currentProduction) =>
        currentProduction.slug !== "podcast" &&
        currentProduction.slug !== "juegos"
    );
    const gameCategoryList = categoryList.find(
      (currentProduction) => currentProduction.slug === "juegos"
    );
    const podcastCategoryList = categoryList.find(
      (currentProduction) => currentProduction.slug === "podcast"
    );

    setProductions(videoProductionsList);
    setPodcastProductions(podcastProductionsList);
    setGameProductions(gameProductionsList);

    setGameCategory(gameCategoryList);
    setPodcastCategory(podcastCategoryList);
    setCategories(categoryProductionsList);
  }

  function errorProductionSearched(e: AxiosError) {
    console.log("errorProductionSearched", e);
  }

  const handleProductionSearch = () => {
    getProductionsSearch({
      search: searchTerm,
      success: successProductionSearched,
      error: errorProductionSearched,
    });
  };

  /*Game card */
  const handleSuccessGameById = (data: any) => {
    if (data.url) {
      window.open(`${data.url}`, "_blank");
    }
  };

  const handleErrorGameById = (error: any) => {
    if (error.response.data.message) {
      if (!token) {
        toast.error("Debes iniciar sesión para acceder al contenido");
      } else {
        userSubscription?.stripeStatus !== "active"
          ? toast.error("Debes subscribirte para acceder al contenido")
          : toast.error(error.response.data.message);
      }
    }
  };

  const onGetGameById = (gameId: string) => {
    getGameById({
      id: gameId,
      success: handleSuccessGameById,
      error: handleErrorGameById,
    });
  };

  useEffect(() => {
    handleProductionSearch();
  }, [searchTerm]);

  return {
    breadcrumbItemsElements,
    productions,
    podcastProductions,
    gameProductions,
    navigateTo,
    searchTerm,
    categories,
    podcastsCategory,
    gameCategory,

    onGetGameById,
  };
}
