import { FC } from "react";
import {
  ChapterInProgressEntity,
  ProductionEntity,
} from "../../../domain/entities";
import { useNavigation } from "../../hooks";
import {
  StyledImage,
  StyledImageMobile,
  StyledInProgressCard,
  StyledLinearProgress,
} from "./InProgressCard.styles";
import LinearProgress from "@mui/material/LinearProgress";
import { ReactComponent as PlayButtonIcon } from "../../assets/images/playButtonIcon.svg";

export interface InProgressCardProps {
  className?: string;
  showOptions?: boolean;
  isCatalog?: boolean;
  productionData: ChapterInProgressEntity;
}

export const InProgressCard: FC<InProgressCardProps> = ({
  className,
  showOptions,
  isCatalog,
  productionData,
}: InProgressCardProps) => {
  const {
    uuid,
    name,
    productionTitle,
    productionFormat,
    productionUuid,
    imgPreview,
    imgPreviewMobile,
    alt,
    duration,
    progress,
  } = productionData;
  const { navigateTo } = useNavigation();
  const progressPercentage = (progress * 100) / duration;
  return (
    <StyledInProgressCard
      onClick={() =>
        navigateTo(`/preview?uuid=${productionUuid}&chapterUuid=${uuid}`)
      }
      className={`StyledCard ${className} cursor-pointer`}
    >
      <div className="relative flex flex-col justify-center">
        <PlayButtonIcon className="playButton" />
        <StyledImage
          src={imgPreview}
          alt={alt ? alt : `Imagen producción en progreso ${name}`}
          title={alt ? alt : `Imagen producción en progreso ${name}`}
        />
        <StyledImageMobile
          src={imgPreviewMobile ? imgPreviewMobile : imgPreview}
          alt={alt ? alt : `Imagen producción en progreso ${name}`}
          title={alt ? alt : `Imagen producción en progreso ${name}`}
        />
        <StyledLinearProgress
          variant="determinate"
          value={progressPercentage > 100 ? 100 : progressPercentage}
        />
      </div>
      <h3
        className={`uppercase flex justify-center lg:justify-start mt-2 self-center items-center text-white  lg:text-left font-primaryBold text-sm ${
          productionTitle?.length > 24
            ? productionTitle?.length > 40
              ? "lg:text-sm"
              : "lg:text-xl"
            : "lg:text-base"
        } `}
      >
        {productionTitle}
      </h3>
      <div
        className={`font-primaryItalic flex justify-center self-center items-center text-white lg:justify-start `}
      >
        <p className="capitalize">{productionFormat}</p>
      </div>
    </StyledInProgressCard>
  );
};
