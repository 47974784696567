import { FC } from "react";
import { DefaultLayout } from "../../layouts";
import {
  TextFieldControlled,
  PasswordFieldControlled,
  CheckBoxControlled,
  Button,
  Breadcrumb,
} from "../../components";
import { useLoginViewModel } from "../../viewModels";
import { useNavigation } from "../../hooks";

export const Login: FC = () => {
  const { handleSubmit, onSubmit, control, errors } = useLoginViewModel();
  const { navigateTo } = useNavigation();
  const breadcrumbItemsElemets = [
    {
      name: "Inicio",
      url: "/",
    },
    {
      name: "Inicia Sesión",
    },
  ];
  return (
    <DefaultLayout>
      <div className="px-8 lg:px-14 hidden lg:block">
        <Breadcrumb breadcrumbItems={breadcrumbItemsElemets} />
      </div>
      <div className="text-white max-w-screen-md mx-auto  text-center px-5 my-10">
        <h1 className="text-2 lg:text-3.5 font-primaryBold  ">Inicia Sesión</h1>
        <p
          className="flex flex-col lg:flex-row lg:justify-center gap-x-3
         text-base lg:text-2xl font-primaryBook"
        >
          ¡Hola de nuevo! <span>Nuestro contenido espera por tí</span>
        </p>
        <form className="mt-16 flex flex-col gap-y-6 mx-auto max-w-inputForm">
          <TextFieldControlled
            id={"email"}
            name="email"
            label="Correo electrónico:"
            placeholder="Escribe aquí tu correo"
            error={!!errors.email}
            helperText={errors?.email?.message}
            control={control}
            required
          />
          <PasswordFieldControlled
            id={"password"}
            name="password"
            label="Contraseña:"
            placeholder="Escribe aquí tu contraseña"
            error={!!errors.password}
            helperText={errors?.password?.message}
            control={control}
            required
          />
          <div className="flex gap-x-2  ">
            <p className="text-white">¿Olvidaste tu contraseña? </p>
            <a
              className="text-red-600 cursor-pointer"
              onClick={() => navigateTo("/recover-password")}
            >
              Recupérala aquí
            </a>
          </div>
          <CheckBoxControlled
            id={"remindMe"}
            name="remindMe"
            label="Recordar mis datos"
            error={!!errors.remindMe}
            helperText={errors?.remindMe?.message}
            control={control}
          />
          <Button
            className="max-w-fit mx-auto my-10"
            onClick={handleSubmit(onSubmit)}
          >
            Ingresar
          </Button>
        </form>
        <div className="wf-full flex flex-col lg:flex-row gap-x-2 justify-center mb-10 ">
          <p className="text-white">¿Aún no formas parte de storytraining?</p>
          <a
            className="text-red-600 cursor-pointer"
            onClick={() => navigateTo("/signup")}
          >
            Regístrate aquí
          </a>
        </div>
      </div>
    </DefaultLayout>
  );
};
