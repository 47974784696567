import tw from "twin.macro";
import styled from "styled-components";
import { styled as styledMUI } from "@mui/material/styles";
import LinearProgress from "@mui/material/LinearProgress";

export interface StyledInProgressCardProps {
  className?: string;
}

export const StyledInProgressCard = styled.article.attrs((props) => ({
  className: props.className,
}))<StyledInProgressCardProps>`
  ${tw` w-full px-3`}
  .playButton {
    ${tw`absolute top-1/2 left-1/2`}
    width: 30px;
    height: 30px;
    transform: translate(-50%, -50%);
    z-index: 10;
  }
`;

export const StyledLinearProgress = styledMUI(LinearProgress)((props) => ({
  height: "10px",
  width: "100%",
  position: "relative",
  zIndex: "10",
  backgroundColor: "#FFFFFF",

  [props.theme.breakpoints.down(1024)]: {
    height: "16px",
  },
  "& .MuiLinearProgress-bar1Determinate": {
    backgroundColor: "#E30613",
  },
  "& .MuiLinearProgress-bar2Determinate": {
    backgroundColor: "#FFFFFF",
  },
}));

export const StyledImage = styled.img.attrs({
  className: "StyledImage",
})`
  ${tw`relative object-cover mx-auto rounded-t-lg w-full h-64 lg:(h-28)`}
  @media (max-width: 480px) {
    display: none !important;
  }
`;

export const StyledImageMobile = styled.img.attrs({
  className: "StyledImageMobile",
})`
  ${tw`relative object-cover mx-auto rounded-t-lg w-full h-64 lg:(h-28)`}
  @media (min-width: 479.99px) {
    display: none !important;
  }
`;
