import tw from "twin.macro";
import styled from "styled-components";

export const StyledBreadcrumb = styled.ul.attrs((props) => ({
  className: props.className,
}))`
  ${tw`flex items-center space-x-0 ml-4`}
`;
export const StyledItem = styled.li.attrs((props) => ({
  className: props.className,
}))`
  ${tw`text-white font-primaryBold hidden lg:(block text-base) text-sm`}
  &:first-child,&:last-child {
    ${tw` block`}
  }
  &:last-child {
    ${tw`text-primary`}
  }
  a {
    ${tw` flex items-center`}
    &:after {
      content: "";
      width: 35px;
      height: 35px;
      ${tw` block bg-cover`}
      background-image: url("data:image/svg+xml,%3Csvg width='35' height='36' viewBox='0 0 35 36' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.125 26.75L21.875 18L13.125 9.25' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/%3E%3C/svg%3E%0A");
      @media (max-width: 1024px) {
        width: 24px;
        height: 24px;
      }
    }
  }
`;
