import {
  AuthenticationService,
  changePasswordInput,
  emailAndPasswordInput,
  recoveryPasswordInput,
  signUpInput,
  updateProfileInput,
  deleteNotificationInput,
  getNotificationInput
} from "../../domain/services/authentication.service";
import { SessionModel, ProfileModel, NotificationsModel } from "../models";
import { httpService } from "../http/services/http";
import { Endpoints } from "./endpoints";
import { handleResponse } from "../../domain/shared";
import {
  actSaveSession,
  actSaveProfileData,
  actLogoutSession,
} from "../dto/actions/session.action";
import { useDispatch } from "react-redux";

interface loginResponse {
  data: {
    data: SessionModel;
  };
}

interface profileResponse {
  data: {
    data: ProfileModel;
  };
}

interface notificationsResponse {
  data:{
    data: NotificationsModel;
  }
}
export function authenticationDataService(): AuthenticationService {
  const { get, post, deleteRequest } = httpService();
  const dispatch = useDispatch();
  return {
    async changePassword({
      token,
      email,
      password,
      passwordConfirmation,
      success,
      error,
    }: changePasswordInput) {
      try {
        await post(Endpoints.resetPassword, {
          token,
          email,
          password,
          password_confirmation: passwordConfirmation,
        });
        if (success) {
          success();
        }
      } catch (e) {
        if (error) {
          error(e);
        }
      }
    },
    async emailAndPasswordLogin({
      email,
      password,
      success,
      error,
    }: emailAndPasswordInput) {
      try {
        const data: loginResponse = await post(Endpoints.login, {
          email,
          password,
        });

        dispatch(actSaveSession(data.data.data));

        if (success) {
          success(data.data.data);
        }
      } catch (e) {
        if (error) {
          error(e);
        }
      }
    },
    async recoveryPassword({ email, success, error }: recoveryPasswordInput) {
      try {
        await post(Endpoints.forgotPassword, {
          email,
        });
        if (success) {
          success();
        }
      } catch (e) {
        if (error) {
          error(e);
        }
      }
    },
    async signUp({
      email,
      name,
      lastName,
      country,
      password,
      success,
      error,
    }: signUpInput) {
      try {
        await post(Endpoints.users, {
          name,
          email,
          last_name: lastName,
          password,
          country_id: country.id,
        });
        if (success) {
          success();
        }
      } catch (e) {
        if (error) {
          error(e);
        }
      }
    },
    async getProfile({ success, error }: handleResponse) {
      try {
        const response: profileResponse = await get(Endpoints.profile);
        dispatch(actSaveProfileData(response.data.data));

        if (success) {
          success();
        }
      } catch (e) {
        if (error) {
          error(e);
        }
      }
    },

    async updateProfile({
      name,
      lastName,
      email,
      oldPassword,
      password,
      photo,
      countryId,
      passwordConfirmation,
      success,
      error,
    }: updateProfileInput) {
      let formData = new FormData();
      formData.append("_method", "patch");
      name && formData.append("name", name);
      lastName && formData.append("last_name", lastName);
      email && formData.append("photo", email);
      oldPassword && formData.append("old_password", oldPassword);
      password && formData.append("password", password);
      photo && formData.append("photo", photo);
      countryId && formData.append("country_id", countryId);
      passwordConfirmation &&
        formData.append("password_confirmation", passwordConfirmation);
      try {
        const response: profileResponse = await post(
          Endpoints.profile,
          formData
        );
        dispatch(actSaveProfileData(response.data.data));

        if (success) {
          success();
        }
      } catch (e) {
        if (error) {
          error(e);
        }
      }
    },
    async logout({ success, error }: handleResponse) {
      try {
        const data = await post(Endpoints.logout, {});
        dispatch(actLogoutSession());
        if (success) {
          success(data);
        }
      } catch (e) {
        if (error) {
          error(e);
        }
      }
    },
    async getNotifications({page=1, success, error}: getNotificationInput){
      try {
        const response: notificationsResponse = await get(`${Endpoints.notifications}?page=${page}`);
        if (success) {
          success(response.data.data);
        }
      } catch (e) {
        if (error) {
          error(e);
        }
      }
    },
    async deleteNotification({id, success, error}: deleteNotificationInput){
      try {
        await deleteRequest(`${Endpoints.notifications}/${id}`); 
        if (success) {
          success();
        }
      } catch (e) {
        if (error) {
          error(e);
        }
      }
    }
  };
}
