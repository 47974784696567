import { FC, useEffect, useRef } from "react";
import {
  Button,
  SelectControlled,
  TextFieldControlled,
} from "../../../../components";
import {
  StyledContactForm,
  StyledContactFormSection,
} from "./ContactForm.styles";
import {
  Control,
  FieldValues,
  UseFormHandleSubmit,
  SubmitHandler,
  FieldErrors,
} from "react-hook-form";
import { TextAreaControlled } from "../../../../components/TextArea/TextAreaControlled";
import { ContactFormTermsModel } from "../../../../../data/models";
import {
  ContactFormTermsEntity,
  OptionsSelectEntity,
} from "../../../../../domain/entities";
import { useLocation } from "react-router";

interface contactFormProps {
  control: Control<FieldValues, any>;
  errors: FieldErrors<FieldValues>;
  handleSubmit: UseFormHandleSubmit<FieldValues>;
  onSubmit: SubmitHandler<FieldValues>;
  contactFormMatter: Array<OptionsSelectEntity>;
  contactFormProductMatterTerms: Array<OptionsSelectEntity>;
}

export const ContactForm: FC<contactFormProps> = ({
  control,
  errors,
  handleSubmit,
  onSubmit,
  contactFormMatter,
  contactFormProductMatterTerms,
}) => {
  return (
    <StyledContactFormSection id="contactForm">
      <StyledContactForm>
        <h3>CONTÁCTANOS</h3>
        <p className="font-primaryBook text-white text-sm lg:text-xl mb-8  ">
          Queremos leerte ¿Tienes un problema? ¿Tus dudas no fueron resueltas?
          ¿Tienes una recomendación o felicitación para nuestro equipo
        </p>

        <SelectControlled
          id={"contactMatter"}
          name="contactMatter"
          placeholder="Elige una opción"
          error={!!errors.contactMatter}
          helperText={errors?.contactMatter?.message}
          control={control}
          options={contactFormMatter}
          selectColor="secondary"
          defaultValue={contactFormMatter[0]?.id}
          className="my-2 max-w-selectContactForm lg:self-start"
        />
        <TextFieldControlled
          id={"email"}
          name="email"
          label="Correo electrónico"
          placeholder="Escribe aquí tu email"
          error={!!errors.email}
          helperText={errors?.email?.message}
          control={control}
          required
          className="py-2"
        />
        <SelectControlled
          id={"contactProductMatter"}
          name="contactProductMatter"
          label="¿Cómo uso storyTraining?"
          error={!!errors.contactProductMatter}
          helperText={errors?.contactProductMatter?.message}
          control={control}
          options={contactFormProductMatterTerms}
          required
          defaultValue={contactFormProductMatterTerms[0]?.id}
          className="my-2 "
        />
        <TextAreaControlled
          id={"description"}
          name="description"
          label="Descripción"
          placeholder="Cuéntanos cómo podemos ayudarte"
          error={!!errors.description}
          helperText={errors?.description?.message}
          control={control}
          required
          className="py-2"
        />
        <Button className="my-4" onClick={handleSubmit(onSubmit)}>
          Enviar
        </Button>
        <p className="font-primaryBook text-xs text-white">
          Al enviar la solicitud, aceptas el procesamiento de sus datos
          personales por parte de Storytraining, para gestionarla y responder a
          ella. Si deseas obtener más información, por favor consulte nuestra
          Política de privacidad.
        </p>
      </StyledContactForm>
    </StyledContactFormSection>
  );
};
