import tw from "twin.macro";
import styled from "styled-components";

export const StyledWarpperSliderContainer = styled.div.attrs({
  className: "StyledWarpperSliderContainer",
})`
  ${tw`w-full`}

  .slick-arrow {
    ${tw`  bg-transparent text-transparent`}
    width: 22px;
    height: 45px;
    left: -35px;
  }
  .slick-arrow:before {
    content: "";
    ${tw` block bg-no-repeat`}
    width: 22px;
    height: 45px;
    background-image: url("data:image/svg+xml,%3Csvg width='22' height='42' viewBox='0 0 22 42' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21 1L1 21L21 41' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/%3E%3C/svg%3E%0A");
  }
  .slick-next {
    right: -35px;
    left: inherit;
  }
  .slick-next:before {
    rotate: 180deg;
  }
  .slick-dots {
    ${tw` bottom-0 relative block gap-x-4 items-center justify-center pb-4 my-6`}
  }
  .slick-dots li {
    width: 10px;
    height: 10px;
  }
  .slick-dots li.slick-active button {
    ${tw` bg-white`}
  }
  .slick-dots li button {
    width: 10px;
    height: 10px;
    ${tw`  bg-gray-500 rounded-full`}
  }
  .slick-dots li button:before {
    ${tw`  hidden`}
  }
  .slick-track {
    ${tw`flex`}
  }
  .slick-slide {
    height: auto !important;
    > div {
      ${tw`h-full`}
    }
  }
`;
