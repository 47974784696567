import { AxiosError } from "axios";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import {
  getSessionToken,
  getSubscriptionStatus,
} from "../../../data/dto/selectors";
import { productionsDataService } from "../../../data/services";
import { CategoryEntity } from "../../../domain/entities";
import { useCaseProductions } from "../../../domain/useCases";
import { useCustomEffect } from "../../hooks";

const breadcrumbItemsElements = [
  {
    name: "Inicio",
    url: "/",
  },
  {
    name: "Catálogo",
  },
];

export function useCatalogViewModel() {
  const token = useSelector(getSessionToken);
  const userSubscription = useSelector(getSubscriptionStatus);
  const [productions, setProductions] = useState<Array<CategoryEntity> | []>(
    []
  );
  const [podcastProductions, setPodcastProductions] =
    useState<CategoryEntity>();
  const [gameProductions, setGameProductions] = useState<CategoryEntity>();
  const { getAll, getGameById } = useCaseProductions(productionsDataService());

  function successProduction(productions: Array<CategoryEntity>) {
    const videoProductionsList = productions.filter(
      (currentProduction) =>
        currentProduction.slug !== "podcast" &&
        currentProduction.slug !== "juegos"
    );
    const podcastProductionsList = productions.find(
      (currentProduction) => currentProduction.slug === "podcast"
    );
    const gameProductionsList = productions.find(
      (currentProduction) => currentProduction.slug === "juegos"
    );
    setProductions(videoProductionsList);
    setPodcastProductions(podcastProductionsList);
    setGameProductions(gameProductionsList);
  }

  function errorProduction(e: AxiosError) {
    console.log("errorProductionFiltered", e);
  }
  /*Game card */
  const handleSuccessGameById = (data: any) => {
    if (data.url) {
      window.open(`${data.url}`, "_blank");
    }
  };

  const handleErrorGameById = (error: any) => {
    if (error.response.data.message) {
      if (!token) {
        toast.error("Debes iniciar sesión para acceder al contenido");
      } else {
        userSubscription?.stripeStatus !== "active"
          ? toast.error("Debes subscribirte para acceder al contenido")
          : toast.error(error.response.data.message);
      }
    }
  };

  const onGetGameById = (gameId: string) => {
    getGameById({
      id: gameId,
      success: handleSuccessGameById,
      error: handleErrorGameById,
    });
  };
  useCustomEffect(() => {
    getAll({
      success: successProduction,
      error: errorProduction,
    });
  }, []);

  return {
    breadcrumbItemsElements,
    productions,
    podcastProductions,
    gameProductions,
    onGetGameById,
  };
}
