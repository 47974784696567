import tw from "twin.macro";
import styled from "styled-components";

export interface StyledCardProps {
    className?: string;
    withBadge?: boolean;
}

export const StyledSearchCard = styled.article.attrs((props) => ({
    className: props.className,
  }))<StyledCardProps>`
    ${tw`grid gap-y-2 `}
    grid-template-rows: 216px 30px 60px 48px 96px;
  
    .playButton {
      ${tw`absolute top-1/2 left-1/2`}
      width: 30px;
      height: 30px;
      transform: translate(-50%, -50%);
    }
  
    @media (max-width: 1024px) {
      ${tw`flex flex-col items-center gap-y-1 relative`}
    }
  `;

export const StyledImage = styled.img.attrs({
    className: "StyledImage",
  })`
    ${tw`rounded-lg w-full object-cover `}
    height: 216px;
    max-width: 384px;
    @media (max-width: 1024px) {
      max-height: 87px;
      width: 156px;
      max-width: 156px;
      height: 87x;
    }
  `;