import tw from "twin.macro";
import styled from "styled-components";

export const StyledLearningPathCalendarContainer = styled.section.attrs({
    className: "StyledLearningPathCalendarContainer"
})
`
${tw`text-center`}
`;

export const StyledTitledDescripcionCalendar = styled.div.attrs({
    className: "StyledTitledDescripcionCalendar"
})
`
${tw`flex flex-col items-center my-8 mx-5 xl:(mx-[424px]) text-white`}
h1 {
    ${tw`font-primaryBold text-xl my-[26px] xl:(text-[32px])`}
}
p {
    ${tw`font-primaryBook text-sm xl:(text-base)`}
}
`;

export const StyledCalendarSection = styled.div.attrs({
    className: "StyledCalendarSection"
})
`
${tw`mb-8 flex flex-col  items-center my-8 bg-secondary-100 xl:(mx-[400px] rounded-[48px])`}
h2 {
    ${tw`font-primaryBold text-xl mb-6 text-white`}
}
p {
    ${tw`text-sm text-white`}
}
.select {
    ${tw`lg:(w-[325px])`}
}
`;