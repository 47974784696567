import { httpService } from "../http/services/http";
import { Endpoints } from "./endpoints";
import { GeneralSEOModel } from "../models";
import {
  GeneralSEO,
  GeneralSEOService,
} from "../../domain/services/generalSEO.service";

export interface GeneralSEOResponse {
  data: {
    data: GeneralSEOModel;
  };
}

export function generalSEOService(): GeneralSEOService {
  const { get } = httpService();
  return {
    async getSEOPathname({ pathName, success, error }: GeneralSEO) {
      try {
        const response: GeneralSEOResponse = await get(
          `${Endpoints.seo}${pathName}`
        );
        if (success) {
          success(response.data.data);
        }
      } catch (e) {
        if (error) {
          error(e);
        }
      }
    },
  };
}
