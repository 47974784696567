import { yupResolver } from "@hookform/resolvers/yup";
import { FieldValues, useForm } from "react-hook-form";
import { useCaseFaqs } from "../../../domain/useCases";
import { contactFormSchema } from "../../validators";
import { faqsDataService } from "../../../data/services";
import { useState } from "react";
import { AxiosError } from "axios";
import { useCustomEffect } from "../../hooks";
import {
  FaqsEntity,
  ContactFormTermsEntity,
  OptionsSelectEntity,
} from "../../../domain/entities";
import toast from "react-hot-toast";

export function useFaqsViewModel() {
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    reset,
  } = useForm({ mode: "onChange", resolver: yupResolver(contactFormSchema) });

  const breadcrumbItemsElements = [
    {
      name: "Inicio",
      url: "/",
    },
    {
      name: "Preguntas frecuentes",
    },
  ];

  const [frequentAskedQuestions, setFrequentAskedQuestions] = useState<
    Array<FaqsEntity> | []
  >([]);
  const [contactFormMatter, setContactFormMatter] = useState<
    Array<OptionsSelectEntity>
  >([]);
  const [contactFormProductMatterTerms, setContactFormProductMatterTerms] =
    useState<Array<OptionsSelectEntity>>([]);

  const {
    get,
    post,
    getContactFormMatterTerms,
    getContactFormProductMatterTerms,
  } = useCaseFaqs(faqsDataService());

  function onSuccessProduction(frequentAskedQuestions: Array<FaqsEntity>) {
    setFrequentAskedQuestions(frequentAskedQuestions);
  }

  function onErrorProduction(e: AxiosError) {
    console.log("errorFrequentAskedQuestions", e);
  }

  function onSuccessContactFormMatter(
    contactFormMatter: Array<ContactFormTermsEntity>
  ) {
    const contactFormMatterMapped = contactFormMatter.map((matter) => ({
      id: matter.uuid,
      name: matter.title,
    }));
    setContactFormMatter(contactFormMatterMapped);
  }

  function onErrorContactFormMatter(e: AxiosError) {
    console.log("errorContactFormMatter", e);
  }

  function onSuccessContactFormProductMatterTerms(
    contactFormProductMatterTerms: Array<ContactFormTermsEntity>
  ) {
    const contactFormProductMatterMapped = contactFormProductMatterTerms.map(
      (matter) => ({
        id: matter.uuid,
        name: matter.title,
      })
    );
    setContactFormProductMatterTerms(contactFormProductMatterMapped);
  }

  function onErrorContactFormProductMatterTerms(e: AxiosError) {
    console.log("errorContactFormProductMatterTerms", e);
  }

  const onSubmitContactFormSuccess = () => {
    toast.success("¡Tu mensaje ha sido enviado exitosamente!");
    reset({
      contactMatter: contactFormMatter[0]?.id,
      email: "",
      contactProductMatter: contactFormProductMatterTerms[0]?.id,
      description: "",
    });
  };

  // on submit contact form
  const onSubmit = (data: FieldValues) => {
    const contactFormData = {
      contactMatter: data.contactMatter,
      email: data.email,
      contactProductMatter: data.contactProductMatter,
      description: data.description,
      success: onSubmitContactFormSuccess,
    };
    post(contactFormData);
  };

  useCustomEffect(() => {
    setValue("contactMatter", contactFormMatter[0]?.id);
  }, [contactFormMatter]);

  useCustomEffect(() => {
    setValue("contactProductMatter", contactFormProductMatterTerms[0]?.id);
  }, [contactFormProductMatterTerms]);

  useCustomEffect(() => {
    get({
      success: onSuccessProduction,
      error: onErrorProduction,
    });
    getContactFormMatterTerms({
      success: onSuccessContactFormMatter,
      error: onErrorContactFormMatter,
    });
    getContactFormProductMatterTerms({
      success: onSuccessContactFormProductMatterTerms,
      error: onErrorContactFormProductMatterTerms,
    });
  }, []);

  return {
    breadcrumbItemsElements,
    frequentAskedQuestions,
    handleSubmit,
    control,
    errors,
    onSubmit,
    contactFormMatter,
    contactFormProductMatterTerms,
  };
}
