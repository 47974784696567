import { FC } from "react";
import { StyledStatusCard } from "./StatusCard.styles";
import { ReactComponent as CheckMark } from "../../assets/images/checkMarkCompleted.svg";
import { useStatusCard } from "./useStatusCard";

export interface StatusCardProps {
  status: string;
  medalId: string;
  isScheduled: boolean;
  learningPathId?: string;
  handlePayMedal?: (learningPathId: string, medalId: string) => void;
}

export const StatusCard: FC<StatusCardProps> = ({
  status,
  medalId,
  isScheduled,
  learningPathId,
  handlePayMedal,
}) => {
  const { handleCopyButton, handleOnClickButton } = useStatusCard({
    status,
    medalId,
    isScheduled,
    learningPathId,
    handlePayMedal,
  });
  return (
    <StyledStatusCard
      onClick={() => handleOnClickButton()}
      medalId={medalId}
      className={`StyledStatusCard ${
        handleCopyButton() === "IR A PAGAR" ||
        handleCopyButton() === "IR A CALENDARIO"
          ? "cursor-pointer"
          : ""
      }`}
    >
      <p>{handleCopyButton()}</p>
      {status === "completed" && <CheckMark />}
    </StyledStatusCard>
  );
};
