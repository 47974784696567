import { Dispatch, FC, SetStateAction } from "react";
import { Button, Modal } from "../../../../components";
import { useNavigation } from "../../../../hooks";
import { ReactComponent as CalendarIcon } from "../../../../assets/images/calendarLogo.svg";
import { FieldValues, UseFormHandleSubmit } from "react-hook-form/dist/types";

export interface ModalsCalendarProps {
  showDateModal: boolean;
  setShowDateModal: Dispatch<SetStateAction<boolean>>;
  handleSubmit: UseFormHandleSubmit<FieldValues>;
  onSubmit: (data: FieldValues) => void;
  showSuccessModal: boolean;
  handleSuccess: () => void;
  title?: string;
  dateForModal: string;
  hoursForModal: string;
  showErrorModal: boolean;
  setShowErrorModal: Dispatch<SetStateAction<boolean>>;
}

export const ModalsCalendar: FC<ModalsCalendarProps> = ({
  showDateModal,
  setShowDateModal,
  handleSubmit,
  onSubmit,
  showSuccessModal,
  handleSuccess,
  title,
  dateForModal,
  hoursForModal,
  showErrorModal,
  setShowErrorModal,
}) => {
  return (
    <>
      <Modal showModal={showDateModal}>
        <div className="text-center flex flex-col justify-center items-center max-w-xl">
          <h3 className="font-primaryBold text-primary-100 text-2xl mb-4 lg:text-2 lg:w-2/3">
            ¡Vas a programar tú Curso en formato síncrono!
          </h3>
          <CalendarIcon className="mb-5" />
          <p className="font-primaryBook text-base mb-4 lg:w-2/3">
            Al pulsar “<span className="font-primaryBold">Aceptar</span>”
            iniciarás la fase Gold de nuestro Camino de Aprendizaje: “
            <span className="font-primaryBold">{title}</span>”.
          </p>
          <p className="font-primaryBold text-primary-100 text-sm mb-6 lg:text-2xl">
            Fecha de inicio: {dateForModal}
          </p>
          <p className="font-primaryBook text-base mb-4 lg:w-2/3">
            *La fecha de inicio se muestra en{" "}
            <span className="font-primaryBold">horario local</span>
          </p>

          <div className="flex flex-row">
            <Button
              className="mx-4"
              color="third"
              onClick={() => {
                setShowDateModal(false);
              }}
            >
              Cancelar
            </Button>
            <Button className="mx-4" onClick={handleSubmit(onSubmit)}>
              Aceptar
            </Button>
          </div>
        </div>
      </Modal>

      <Modal showModal={showSuccessModal}>
        <div className="text-center flex flex-col justify-center items-center max-w-xl">
          <h3 className="font-primaryBold text-primary-100 text-2xl mb-4 lg:text-2 lg:w-2/3">
            ¡Programaste tu Curso de manera exitosa!
          </h3>
          <CalendarIcon className="mb-5" />
          <p className="font-primaryBook text-sm mb-4 lg:w-2/3">
            Estás a un paso de lograr la certificación Gold.
          </p>
          <p className="font-primaryBold text-primary-100 text-sm lg:text-2xl">
            Fecha de inicio: {dateForModal}
          </p>
          <p className="font-primaryBold text-primary-100 text-sm mb-6 lg:text-2xl">
            Horario: {hoursForModal}
          </p>
          <p className="font-primaryBook text-sm mb-4 lg:w-2/3">
            *Fecha y horas en{" "}
            <span className="font-primaryBold">hora local</span>
          </p>
          <Button className="mx-4" onClick={handleSuccess}>
            Ir a mi storyTraining
          </Button>
        </div>
      </Modal>

      <Modal showModal={showErrorModal}>
        <div className="text-center flex flex-col justify-center items-center max-w-xl">
          <h3 className="font-primaryBold text-primary-100 text-2xl mb-4 lg:text-2 lg:w-2/3">
            Fase Gold en progreso
          </h3>
          <CalendarIcon className="mb-5" />
          <p className="font-primaryBook text-sm mb-4 lg:w-2/3">
            Ésta es la programación para certifcarte en Gold
          </p>
          <p className="font-primaryBold text-primary-100 text-sm lg:text-2xl">
            Fecha de inicio: {dateForModal}
          </p>
          <p className="font-primaryBold text-primary-100 text-sm mb-6 lg:text-2xl">
            Horario: {hoursForModal}
          </p>
          <Button
            className="mx-4"
            onClick={() => {
              setShowErrorModal(false);
            }}
          >
            Aceptar
          </Button>
        </div>
      </Modal>
    </>
  );
};
