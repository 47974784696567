import { useState } from "react";
import { useSelector } from "react-redux";
import {
  getAvatar,
  getUser,
} from "../../../../data/dto/selectors/session.selector";
import { useNavigation } from "../../../hooks";

export const useProfileDropDown = () => {
  const user = useSelector(getUser);
  const userAvatar = useSelector(getAvatar);
  const { navigateTo } = useNavigation();
  const [selectValue, setSelectValue] = useState<boolean>(false);

  const onToggleDropDown = () => {
    setSelectValue(!selectValue);
  };
  return {
    user,
    userAvatar,
    selectValue,
    onToggleDropDown,
    navigateTo,
  };
};
