import { FC, PropsWithChildren, ReactNode } from "react";
import { alpha, styled } from "@mui/material/styles";
import {
  StyledAccordion,
  StyledAccordionSummary,
  StyledAccordionDetails,
} from "./Accordion.styles";
import { ArrowDownIcon } from "../../assets/Icons";

interface AccordionProps {
  className?: string;
  title: string;
}

export const Accordion: FC<PropsWithChildren<AccordionProps>> = ({
  children,
  className,
  title,
}) => {
  return (
    <StyledAccordion className={className}>
      <StyledAccordionSummary expandIcon={<ArrowDownIcon color="#FFFFFF" />}>
        <h3 className="m-0 text-sm lg:text-2xl font-primaryBold lg:font-primarySemibold text-white">
          {title}
        </h3>
      </StyledAccordionSummary>
      <StyledAccordionDetails>{children}</StyledAccordionDetails>
    </StyledAccordion>
  );
};
