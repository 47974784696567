import { FC } from "react";
import { PillEntity, ProductionEntity } from "../../../domain/entities";
import { useNavigation } from "../../hooks";
import { Badge } from "../Badge";
import {
  StyledContainerBadge,
  StyledContainerImage,
  StyledImage,
  StyledImageMobile,
  StyledSavedCard,
} from "./SavedCard.styles";
import { ReactComponent as PlayButtonIcon } from "../../assets/images/playButtonIcon.svg";

export interface SavedCardProps {
  className?: string;
  showOptions?: boolean;
  isCatalog?: boolean;
  productionData: ProductionEntity;
}

export const SavedCard: FC<SavedCardProps> = ({
  className,
  showOptions,
  isCatalog,
  productionData,
}: SavedCardProps) => {
  const {
    uuid,
    title,
    description,
    productionType,
    imgPreview,
    imgPreviewMobile,
    alt,
    duration,
    terms,
    seasonsCount,
    format,
  } = productionData;
  const { navigateTo } = useNavigation();
  return (
    <StyledSavedCard
      onClick={() => navigateTo(`/preview?uuid=${uuid}`)}
      className={`StyledCard ${className} cursor-pointer`}
    >
      <StyledContainerImage>
        <PlayButtonIcon className="playButton" />
        <StyledImage
          src={imgPreview}
          alt={alt ? alt : `Imagen producción en favoritos ${title}`}
          title={alt ? alt : `Imagen producción en favoritos ${title}`}
        />
        <StyledImageMobile
          src={imgPreviewMobile ? imgPreviewMobile : imgPreview}
          alt={alt ? alt : `Imagen producción en favoritos ${title}`}
          title={alt ? alt : `Imagen producción en favoritos ${title}`}
        />
      </StyledContainerImage>

      <div className="">
        <h3
          className={`uppercase mt-2 self-center items-center text-white  lg:text-left font-primaryBold ${
            title.length > 24
              ? title.length > 40
                ? "lg:text-sm"
                : "lg:text-xl"
              : "text-sm lg:text-base"
          } `}
        >
          {title}
        </h3>
        <div
          className={`font-primarySemiboldItalic self-center items-center text-white `}
        >
          <p className="capitalize">
            {productionType == "serie"
              ? seasonsCount > 1
                ? `${seasonsCount} temporadas`
                : `${seasonsCount} temporada`
              : format}
          </p>
        </div>
        <p className=" text-white text-xs mt-2 sm:text-base">
          {`${description.substring(0, 120)}...`}
          <a className="font-primarySemiBold text-primary"> Ver más</a>
        </p>
      </div>
      {!!terms?.length && (
        <StyledContainerBadge>
          {terms?.map((item: PillEntity, i: number) => {
            return (
              <Badge
                className="w-fit py-1"
                key={`badge${i}`}
                title={item.title.toLowerCase()}
              />
            );
          })}
        </StyledContainerBadge>
      )}
    </StyledSavedCard>
  );
};
