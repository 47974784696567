import { useState } from "react";
import { homeDataService } from "../../../data/services";
import { CategoryEntity } from "../../../domain/entities";
import { useCaseHome } from "../../../domain/useCases";
import { useCustomEffect } from "../../hooks";

export function usePageNotFoundViewModel() {
  const [favoritesProductions, setFavoritesProductions] = useState<
    Array<CategoryEntity> | []
  >([]);
  const { getFavorites } = useCaseHome(homeDataService());

  /*Handle 404 favorites */
  const onGetHomeFavoritesSuccess = (
    favoritesProductions: Array<CategoryEntity>
  ) => {
    setFavoritesProductions(favoritesProductions);
  };

  useCustomEffect(() => {
    getFavorites({
      success: onGetHomeFavoritesSuccess,
    });
  }, []);

  return {
    favoritesProductions,
  };
}
