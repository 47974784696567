import tw from "twin.macro";
import { styled } from "@mui/material/styles";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionProps,
  AccordionSummaryProps,
  AccordionDetailsProps,
} from "@mui/material";

export const StyledAccordion = styled(Accordion)<AccordionProps>(
  ({ theme }) => ({
    borderRadius: "8px !important",
    backgroundColor: "transparent",
    margin: "0 !important",
    "& >.Mui-expanded": {
      backgroundColor: "#C1101A",
      borderRadius: "8px 8px 0 0 ",
      transition: "none",
    },
    "& .MuiCollapse-entered": {
      transitionDelay: "1201ms",
    },
    [theme.breakpoints.down(1024)]: {
      borderRadius: "0px  !important",
      borderTop: "2px solid rgba(255, 255, 255,0.2)",
      "& >.Mui-expanded": {
        backgroundColor: "transparent",
        "& h3, svg": {
          color: "red",
          "& >path": {
            stroke: "red !important",
          },
        },
      },
    },
  })
);

export const StyledAccordionSummary = styled(
  AccordionSummary
)<AccordionSummaryProps>(({ theme }) => ({
  minHeight: "53px !important",
  margin: "0",
  padding: "12px 24px",
  gap: "8px",
  borderRadius: "8px",
  backgroundColor: "#434343",
  transition: "border-radius 1s",
  "& .MuiAccordionSummary-content": {
    margin: "0px !important",
  },
  [theme.breakpoints.down(1024)]: {
    padding: "8px",
    borderRadius: "0px",
    backgroundColor: "transparent",
  },
}));

export const StyledAccordionDetails = styled(
  AccordionDetails
)<AccordionDetailsProps>(({ theme }) => ({
  backgroundColor: "#FFFFFF",
  padding: "12px 24px",
  borderRadius: "0 0 8px 8px",
  fontSize: "14px",
  lineHeight: "21px",
  [theme.breakpoints.down(1024)]: {
    borderRadius: "0px",
    backgroundColor: "#434343",
    color: "#ffffff",
  },
}));
