import tw from "twin.macro";
import styled from "styled-components";

export interface StyledSubscriptionCard {
  className?: string;
  isBestOffer?: boolean;
}

export const StyledSubscriptionCard = styled.article.attrs((props) => ({
  className: props.className,
}))<StyledSubscriptionCard>`
  ${tw`relative w-full lg:w-subscriptionCard max-w-subscriptionCard lg:min-h-subscriptionCardDesktop p-6 flex flex-col items-center gap-y-4 rounded-3xl`}

  ${({ isBestOffer }) =>
    isBestOffer
      ? `
  background: linear-gradient(161.68deg, rgba(67, 67, 67, 0) 0%, #434343 57.46%);
  `
      : tw`bg-transparent lg:bg-secondary-200`}
`;

export const StyledRibbon = styled.div.attrs({
  className: "StyledRibbon",
})`
  ${tw`absolute top-0 right-0 w-36 h-36 hidden place-content-center text-center overflow-hidden lg:(grid)`}
  span {
    ${tw`absolute w-[12.7rem] bg-primary-50 px-12 text-white text-xl font-primarySemibold`}
    height: 56px;
    top: 50%;
    left: 50%;
    transform: translate(calc(-50% + 20px), calc(-50% - 20px)) rotate(45deg);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }
`;

export const StyledImage = styled.img.attrs({
  className: "StyledImage",
})`
  ${tw`max-h-36`}
`;

export const StyledDescriptionList = styled.ul.attrs({
  className: "StyledDescriptionList",
})`
  ${tw`w-full lg:( flex flex-col gap-y-4 overflow-hidden)`}
  li:not(:last-child) {
    ${tw`hidden lg:grid`}
  }
`;

export const StyledDescriptionItem = styled.li.attrs({
  className: "StyledDescriptionItem",
})`
  ${tw`flex text-center lg:(pl-4 grid items-center gap-x-5 text-left)`}
  grid-template-columns: 20px auto;

  p {
    ${tw`text-sm  text-white font-primarySemibold`}
  }

  svg {
    ${tw`hidden lg:block`}
  }
`;

export const StyledParagraphTip = styled.p.attrs((props) => ({
  className: props.className,
}))`
  ${tw`max-w-[13rem] text-center text-xs text-white font-primaryBook`}
`;

export const StyledLink = styled.a.attrs((props) => ({
  className: props.className,
}))`
  ${tw`max-w-[13rem] text-center text-xs text-white font-primaryBook`}
`;
