import {
  LearningPathCalendarService,
  listSessionsInput,
  postSignUpForSessionInput,
} from "../../services/learningPathCalendar.service";

export function useCaseLearningPathCalendar(
  service: LearningPathCalendarService
) {
  return {
    getListSessions({
      success,
      error,
      learningPathUuid,
      medalId,
      timeZone,
    }: listSessionsInput) {
      return service.getListSessions({
        success,
        error,
        learningPathUuid,
        medalId,
        timeZone,
      });
    },
    postSignUpForSession({
      success,
      error,
      learningPathUuid,
      medalId,
      id,
    }: postSignUpForSessionInput) {
      return service.postSignUpForSession({
        success,
        error,
        learningPathUuid,
        medalId,
        id,
      });
    },
  };
}
