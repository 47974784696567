import { FC } from "react";
import {
  StyledButton,
  StyledButtonRent,
  StyledInformationSection,
} from "./InformationSection.styles";
import { FolderIcon, HeartIcon } from "../../../../assets/Icons";
import { Button, RatingModal } from "../../../../components";
import { useNavigation } from "../../../../hooks";
import { PriceEntity } from "../../../../../domain/entities";
import { FieldValues } from "react-hook-form";

interface InformationSectionProps {
  token?: string;
  title: string;
  description: string;
  onClick?: (data: any) => void;
  isProductionSaved: boolean;
  isSubscriptionActive: boolean;
  isProductionRented: boolean;
  price?: PriceEntity;
  navigateToRental: () => void;
  userCanComment: boolean;
  showModalRating: boolean;
  onSubmitRating: (data: FieldValues) => void;
  onCancelRating: (data: any) => void;
  onClickRatingButton: (data: any) => void;
}
enum CurrencySymbol {
  eur = "€",
  usd = "$",
}
export const InformationSection: FC<InformationSectionProps> = ({
  token,
  description,
  title,
  onClick,
  isProductionSaved,
  isProductionRented,
  isSubscriptionActive,
  price,
  navigateToRental,
  userCanComment,
  showModalRating,
  onSubmitRating,
  onCancelRating,
  onClickRatingButton,
}) => {
  const { navigateTo } = useNavigation();
  const finalPrice = price
    ? (price?.unitAmount / 100.0).toString().replace(".", ",")
    : "";
  return (
    <StyledInformationSection>
      {!!token && (
        <div className="w-full pb-4 flex flex-col items-end gap-y-6">
          {!isSubscriptionActive && (
            <div className="w-full flex flex-col gap-8 justify-center lg:justify-end lg:flex-row lg:items-center text-center">
              <p className="font-primaryBold text-sm lg:text-base">
                ¿Te gustaría acceder a todos nuestros beneficios?{" "}
              </p>
              <Button
                className="w-full lg:w-32"
                onClick={() => navigateTo("/subscriptions")}
              >
                Suscríbete
              </Button>
            </div>
          )}
          {!isSubscriptionActive && !isProductionRented && price && (
            <div className="w-full flex flex-col lg:flex-row lg:justify-end items-center gap-4">
              <p className="text-white text-xs font-primaryBook">
                También puedes
              </p>
              <div className="flex gap-4 items-center">
                <StyledButtonRent onClick={navigateToRental}>
                  Alquilar esta producción
                </StyledButtonRent>
                <p>
                  <strong className="font-primaryBold">{`${CurrencySymbol[price?.currency]
                    } ${finalPrice}`}</strong>
                </p>
              </div>
            </div>
          )}
        </div>
      )}
      <div className="flex flex-row justify-end py-4 lg:justify-between gap-x-4">
        {title &&
          <h1 className="flex gap-x-4 items-center w-full justify-between lg:justify-start text-xl font-primaryBold">
            {title}
            {isSubscriptionActive && (
              <HeartIcon
                fill={!isProductionSaved ? "none" : "#C1101A"}
                onClick={!isProductionSaved ? onClick : () => { }}
                color="#C1101A"
                className="folderLogo cursor-pointer hidden lg:block"
              />
            )}
          </h1>
        }
        {!token && (
          <StyledButton onClick={() => navigateTo("/signup")}>
            ¡Regístrate ahora!
          </StyledButton>
        )}
        {isSubscriptionActive && userCanComment && (
          <Button color="secondary" onClick={onClickRatingButton}>
            Calificar este título
          </Button>
        )}
      </div>
      <p className="text-base font-primaryBook">{description}</p>
      <RatingModal
        showModal={showModalRating}
        onSubmit={onSubmitRating}
        onCancel={onCancelRating}
      />
    </StyledInformationSection>
  );
};
