import { FieldValues, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { createPasswordSchema } from "../../validators";
import { useNavigation, useObjectToArray } from "../../hooks";
import { useCustomEffect } from "../../hooks/useHookEffect";
import { useCaseResetPassword } from "../../../domain/useCases";
import { authenticationDataService } from "../../../data/services/authentication.data.service";
import { useState } from "react";
import toast from "react-hot-toast";

export function useCreatePasswordViewModel() {
  const { navigateTo, getQueryParam } = useNavigation();
  const { run } = useCaseResetPassword(authenticationDataService());
  const [showModalResetPasswordSuccess, setShowModalResetPasswordSuccess] =
    useState(false);
  const {
    handleSubmit,
    control,
    formState: { errors },
    setError
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(createPasswordSchema),
  });
  const breadcrumbItemsElements = [
    {
      name: "Inicio",
      url: "/",
    },
    {
      name: "Inicia Sesión",
      url: "/login",
    },
    {
      name: "Crear una nueva contraseña",
    },
  ];

  /*handlers modal*/
  const handleCloseModal = () => {
    setShowModalResetPasswordSuccess(false);
    navigateTo("/login");
  };

  /*handlers reset password submit*/
  const onResetPasswordSuccess = () => {
    setShowModalResetPasswordSuccess(true);
  };

  const onCreatePasswordError = (error: any) => {
    toast.error(error.response.data.message)
  }

  const onSubmit = (data: FieldValues) => {
    const email = getQueryParam("email");
    const token = getQueryParam("token");

    const resetPasswordData = {
      email: email as string,
      token: token as string,
      password: data.password,
      passwordConfirmation: data.passwordConfirmation,
      success: onResetPasswordSuccess,
      error: onCreatePasswordError
    };

    run(resetPasswordData);
  };

  return {
    breadcrumbItemsElements,

    showModalResetPasswordSuccess,
    handleCloseModal,

    handleSubmit,
    control,
    errors,
    onSubmit,
  };
}
