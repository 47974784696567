import React, { FC } from "react";
import { Accordion, Breadcrumb, Navbar } from "../../components";
import { DefaultLayout } from "../../layouts";
import { useFaqsViewModel } from "../../viewModels";
import { ContactForm, FaqsSection } from "./components";
import { StyledFaqsContactsContainer } from "./faqs.styles";

export const Faqs: FC = () => {
  const { breadcrumbItemsElements, frequentAskedQuestions, control, errors, handleSubmit, onSubmit, contactFormMatter, contactFormProductMatterTerms} = useFaqsViewModel();

  return (
    <DefaultLayout>
      <div className="w-full lg:hidden">
        <Navbar/>
      </div>
      <div className="px-8 pb-8 lg:px-14 hidden lg:block">
        <Breadcrumb breadcrumbItems={breadcrumbItemsElements} />
      </div>
      <StyledFaqsContactsContainer>
        <FaqsSection faqsData={frequentAskedQuestions} />
        <ContactForm control={control} errors={errors} handleSubmit={handleSubmit} onSubmit={onSubmit} contactFormMatter={contactFormMatter} contactFormProductMatterTerms={contactFormProductMatterTerms}/>
      </StyledFaqsContactsContainer>
    </DefaultLayout>
  );
};
