import { StaticDatePicker, DesktopDatePicker } from '@mui/x-date-pickers';
import { styled } from "@mui/material";

export const StyledDatePicker = styled(StaticDatePicker)(
    ({theme}) => ({
      background: 'transparent',
      width: "100%",
      "& .PrivatePickersSlideTransition-root.MuiDayPicker-slideTransition":{
        minHeight: "256px"
      },
      "& .MuiCalendarOrClockPicker-root":{
        "& >div":{
          margin: "0px",
          width: "100%",
        }
      },
      "&. MuiPickerStaticWrapper-root": {
        height: "258px",
        minHeight: "256px",
      },
      '& >.MuiPickerStaticWrapper-content': {
        background: 'transparent',
        width: "100%",
        '& .MuiCalendarPicker-root': {
          background: "#FFFFFF", 
          borderRadius: "8px",   
          paddingBottom: "20px",
          width: "100%",
          height: "100%",
          minHeight: "256px",
          '& .MuiPickersCalendarHeader-root':{
            position: "relative",
            justifyContent: 'center',
            padding: '0px',
            margin: "18px 16px",
            marginBottom: "6px",
            '& .MuiPickersCalendarHeader-labelContainer': {
              margin: '0px auto',
              fontFamily: "Carnero-Bold, sans-serif",
              fontSize: "14px",
              textTransform: "capitalize",
            },
            '& .MuiPickersArrowSwitcher-root': {
              position: "absolute",
              width: "100%",
              justifyContent: "space-between"
            },
          },
          '& .MuiPickersDay-root.Mui-selected': {
            backgroundColor: "#C1101A",
          },
          "& .PrivatePickersSlideTransition-root.MuiDayPicker-slideTransition": {
            minHeight: "180px"
          },
          "& .MuiTypography-root": {
            fontFamily: "Carnero-Semibold, sans-serif",
            fontSize: "12px"
          },  
          "& .MuiButtonBase-root.MuiPickersDay-root": {
            fontFamily: "Carnero-regular, sans-serif",
            fontSize: "14px",
            borderRadius: "8px",   
            width: "36px",
            height: "29px",
          }
        },
      },
    })
  );

export const StyledInputDate = styled(DesktopDatePicker)(
 () => ({
   background: "#FAFAFB",
   borderRadius: "8px",
   width: "100%",
   marginBottom: "8px",
   "& .MuiInputBase-root": {
    flexDirection: "row-reverse",
   },
   "& .MuiInputBase-input":{
    textTransform: "capitalize",
    fontFamily: "Carnero-Regular, sans-serif",
    color: "#8C8C8C",
   }
 })
)