import {
  CommentsService,
  ProductionComments,
  RateProduction,
} from "../../domain/services/comments.service";
import {
  ProductionsSearch,
  SearchService,
} from "../../domain/services/search.service";
import { httpService } from "../http/services/http";
import { CommentModel, ProductionCommentsModel } from "../models";
import { Endpoints } from "./endpoints";

export interface ProductionCommentsResponse {
  data: {
    data: ProductionCommentsModel;
  };
}

export interface CommentByUserResponse {
  data: {
    data: CommentModel;
  };
}

export function commentsDataService(): CommentsService {
  const { get, post } = httpService();
  return {
    async getProductionComments({
      filter,
      productionId,
      success,
      error,
    }: ProductionComments) {
      try {
        const response: ProductionCommentsResponse = await get(
          `${Endpoints.productions}/${productionId}/comments`,
          { ...filter }
        );
        if (success) {
          success(response.data.data);
        }
      } catch (e) {
        if (error) {
          error(e);
        }
      }
    },

    async rateProductionByUser({
      qualification,
      productionId,
      body,
      success,
      error,
    }: RateProduction) {
      try {
        const response: CommentByUserResponse = await post(
          `${Endpoints.productions}/${productionId}/comments`,
          { qualification, body }
        );
        if (success) {
          success(response.data.data);
        }
      } catch (e) {
        if (error) {
          error(e);
        }
      }
    },
  };
}
