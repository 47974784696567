import tw from "twin.macro";
import styled from "styled-components";
import { Card, CategoryCarousel } from "../../components";

export interface StyledSectionProps {
  withoutPadding?: boolean;
}

export const StyledSearchPage = styled.div.attrs({
  className: "StyledSearchPage",
})`
  ${tw``}
`;

export const StyledContainer = styled.div.attrs({
  className: "StyledContainer",
})`
  .StyledSection:nth-child(odd) {
    ${tw`bg-secondary-300`}
  }

  .StyledSection:nth-child(even) {
    ${tw`bg-secondary-200`}
  }
`;

export const StyledSearchText = styled.div.attrs({
  className: "StyledSearchText",
})`
  ${tw`font-primaryBook text-base my-4 mx-4 lg:(text-2xl text-left mx-0)`}
  span {
    ${tw`font-bold`}
  }
`;

export const StyledSection = styled.section.attrs((props) => ({
  className: props.className,
}))<StyledSectionProps>`
  ${tw`relative w-full pt-8  flex flex-col text-center lg:(px-20) `}

  > h2, > article > h2 {
    ${tw`text-xl leading-9 font-primaryBold text-white sm:(text-[2rem] leading-[2.375rem])`}
    span {
      ${tw`text-primary-100`}
    }
  }

  > h3 {
    ${tw`text-sm font-primaryBold text-white sm:(text-2xl)`}
    span {
      ${tw`text-primary-100`}
    }
  }

  > p,
  > article > p {
    ${tw`max-w-[67.5rem] text-xl leading-6 text-white`}
  }
  ${({ withoutPadding }) => withoutPadding && tw`px-0 lg:px-5`}
`;

export const StyledCarousel = styled(CategoryCarousel).attrs({
  className: "StyledCarousel",
})`
 .
`;

export const StyledFavoriteCard = styled(Card).attrs({
  className: "StyledFavoriteCard",
})`
  ${tw`relative px-2.5`}
  grid-template-rows: 120px 30px 20px 100px 30px;

  .StyledImage {
    @media (min-width: 1024px) {
      height: 125px;
    }
  }
  h3 {
    ${tw`lg:text-base`}
  }
  .StyledBadge {
    ${tw`text-xs py-1 h-auto max-h-9 text-center`}
  }
`;
