import tw from "twin.macro";
import styled from "styled-components";

import { styled as styledMUI } from "@mui/material/styles";
import { Rating } from "@mui/material";

export const StyledRating = styledMUI(Rating)((props) => ({
  display: "flex",
  "& .MuiRating-icon.MuiRating-iconFilled": {
    color: "#C1101A",
  },
}));

export const StyledHelperText = styled.p.attrs({
  className: "StyledHelperText",
})`
  ${tw`
    text-xs
    text-primary-100
    `}
  word-break: break-word;
`;
