import { FC } from "react";
import { DefaultLayout } from "../../layouts";
import { usePreviewViewModel, useRentalsViewModel } from "../../viewModels";
import { PillEntity, RentalEntity } from "../../../domain/entities";

import {
  StyledPpvSection,
  StyledTable,
  StyledRentalsMobile,
  StyledRentalsMobileInnerContainer,
  StyledCardRental,
  StyledSelectTable,
  StyledModal,
} from "./rentals.styles";
import {
  InformationSection,
  RelatedAreas,
  SeasonsSection,
  UsersCommentsSection,
  VideoPreview,
} from "../preview/components";
import { StyledBlurWall, StyledButton } from "../preview/preview.styles";

import { ReactComponent as PlusCircleIcon } from "../../assets/images/plusCircleIcon.svg";
import { Paginator } from "../../components";
enum CurrencySymbol {
  eur = "€",
  usd = "$",
}
export const Rentals: FC = () => {
  const {
    rentals,
    rows,
    columns,
    formatOptions,
    formatOptionsMobile,
    handleFormatFilterChange,
    handlePageChange,
  } = useRentalsViewModel();
  const {
    showModalPreview,
    handleModalClose,

    isProductionRented,
    token,
    userSubscription,
    videoOptions,
    similarProductions,
    currentSeasonNumber,
    currentProduction,
    currentChapter,
    nextChapter,
    onGetChapter,
    navigateTo,
    handleSaveProduction,
    isProductionSaved,
    handlePlayerReady,
    videoTimeLeft,
    handleCallToActionRental,
    isPodcast,

    showModalRating,
    onSubmitRating,
    onCancelRating,
    onClickRatingButton,
    userCanComment,
    comments,
    handleChangeCommentsPage,
  } = usePreviewViewModel();
  return (
    <DefaultLayout>
      <StyledPpvSection>
        <h1>Valores individuales por alquiler</h1>
        <p>
          Mejora tu búsqueda filtrando el contenido según el formato de la
          producción.
        </p>

        <>
          <StyledTable rows={rows} columns={columns} />

          <StyledRentalsMobile
            // style={{ height: `${92 + rows.length * 176}px` }}
            style={{ height: "auto" }}
          >
            <StyledSelectTable
              id={"contactMatter"}
              name="contactMatter"
              placeholder="FORMATO"
              onChange={handleFormatFilterChange}
              options={formatOptionsMobile}
              selectColor="secondary"
              defaultValue={"reset"}
            />
            {!!rows.length ? (
              <StyledRentalsMobileInnerContainer>
                {rows?.map((currentRow: RentalEntity, index) => {
                  const { uuid, title, format, duration, price, units, terms } =
                    currentRow;
                  const finalPrice = price
                    ? (price?.unitAmount / 100.0).toString().replace(".", ",")
                    : "";
                  return (
                    <StyledCardRental key={`R-${index}`}>
                      <PlusCircleIcon
                        className="m-auto cursor-pointer"
                        onClick={() => {
                          uuid && navigateTo(`/preview?uuid=${uuid}`);
                        }}
                      />
                      <div className="relative pl-4 border-opacity-50 ">
                        <ul className="font-primaryBold flex flex-col gap-2">
                          <li>
                            <strong>Producción:</strong>
                            <span>{title}</span>
                          </li>
                          <li>
                            <strong>Área temática:</strong>
                            <span className="capitalize">
                              {terms.map(
                                (currentTerm: PillEntity, index) =>
                                  `${
                                    index !== 0 ? " | " : ""
                                  } ${currentTerm.title.toLowerCase()}`
                              )}
                            </span>
                          </li>
                          <li>
                            <strong>Formato:</strong>
                            <span>{format}</span>
                          </li>
                          <li>
                            <strong>Unidades:</strong>
                            <span>{units}</span>
                          </li>
                          <li>
                            <strong>Duración:</strong>

                            <span>{`${Math.round(duration / 60)} min.`}</span>
                          </li>
                          <li>
                            <strong>Precio:</strong>
                            <span>{`${
                              CurrencySymbol[price.currency]
                            } ${finalPrice}`}</span>
                          </li>
                        </ul>
                      </div>
                    </StyledCardRental>
                  );
                })}
              </StyledRentalsMobileInnerContainer>
            ) : (
              <StyledRentalsMobileInnerContainer>
                <p className="w-full text-white mx-auto text-center">
                  Sin datos
                </p>
              </StyledRentalsMobileInnerContainer>
            )}
          </StyledRentalsMobile>
          {rentals.pages > 1 && (
            <Paginator
              className="mx-auto mt-8"
              page={rentals.currentPage}
              totalPages={rentals.pages}
              onPageChange={handlePageChange}
            />
          )}
        </>
      </StyledPpvSection>
      <StyledModal
        showModal={showModalPreview}
        backgroundColor="black"
        title={currentProduction.title}
        onClose={handleModalClose}
      >
        <div className="w-preview py-6">
          <VideoPreview
            videoOptions={videoOptions}
            imgPreview={currentProduction.imgPreview}
            format={currentProduction.format}
            terms={currentProduction.terms}
            duration={currentProduction.duration}
            handlePlayerReady={handlePlayerReady}
            timeLeft={videoTimeLeft}
            chapterName={
              currentChapter.name
                ? currentChapter.name
                : currentProduction.title
            }
            productionName={currentProduction.title}
            currentSeasonNumber={currentSeasonNumber}
            nextChapterName={nextChapter.name ? nextChapter.name : ""}
            nextChapterImage={
              nextChapter.imgPreview ? nextChapter.imgPreview : ""
            }
            similarProductions={similarProductions}
            isPodcast={isPodcast}
          />

          <InformationSection
            token={token}
            title={currentProduction.title}
            description={currentProduction.description}
            onClick={handleSaveProduction}
            isProductionSaved={isProductionSaved}
            isProductionRented={isProductionRented}
            isSubscriptionActive={userSubscription?.stripeStatus === "active"}
            price={currentProduction.price}
            navigateToRental={handleCallToActionRental}
            onSubmitRating={onSubmitRating}
            onCancelRating={onCancelRating}
            onClickRatingButton={onClickRatingButton}
            showModalRating={showModalRating}
            userCanComment={userCanComment}
          />
          <div className="StyledSubscribedContent relative pb-36 ">
            {userSubscription?.stripeStatus !== "active" &&
              !isProductionRented && <StyledBlurWall />}
            {!!(
              currentProduction?.seasons?.length ||
              currentProduction?.chapters?.length
            ) && (
              <SeasonsSection
                onChapterClick={onGetChapter}
                seasons={currentProduction.seasons}
                chapters={currentProduction.chapters}
                currentChapter={currentChapter}
              />
            )}
            <RelatedAreas terms={currentProduction.terms} />
            {/* <RelatedContent
        categoryName="MÁS TÍTULOS SOBRE ESTA SOFT SKILL:"
        productions={dataSliders}
      /> */}
            {!!comments?.items?.length && (
              <UsersCommentsSection
                comments={comments}
                handleChangeCommentsPage={handleChangeCommentsPage}
              />
            )}
            {!token && (
              <StyledButton onClick={() => navigateTo("/signup")}>
                ¡Regístrate ahora!
              </StyledButton>
            )}
          </div>
        </div>
      </StyledModal>
    </DefaultLayout>
  );
};
