import { AxiosError } from "axios";
import { useLayoutEffect, useState } from "react";
import { LearningPathDetailDataService } from "../../../data/services/learningPathDetail.data.service";
import { useCaseLearningPathDetail } from "../../../domain/useCases/learningPathDetail";
import { useCustomEffect, useNavigation } from "../../hooks";
import { LearningPathDetailModel } from "../../../data/models/learningPathDetail.model";
import { useSelector } from "react-redux";
import { getSessionToken } from "../../../data/dto/selectors";
import { LearningPathDetailEntity } from "../../../domain/entities";

export function useLearningPathDetailViewModel() {
  const { getQueryParam, navigateTo } = useNavigation();
  const [sizeScreen, setSizeScreen] = useState({ x: 0, y: 0 });
  const { getListDetailLearningPaths, postLearningPath, postPayMedal } =
    useCaseLearningPathDetail(LearningPathDetailDataService());
  const [detailLearningPath, setDetailLearningPath] =
    useState<LearningPathDetailModel>({} as LearningPathDetailModel);
  const [detailBlueMedal, setDetailBlueMedal] =
    useState<LearningPathDetailEntity | null>();
  const [detailGreenMedal, setDetailGreenMedal] =
    useState<LearningPathDetailEntity | null>();
  const [detailGoldMedal, setDetailGoldMedal] =
    useState<LearningPathDetailEntity | null>();
  const [showLogInModal, setShowLoginModal] = useState(false);
  const [showStartPathModal, setShowStartPathModal] = useState(false);
  const [showSuccessModal, setSuccessModal] = useState(false);
  const [showNotSubscribedModal, setShowNotSubscribedModal] = useState(false);
  const [transactionFinalStatus, setTransactionFinalStatus] = useState<
    "none" | "success" | "error"
  >("none");
  const [showTransactionModal, setShowTransactionModal] = useState(false);
  const token = useSelector(getSessionToken);

  function successGetListDetailLearningPath(
    learningPath: LearningPathDetailModel
  ) {
    setDetailLearningPath(learningPath);
    learningPath.medals.map((currentMedal) => {
      currentMedal.id === "blue_4eC39Hq" && setDetailBlueMedal(currentMedal);
      currentMedal.id === "green_4eC39Hq" && setDetailGreenMedal(currentMedal);
      currentMedal.id === "gold_4eC39Hq" && setDetailGoldMedal(currentMedal);
    });
  }

  function errorGetListDetailLearningPath(e: AxiosError) {
    console.log("errorGetListDetailLearningPath", e);
  }

  function successPostLearningPath() {
    setSuccessModal(true);
  }

  function errorPostLearningPath(e: AxiosError) {
    console.log("errorGetListDetailLearningPath", e);
    if (e?.response?.status === 401) {
      setShowLoginModal(true);
    } else if (e?.response?.status === 403) {
      setShowNotSubscribedModal(true);
    }
  }

  function errorPayMedal(e: AxiosError) {
    console.log("errorPayMedal", e);
  }

  function handleNotSubscribedModal(value: boolean) {
    setShowNotSubscribedModal(value);
  }

  function handleTransactionModal() {
    setShowTransactionModal(false);
  }

  function handleStartPath() {
    if (token) {
      setShowStartPathModal(true);
    } else {
      setShowLoginModal(true);
    }
  }

  const learningPathId = getQueryParam("uuid");

  const handleStartLearningPath = (uuid: string) => {
    postLearningPath({
      success: successPostLearningPath,
      error: errorPostLearningPath,
      learningPathUuid: uuid,
    });
    setShowStartPathModal(false);
  };

  const handlePayMedal = (learningPathId: string, medalId: string) => {
    postPayMedal({
      success: (url) => {
        window.location.replace(url);
      },
      error: errorPayMedal,
      learningPathUuid: learningPathId,
      medalId: medalId,
    });
  };

  function handleRedirectToFirstProduction() {
    if (detailLearningPath.medals[0].path?.length) {
      navigateTo(`/preview?uuid=${detailLearningPath.medals[0].path[0].id}`);
    }
    setSuccessModal(false);
  }

  const handleTransactionStatus = (status: string) => {
    switch (status) {
      case "success":
        setTransactionFinalStatus(status);
        setShowTransactionModal(true);
        break;
      case "error":
        setTransactionFinalStatus(status);
        setShowTransactionModal(true);
        break;
      default:
        setTransactionFinalStatus("none");
        setShowTransactionModal(false);
        break;
    }
  };

  useCustomEffect(() => {
    getListDetailLearningPaths({
      success: successGetListDetailLearningPath,
      error: errorGetListDetailLearningPath,
      learningPathUuid: learningPathId,
    });
    handleTransactionStatus(getQueryParam("status"));
  }, [learningPathId]);

  useLayoutEffect(() => {
    const handleResize = () => {
      setSizeScreen({ x: window.innerWidth, y: window.innerHeight });
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return {
    sizeScreen,
    detailLearningPath,
    detailBlueMedal,
    detailGreenMedal,
    detailGoldMedal,
    handleStartLearningPath,
    showLogInModal,
    setShowLoginModal,
    showStartPathModal,
    setShowStartPathModal,
    showSuccessModal,
    navigateTo,
    handlePayMedal,
    handleRedirectToFirstProduction,
    handleNotSubscribedModal,
    showNotSubscribedModal,
    handleStartPath,
    showTransactionModal,
    handleTransactionModal,
    transactionFinalStatus,
  };
}
