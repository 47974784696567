import { FC } from "react";
import { Button } from "../../../../components";
import { StyledDescription, StyledSearchNotFound } from "./searchNotFound.styles";
import  notFoundImage from "../../../../assets/images/searchNotFoundImage.png";

export interface SearchNotFoundProps {
    navigateTo: (url: string, state?: { [key: string]: any; } | undefined) => void;
    searchTerm: string;
}

export const SearchNotFound: FC<SearchNotFoundProps> = ({navigateTo, searchTerm}) => {
    return (
        <StyledSearchNotFound>
            <img src={notFoundImage}/>
            <StyledDescription>
                <p>¡Lo sentimos! No encontramos títulos relacionados a “<span>{searchTerm}</span>”.</p>
                <p> Prueba repetir tu búsqueda o revisa nuestro catálogo completo.</p>
                <Button onClick={() => navigateTo("/catalog")}>Ver todo el catálogo</Button>
            </StyledDescription>
        </StyledSearchNotFound>
    )
}