import { FC } from "react";
import { StyledPodcastContainer } from "./PodcastInProgress.styles";

import { ChapterInProgressEntity } from "../../../../../domain/entities";
import { Carousel, PodcastInProgressCard } from "../../../../components";
import { MyStorytrainingInProgressModel } from "../../../../../data/models";

export interface PodcastInProgressSectionProps {
  podcastList: Array<ChapterInProgressEntity>;
}

export const PodcastInProgress: FC<PodcastInProgressSectionProps> = ({
  podcastList,
}) => {
  return (
    <>
      <StyledPodcastContainer>
        <h3>PODCAST</h3>

        <div className="w-full flex">
          <Carousel
            slidesToShow={1}
            slidesToScroll={1}
            infinite={true}
            dots={false}
            arrows={true}
            centerMode={false}
            responsive={[
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  centerMode: true,
                  dots: true,
                  arrows: false,
                },
              },
            ]}
          >
            {podcastList.map((currentPodcast, index) => (
              <PodcastInProgressCard
                className={"h-full px-0 mr-auto"}
                key={`P-${index}`}
                podcastData={currentPodcast}
              />
            ))}
          </Carousel>
        </div>
      </StyledPodcastContainer>
    </>
  );
};
