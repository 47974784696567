import { FC } from "react";
import {
  StyledSubscriptionCard,
  StyledImage,
  StyledHorizontalImage,
  StyledDescriptionItem,
  StyledParagraphTip,
  StyledDescriptionList,
  StyledInformation,
  StyledCardContainer,
} from "./SubscriptionDetailCard.styles";
import { SubscriptionEntity } from "../../../domain/entities";
import { useNavigation } from "../../hooks";
import { Button } from "../Button";

export interface SubscriptionDetailCardProps {
  className?: string;
  subscriptionData: SubscriptionEntity;
  flag: boolean;
  callToActionCard?: (data?: any) => void;
}
enum CurrencySymbol {
  eur = "€",
  usd = "$",
}

export const SubscriptionDetailCard: FC<SubscriptionDetailCardProps> = ({
  className,
  subscriptionData,
  flag,
  callToActionCard,
}) => {
  const { name, description, images, tyc, price, discount } = subscriptionData;
  const { navigateTo } = useNavigation();
  return (
    <StyledSubscriptionCard
      className={`StyledSubscriptionCard ${className}`}
      flag={flag}
    >
      <StyledCardContainer flag={flag}>
        {flag && (
          <div className="h-36 mx-auto max-h-36 flex justify-end">
            <StyledImage src={images[0]} />
          </div>
        )}

        <div className="flex flex-col gap-y-6">
          <h4 className="flex flex-col lg:flex-row justify-center items-center text-3xl text-white font-primaryBold gap-x-2">
            {name}
            {/* Suscripción <span className="text-primary-100">{subscriptionType}</span> */}
          </h4>
          <hr className=" w-full opacity-50 border-t-2 lg:hidden border-white " />

          <div className="lg:flex flex-col-reverse gap-y-6">
            <StyledDescriptionList>
              <StyledDescriptionItem>
                <p>{description}</p>
              </StyledDescriptionItem>
            </StyledDescriptionList>

            <StyledInformation>
              {!flag && (
                <div className="mx-auto w-full max-w-full h-auto flex justify-center">
                  <StyledHorizontalImage src={images[0]} />
                </div>
              )}
               {discount && <p className="text-white font-primarySemibold text-xs">Antes: <span className="line-through">{price && price?.unitAmount / 100.0}</span> / Ahora:</p>}
              {!!price?.unitAmount ? (
                <div className="flex flex-col">
                  <p className="text-white font-primaryBold text-xl sm:hidden">
                    {name}
                  </p>
                  <span className="font-primaryBold text-primary-100 text-3xl text-center">
                    {CurrencySymbol[price.currency]}
                    {!discount ? price.unitAmount / 100.0 : discount?.priceAfterDiscount / 100.0}
                  </span>
                </div>
              ) : (
                <StyledParagraphTip>*Valor variable</StyledParagraphTip>
              )}
            </StyledInformation>
          </div>
          <StyledParagraphTip
            className="cursor-pointer"
            onClick={tyc ? () => window.location.replace(tyc) : () => {}}
          >
            *Aplican términos y condiciones
          </StyledParagraphTip>
        </div>
      </StyledCardContainer>

      {!flag && (
        <Button
          onClick={
            !!callToActionCard ? () => callToActionCard(price?.id) : () => {}
          }
        >
          Suscríbete
        </Button>
      )}
    </StyledSubscriptionCard>
  );
};
