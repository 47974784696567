import { FC, useState } from "react";
import { StyledCodeValidationSection } from "./CodeValidationSection.styles";
import {
  Button,
  Carousel,
  Modal,
  TextFieldControlled,
} from "../../../../components";
import { StatusModal } from "../../../subscriptionDetail/components/StatusModal";
import { useNavigation } from "../../../../hooks";
import { ReactComponent as RocketLogo } from "../../../../assets/images/rocketLogo.svg";

import {
  Control,
  FieldErrorsImpl,
  FieldValues,
  UseFormHandleSubmit,
} from "react-hook-form";

export interface CodeValidationSectionProps {
  className?: string;
  showModalStatusCode: boolean;
  finalStatusCode: "success" | "error" | "none";
  handleCloseStatusModal: () => void;
  handleCloseStatusModalOnSuccess: (url: string) => void;
  onSubmitValidationCode: (data: FieldValues) => void;
  handleValidationCodeForm: () => {
    handleSubmit: UseFormHandleSubmit<FieldValues>;
    control: Control<FieldValues, any>;
    errors: Partial<
      FieldErrorsImpl<{
        [x: string]: any;
      }>
    >;
  };

  showModalProductionRedemption: boolean;
  productionId?: string;
  productionName?: string;
  codeError?: number;
}

export const CodeValidationSection: FC<CodeValidationSectionProps> = ({
  className,
  showModalStatusCode,
  finalStatusCode,
  handleCloseStatusModal,
  handleCloseStatusModalOnSuccess,
  onSubmitValidationCode,
  handleValidationCodeForm,
  showModalProductionRedemption,
  productionId,
  productionName,
  codeError,
}) => {
  const { handleSubmit, control, errors } = handleValidationCodeForm();
  return (
    <StyledCodeValidationSection id="code-validation" className={className}>
      <h1>Valida tu código</h1>
      <p>
        Ingresa los dígitos de tu código para disfrutar de todo nuestro
        contenido
      </p>
      <div className="w-full flex flex-col justify-center mt-16 max-w-inputForm gap-8">
        <TextFieldControlled
          id="codeValidation"
          name="codeValidation"
          label="Tu código:"
          placeholder="Digita aquí tu código"
          control={control}
          error={!!errors?.codeValidation}
          helperText={errors?.codeValidation?.message}
          required
        />
        <Button
          className="mx-auto"
          onClick={handleSubmit(onSubmitValidationCode)}
        >
          Validar mi código
        </Button>
      </div>
      <StatusModal
        showModal={showModalStatusCode}
        status={finalStatusCode}
        handleSuccessSubscription={handleCloseStatusModalOnSuccess}
        handleCloseModal={handleCloseStatusModal}
        codeError={codeError}
      />
      <Modal showModal={showModalProductionRedemption} backgroundColor="white">
        <div className="px-4 lg:px-8 text-center flex flex-col items-center justify-center gap-y-4 max-w-registerModal">
          <h3 className="text-2xl font-primaryBold text-primary-100">
            ¡Te damos la bienvenida a storytraining!
          </h3>
          <RocketLogo />
          <p className="font-primaryBook text-sm lg:text-xl">
            Ahora puedes disfrutar de nuestra producción {productionName}; todo
            tu proceso será registrado en tu perfil ¡Te invitamos a explorar
            nuestra plataforma!
          </p>
          <div className="flex flex-row gap-4 lg:gap-6 w-full justify-center">
            <Button
              onClick={() =>
                handleCloseStatusModalOnSuccess(`/preview?uuid=${productionId}`)
              }
            >
              Ir a la producción
            </Button>
            <Button onClick={() => handleCloseStatusModalOnSuccess("/profile")}>
              Ir a Mi Perfil
            </Button>
          </div>
        </div>
      </Modal>
    </StyledCodeValidationSection>
  );
};

export default CodeValidationSection;
