import {
  StyledParagraph,
  StyleButton,
  StyledList,
} from "./ProfileDropDown.styles";
import avatar from "../../assets/images/Avatar.png";
import { FC } from "react";
import { useProfileDropDown } from "./useProfileDropDown";
import { useLogoutViewModel } from "../../viewModels/logoutViewModel";
import { Avatar } from "@mui/material";

export const ProfileDropDown: FC = () => {
  const { user, userAvatar, onToggleDropDown, selectValue, navigateTo } =
    useProfileDropDown();
  const { onSubmit } = useLogoutViewModel();

  return (
    <div className="flex items-center ">
      <StyledParagraph color="secondary">{`¡Hola, ${
        user?.name.split(" ")[0]
      }!`}</StyledParagraph>
      <div className="relative flex items-center  ">
        <StyleButton onClick={onToggleDropDown} color="secondary">
          <Avatar
            sx={{ width: { xs: 64, md: 80 }, height: { xs: 64, md: 80 } }}
            src={userAvatar?.fileUrl ? userAvatar?.fileUrl : avatar}
            alt={`Imagen usuario`}
            title={`Imagen usuario`}
          />
        </StyleButton>
        {selectValue && (
          <StyledList>
            <li onClick={() => navigateTo("/profile")}>Mi Perfil</li>
            <li onClick={() => navigateTo("/dashboard")}>Mi Story Training</li>
            {/* <li onClick={() => navigateTo("/catalog")}>Catálogo</li> */}
            <li onClick={onSubmit}>Cerrar Sesión</li>
          </StyledList>
        )}
      </div>
    </div>
  );
};
