import tw from "twin.macro";
import { styled as styledMui } from "@mui/material/styles";
import styled from "styled-components";
import { DataGrid, DataGridProps } from "@mui/x-data-grid";
import { Pagination, PaginationProps } from "@mui/material";

export const StyledTableContainer = styled.div.attrs((props) => ({
  className: props.className,
}))`
  ${tw`relative`}
`;

export const StyledDataGrid = styledMui(DataGrid)<DataGridProps>(
  ({ theme }) => ({
    backgroundColor: "#1D1D1D",
    borderRadius: "24px",
    border: "none",
    color: "#FFFFFF",
    fontFamily: "Carnero-Regular, sans-serif",
    "& .MuiDataGrid-columnHeaders": {
      border: "none",
      "& .MuiDataGrid-columnHeader": {
        border: "none",
        outline: "none !important",
        padding: "0 4px",
        "& .MuiDataGrid-columnSeparator": {
          "& .MuiSvgIcon-root": {
            display: "none",
          },
        },
        "& .MuiDataGrid-columnHeaderTitleContainer": {
          margin: "8px 0",
          textAlign: "center",
          borderRadius: "12px",
          overflow: "hidden",

          "& .MuiDataGrid-columnHeaderTitleContainerContent": {
            width: "100%",
            "& .MuiDataGrid-columnHeaderTitle": {
              padding: "0 8px",
              width: "100%",
              background: "#C1101A",
              borderRadius: "12px",
              fontFamily: "Carnero-Bold, sans-serif",
              [theme.breakpoints.down(740)]: {
                fontSize: "12px",
              },
              [theme.breakpoints.up(1320)]: {
                fontSize: "18px",
              },
            },
          },
          "& .MuiDataGrid-iconButtonContainer": {
            display: "none",
          },
        },
      },
    },
    "& .MuiDataGrid-virtualScrollerRenderZone": {
      boxSizing: "border-box",
      gap: "1px",
      "& .MuiDataGrid-row": {
        position: "relative",
        "&::after": {
          position: "absolute",
          bottom: "-10px",
          borderBottom: "2px solid #434343",
          content: '""',
          height: "0px",
          width: "100%",
        },
        borderRadius: "12px",
        margin: "8px 0",
        backgroundColor: "#1D1D1D",
        boxSizing: "border-box",
        "& .MuiDataGrid-cell": {
          outline: "none",
          borderRadius: "12px",
          border: "none",
          backgroundClip: "content-box",
          fontFamily: "Carnero-Book, sans-serif",
          fontSize: "12px",
          [theme.breakpoints.down(740)]: {
            fontSize: "10px",
          },
          [theme.breakpoints.up(1400)]: {
            fontSize: "16px",
          },

          "& .MuiDataGrid-cellContent": {
            width: "100%",
            textAlign: "center",
          },
        },
        "& .MuiDataGrid-cell--withRenderer": {
          width: "100%",
          border: "none",
          backgroundClip: "content-box",
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
        },
      },

      "& .MuiDataGrid-row:nth-of-type(odd)": {
        backgroundColor: "#1D1D1D",

        "& .MuiDataGrid-cell": {
          backgroundColor: "#1D1D1D",
        },
      },
      "& .MuiDataGrid-row:nth-of-type(even)": {
        backgroundColor: "#434343",

        "& .MuiDataGrid-cell": {
          backgroundColor: "#434343",
        },
      },
    },
    "& .MuiDataGrid-footerContainer": {
      display: "none",
    },
    "& .MuiDataGrid-overlay": {
      content: '"Sin datos"',
      background: "transparent",
    },
  })
);

export const StyledPagination = styledMui(Pagination)<PaginationProps>(
  ({ theme }) => ({})
);
