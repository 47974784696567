import { FC, ReactNode } from "react";
import { StyledWarpperSliderContainer } from "./Carousel.styles";

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

interface Settings {
  dots?: boolean;
  infinite?: boolean;
  speed?: number;
  slidesToShow: number;
  slidesToScroll: number;
  centerMode?: boolean;
  centerPadding?: string;
  arrows?: boolean;
  paddingLeft?: boolean;
  paddingRight?: boolean;
  isCatalog?: boolean;
}

interface BreakPoints {
  breakpoint: number;
  settings: Settings;
}

interface CarouselProps extends Settings {
  children: ReactNode;
  responsive?: Array<BreakPoints>;
}

export const Carousel: FC<CarouselProps> = ({
  isCatalog = false,
  dots = true,
  children,
  infinite = false,
  speed = 500,
  slidesToShow,
  slidesToScroll,
  responsive,
  centerMode = false,
  centerPadding = "0px",
  arrows = true,
  paddingLeft,
  paddingRight,
}: CarouselProps) => {
  const settings = {
    dots: dots,
    infinite: infinite,
    speed: speed,
    slidesToShow: slidesToShow,
    slidesToScroll: slidesToScroll,
    centerMode: centerMode,
    centerPadding: centerPadding,
    responsive: responsive,
    arrows: arrows,
  };

  return (
    <StyledWarpperSliderContainer>
      <Slider {...settings}>{children}</Slider>
    </StyledWarpperSliderContainer>
  );
};
