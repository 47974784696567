import { learningPathDetailInput, LearningPathDetailService, payMedalInput, startLearningPathInput } from "../../services/learningPathDetail.service";
import { handleResponse } from "../../shared";

export function useCaseLearningPathDetail(service: LearningPathDetailService){
    return {
        getListDetailLearningPaths({success, error, learningPathUuid}: learningPathDetailInput){
            return service.getListDetailLearningPaths({success, error, learningPathUuid});
        },
        postLearningPath({success, error, learningPathUuid}: startLearningPathInput){
            return service.postLearningPath({success, error, learningPathUuid});
        },
        postPayMedal({success,error, learningPathUuid, medalId}: payMedalInput){
            return service.postPayMedal({success,error, learningPathUuid, medalId});
        }
    }
}