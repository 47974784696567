import { Avatar } from "@mui/material";
import { FC } from "react";
import { AvatarEntity } from "../../../domain/entities";
import { Rating } from "../Rating";
import { StyledCommentContainer } from "./ProductionComment.styles";
import avatar from "../../assets/images/Avatar.png";

interface ProductionCommentProps {
  className?: string;
  ratingValue: number;
  comment: string;
  userName: string;
  userAvatar?: AvatarEntity;
}

export const ProductionComment: FC<ProductionCommentProps> = ({
  className,
  ratingValue,
  comment,
  userName,
  userAvatar,
}) => {
  return (
    <StyledCommentContainer className={className}>
      <div className="flex flex-col gap-4 items-center text-center max-w-selectContactForm">
        <Rating
          id={"rating"}
          name={"rating"}
          value={ratingValue}
          readOnly={true}
        />
        <p className="font-primarySemiboldItalic text-base text-white">
          {comment}
        </p>
      </div>
      <div className="flex flex-col items-center text-center">
        <Avatar
          sx={{ width: 80, height: 80 }}
          src={userAvatar?.fileUrl ? userAvatar?.fileUrl : avatar}
          alt={`Imagen usuario ${userName}`}
          title={`Imagen usuario ${userName}`}
        />
        <p className="font-primaryBook text-base text-white">{userName}</p>
      </div>
    </StyledCommentContainer>
  );
};
