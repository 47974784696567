import tw from "twin.macro";
import styled from "styled-components";

export const StyledPodcastContainer = styled.div.attrs({
  className: "StyledPodcastContainer",
})`
  ${tw`relative   w-full max-w-[320px] lg:max-w-full mx-auto  flex flex-col gap-4 justify-center pt-4 px-6 items-center border-t-2 border-white border-opacity-20 mt-8 lg:(items-start px-0 mt-0 border-t-0)`}
  > h3 {
    ${tw`text-xl font-primaryBold text-primary-100 `}
  }
`;
