import { FC } from "react";
import {
  StyledInProgressInnerContainer,
  StyledInProgressProductions,
} from "./ProductionsInProgress.styles";

import { Carousel, InProgressCard } from "../../../../components";
import { MyStorytrainingInProgressModel } from "../../../../../data/models";

interface ProductionsInProgressProps {
  productionsInProgress: [] | MyStorytrainingInProgressModel[];
}

export const ProductionsInProgress: FC<ProductionsInProgressProps> = ({
  productionsInProgress,
}) => {
  return (
    <StyledInProgressProductions>
      {!!productionsInProgress.length &&
        productionsInProgress.map((currentCategory, indexCategory) => (
          <StyledInProgressInnerContainer key={`c-${indexCategory}`}>
            <h4>{currentCategory.title}</h4>
            <Carousel
              slidesToShow={currentCategory?.chapters.length > 1 ? 2 : 1}
              slidesToScroll={1}
              infinite={true}
              dots={false}
              arrows={true}
              responsive={[
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow:
                      currentCategory?.chapters.length > 2
                        ? 3
                        : currentCategory?.chapters.length,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    arrows: false,
                    centerMode: true,
                    centerPadding: "68px",
                  },
                },
                {
                  breakpoint: 768,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    arrows: false,
                    centerMode: true,
                    centerPadding: "68px",
                  },
                },
              ]}
            >
              {currentCategory?.chapters?.map((production, index) => {
                return (
                  <InProgressCard
                    key={`p-${index}`}
                    className={`${
                      currentCategory?.chapters.length < 3
                        ? "max-w-inProgressCardDashboard mx-auto"
                        : ""
                    } px-2 lg:max-w-inProgressCardDashboardLg lg:mx-0`}
                    productionData={production}
                  />
                );
              })}
            </Carousel>
          </StyledInProgressInnerContainer>
        ))}
    </StyledInProgressProductions>
  );
};
