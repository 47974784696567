import { FC } from "react";

interface IconProps {
  className?: string;
  onClick?: () => void;
  color?: string;
  width?: string;
  height?: string;
}

export const StoryTrainingIcon: FC<IconProps> = ({
  className,
  color = "#8C8C8C",
  width = "84px",
  height = "84px",
}) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 84 84"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.93829 83.9872C2.70995 83.9872 1.57025 83.269 1.03839 82.1148C0.443216 80.8324 0.747136 79.3063 1.78553 78.3572C4.87538 75.5102 6.90151 69.2263 7.40804 61.0186C2.55799 54.5038 0 46.8861 0 38.8965C0 17.454 18.7924 0 41.8776 0C56.1745 0 71.4718 8.52824 79.0571 20.7499C84.9329 30.2015 85.5914 40.833 80.9186 50.6821C77.7402 57.3893 72.029 62.5447 63.4686 66.4562C55.9719 69.8803 47.6774 70.0983 40.7126 67.0589C34.3176 64.2632 29.6828 59.0821 27.2895 52.0672C25.0227 45.4241 25.4786 36.1649 25.8838 28.0085C26.0231 25.2128 26.1498 22.571 26.1498 20.3395C26.1498 19.2238 26.7196 18.185 27.6567 17.5951C28.5938 17.0052 29.7588 16.9411 30.7592 17.4284L65.6087 34.6772C66.5964 35.1646 67.2676 36.1392 67.3815 37.255C67.4955 38.3707 67.0396 39.4608 66.1659 40.1405L45.7653 56.1838C44.3723 57.2867 42.3588 57.0302 41.2824 55.6195C40.1934 54.2089 40.4467 52.1698 41.8396 51.0797L58.188 38.2168L32.4054 25.4565C32.3675 26.3927 32.3168 27.3545 32.2661 28.3292C31.8989 35.9084 31.4684 44.5008 33.3299 49.964C35.1154 55.2092 38.5345 59.0565 43.2453 61.1084C48.5639 63.4296 54.9715 63.2244 60.8346 60.5569C67.9641 57.2995 72.6495 53.1572 75.1442 47.8864C79.8676 37.9218 76.9297 29.509 73.6372 24.2125C67.2549 13.9402 53.8951 6.48916 41.865 6.48916C22.3001 6.48916 6.38232 21.0321 6.38232 38.9093C6.38232 45.8089 8.72503 52.3878 13.1445 57.9536C13.6384 58.5692 13.879 59.3386 13.8537 60.1209C13.6511 64.7249 12.9799 70.2907 11.1564 75.1511C15.196 73.3942 21.8316 70.0983 26.2764 65.1866C27.4667 63.8657 29.4929 63.7759 30.7972 64.9942C32.1015 66.1997 32.1902 68.2516 30.9872 69.5725C20.0967 81.589 4.9387 83.8974 4.36885 83.9743C4.22955 83.9872 4.07759 84 3.93829 84V83.9872Z"
        fill={color}
      />
    </svg>
  );
};
