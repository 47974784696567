import tw from "twin.macro";
import styled from "styled-components";
import { ArrowsPaginator, Paginator } from "../../../../components";

export const StyledRelatedAreas = styled.div.attrs({
  className: "StyledRelatedAreas",
})`
  ${tw`flex flex-col items-center justify-center gap-5 mt-16  max-w-preview mx-auto w-full lg:(px-0)`}
`;

export const StyledArrowsPaginator = styled(ArrowsPaginator).attrs({
  className: "StyledArrowsPaginator",
})`
  ${tw`lg:(px-0)`}
  .MuiPagination-root {
    ${tw`block lg:(hidden)`}
  }
`;

export const StyledPaginator = styled(Paginator).attrs({
  className: "StyledPaginator",
})`
  .MuiPagination-root {
    ${tw`hidden lg:(block)`}
  }
`;
