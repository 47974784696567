import tw from "twin.macro";
import styled from "styled-components";

export interface StyledHistorySectionProps {
    className?: string;
}

export const StyledHistorySection = styled.section.attrs((props) => ({className: props.className}))`
${tw`
    bg-secondary-300
    lg:bg-secondary-100
`}

h1 {
    ${tw`font-primaryBold text-[32px] lg:text-6xl text-white text-center mt-16`}
}
`

export const StyledContainerCarouselHistory = styled.section.attrs({
    className: "StyledContainerCarouselHistory",
  })`
    ${tw`max-w-theme mx-auto w-full mb-20 `}
  `;