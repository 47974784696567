import tw from "twin.macro";
import styled from "styled-components";

export const StyledLearningPathBanner = styled.div.attrs({
    className: "StyledLearningPathBanner",
  })`
  ${tw`flex flex-col text-white items-center w-full pt-[78px] pb-16 px-5 lg:(flex flex-row-reverse justify-center)`}
  h2 {
    ${tw`font-primaryBold text-xl my-5 lg:(text-[32px])`}
  }
  p {
    ${tw`font-primarySemibold text-sm my-5 lg:(text-2xl)`}
  }
  img {
    ${tw`w-[200px] h-[52px] lg:(w-[332px] h-20)`}
  }
  .AnahuacLogo {
    ${tw`w-[200px] h-[52px]  lg:(w-[332px] h-20)`}
  }
  `;