import { FC } from "react";

interface IconProps {
  className?: string;
  color?: string;
  width?: string;
  height?: string;
  onClick?: () => void;
}

export const EyeCloseIcon: FC<IconProps> = ({
  className,
  color = "#636363",
  width = "22px",
  height = "22px",
  onClick,
}) => {
  return (
    <svg
      className={className}
      onClick={onClick}
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.7188 17.2563C12.1521 17.3634 11.5767 17.4171 11 17.4167C6.89517 17.4167 3.421 14.7189 2.25225 11C2.56691 9.99909 3.05158 9.05984 3.685 8.22342M9.05483 9.05575C9.5706 8.53998 10.2701 8.25023 10.9995 8.25023C11.729 8.25023 12.4285 8.53998 12.9443 9.05575C13.46 9.57152 13.7498 10.2711 13.7498 11.0005C13.7498 11.7299 13.46 12.4294 12.9443 12.9452M9.05483 9.05575L12.9443 12.9452M9.05483 9.05575L12.9433 12.9433M12.9443 12.9452L15.961 15.961M9.05667 9.05667L6.04083 6.04083M6.04083 6.04083L2.75 2.75M6.04083 6.04083C7.51883 5.0871 9.24101 4.58095 11 4.58333C15.1048 4.58333 18.579 7.28108 19.7477 11C19.1025 13.0446 17.7626 14.7992 15.9601 15.9601M6.04083 6.04083L15.9601 15.9601M15.9601 15.9601L19.25 19.25"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
