import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useRef, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import {
  getAvatar,
  getSessionToken,
  getSubscriptionStatus,
  getUser,
} from "../../../data/dto/selectors";
import { NotificationsModel } from "../../../data/models";
import {
  authenticationDataService,
  subscriptionsDataService,
} from "../../../data/services";
import { countryDataService } from "../../../data/services/country.data.service";
import {
  CountryEntity,
  NotificationEntity,
  OptionsSelectEntity,
} from "../../../domain/entities";
import { useCaseProfile, useCaseSubscriptions } from "../../../domain/useCases";
import { useCaseCountries } from "../../../domain/useCases/countries";
import {
  useCustomEffect,
  useDateFormatter,
  useNavigation,
  useGetLeftDaysHours,
} from "../../hooks";
import {
  editProfileAboutMeSchema,
  editProfilePasswordSchema,
} from "../../validators";

interface CountriesData {
  countries: Array<CountryEntity>;
  countriesOptions: Array<OptionsSelectEntity>;
}

export function useProfileViewModel() {
  const breadcrumbItemsElements = [
    {
      name: "Inicio",
      url: "/",
    },
    {
      name: "Inicia Sesión",
    },
  ];
  const { navigateTo } = useNavigation();

  const userData = useSelector(getUser);
  const userAvatar = useSelector(getAvatar);
  const userSubscription = useSelector(getSubscriptionStatus);
  const token = useSelector(getSessionToken);

  const { getProfile, updateProfile, getNotifications, deleteNotification } =
    useCaseProfile(authenticationDataService());
  const { getBilling } = useCaseSubscriptions(subscriptionsDataService());

  const [editProfile, setEditProfile] = useState(false);
  const [isError, setIsError] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [countriesData, setCountriesData] = useState<CountriesData>();
  const { run: getCountries } = useCaseCountries(countryDataService());
  const [billingPortalUrl, setBillingPortalUrl] = useState<string>("");
  const [currentSubscription, setCurrentSubscription] = useState({
    lastWord: "",
    rest: "",
  });
  const [notifications, setNotifications] = useState<NotificationsModel>({
    pages: 0,
    currentPage: 0,
    items: [],
  });

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors, isDirty },
  } = useForm({ mode: "onChange" });

  const {
    handleSubmit: handleSubmitAboutMe,
    control: controlAboutMe,
    setValue: setValueAboutMe,
    formState: { errors: errorsAboutMe },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(editProfileAboutMeSchema),
  });

  const {
    handleSubmit: handleSubmitPassword,
    control: controlPassword,
    setValue: setValuePassword,
    formState: { errors: errorsPassword },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(editProfilePasswordSchema),
  });

  const handleEditProfile = (data?: FieldValues) => {
    if (editProfile) {
      if (data?.avatar?.file?.type) {
        const dataProfile = { photo: data.avatar.file };
        updateProfile(dataProfile);
      }
      setEditProfile(false);
    } else {
      setEditProfile(true);
    }
  };

  const handleEditProfileAboutMe = (data?: FieldValues) => {
    if (editProfile) {
      if (data) {
        const dataProfile = {
          name: data.name,
          lastName: data.lastName,
          countryId: data.country,
        };
        updateProfile(dataProfile);
      }
      setShowConfirmationModal(true);
    } else {
      setEditProfile(true);
    }
  };

  const onPasswordError = (error: any) => {
    if (error?.response?.status == 422) {
      console.log({ error });
      toast.error(error.response.data.message);
      setIsError(true);
    }
  };

  const handleEditProfilePassword = (data?: FieldValues) => {
    if (editProfile) {
      if (data) {
        const dataProfile = {
          oldPassword: data?.password,
          password: data?.newPassword,
          passwordConfirmation: data?.confirmPassword,
          error: onPasswordError,
        };
        updateProfile(dataProfile);
        setValuePassword("password", "");
        setValuePassword("newPassword", "");
        setValuePassword("confirmPassword", "");
      }
      if (isError == true) {
        console.log({ errorTwo: isError });
        setShowConfirmationModal(true);
      }
    } else {
      setEditProfile(true);
    }
  };

  const handleCloseEditSection = () => {
    setShowConfirmationModal(false);
    setEditProfile(false);
  };

  const handleDeleteNotification = (notification: NotificationEntity) => {
    deleteNotification({ id: notification.id });
    let newNotifications = notifications.items.filter(
      (element) => element.id != notification.id
    );
    setNotifications({ ...notifications, items: [...newNotifications] });
    /*     if(newNotifications.length === 0 && notifications.pages > 1){
      handleGetNotifications(1)
    } */
  };

  const initialRender = useRef(true);
  useEffect(() => {
    if (notifications.items.length === 0 && notifications.pages > 0) {
      setTimeout(
        () =>
          getNotifications({
            page: 1,
            success: (notificationsData) => setNotifications(notificationsData),
          }),
        200
      );
    }
  }, [notifications.items.length]);

  const handleGetNotifications = (pageNumber: number) => {
    getNotifications({ page: pageNumber, success: getNotificationsSuccess });
  };

  const handleBillingCallToAction = () => {
    if (userSubscription?.stripeStatus === "active") {
      billingPortalUrl && window.location.replace(billingPortalUrl);
    } else {
      navigateTo("/subscriptions");
    }
  };

  const getNotificationsSuccess = (notificationsData: NotificationsModel) => {
    setNotifications({
      ...notificationsData,
      items: [...notifications.items, ...notificationsData.items],
    });
  };

  const handleSubscriptionName = (name: string) => {
    const subscriptionLastWord = name.split(" ").slice(-1)[0];
    const subscriptionRestName = name.replace(subscriptionLastWord, "");
    setCurrentSubscription({
      lastWord: subscriptionLastWord,
      rest: subscriptionRestName,
    });
  };

  const handleSubscriptionTrialStatus = (date: string) => {
    setCurrentSubscription({
      lastWord: useDateFormatter(date),
      rest: "Código disponible hasta: ",
    });
  };

  // get countries
  const onGetCountriesSuccess = (countries: Array<CountryEntity>) => {
    const countriesForSelectComponent = countries.map((country) => ({
      id: country.id,
      name: country.name,
    }));
    setCountriesData({
      countries,
      countriesOptions: [
        { id: "none", name: "Elige tu país de origen" },
        ...countriesForSelectComponent,
      ],
    });
  };

  useCustomEffect(() => {
    setValue("avatar", { base64: userAvatar?.fileUrl, file: userAvatar });
  }, [userAvatar?.fileUrl]);

  useCustomEffect(() => {
    setValueAboutMe("name", userData?.name);
    setValueAboutMe("lastName", userData?.lastName);
    setValueAboutMe("country", userData?.country.id);
  }, [userData]);

  useCustomEffect(() => {
    userSubscription?.product?.name &&
      handleSubscriptionName(userSubscription?.product.name);
  }, [userSubscription?.product?.name]);

  useCustomEffect(() => {
    userSubscription?.onTrial &&
      userSubscription?.endsAt &&
      handleSubscriptionTrialStatus(userSubscription?.endsAt);
  }, [userSubscription?.onTrial]);

  useCustomEffect(() => {
    getCountries({ success: onGetCountriesSuccess });
    if (token) {
      getProfile({});
      getBilling({ success: (url) => setBillingPortalUrl(url) });
      getNotifications({ success: getNotificationsSuccess });
      if (initialRender.current) {
        initialRender.current = false;
      }
    } else {
      navigateTo("/");
    }
  }, []);

  return {
    breadcrumbItemsElements,
    currentSubscription,
    userAvatar,
    userData,
    userSubscription,
    handleBillingCallToAction,

    handleSubmit,
    control,
    isDirty,
    errors,

    editProfile,
    handleEditProfile,

    notifications,
    handleDeleteNotification,
    handleGetNotifications,

    countriesData,

    handleSubmitAboutMe,
    controlAboutMe,
    errorsAboutMe,

    handleSubmitPassword,
    controlPassword,
    errorsPassword,
    handleEditProfilePassword,
    handleEditProfileAboutMe,

    showConfirmationModal,
    handleCloseEditSection,

    useGetLeftDaysHours,
  };
}
