import { useCaseLogout } from "../../../domain/useCases/logout";
import { authenticationDataService } from "../../../data/services/authentication.data.service";
import { useNavigation } from "../../hooks";

export function useLogoutViewModel() {
    const {navigateTo} = useNavigation();
    const {run} = useCaseLogout(authenticationDataService());
    const logOutData ={
        success: () => navigateTo("/")
    }
    const onSubmit = () => {
        run(logOutData)
    }

    return {
        onSubmit
    }
}