import { FC } from "react";
import { StyledSubscriptionsSection } from "./SubscriptionsSection.styles";

import { SubscriptionEntity } from "../../../../../domain/entities";
import { Carousel, SubscriptionCard } from "../../../../components";

export interface SubscriptionsSectionProps {
  subscriptionsList: Array<SubscriptionEntity>;
  handleCallToActionCard: (data: any) => void;
}

export const SubscriptionsSection: FC<SubscriptionsSectionProps> = ({
  subscriptionsList,
  handleCallToActionCard,
}) => {
  return (
    <StyledSubscriptionsSection>
      <h3>Elige tu plan Storytraining</h3>
      <p>
        Forma parte de nuestra historia mejorando tus soft skills a través de
        nuestro contenido
      </p>
      <hr className="pt-0 w-72 opacity-50 border-t-2 sm:hidden border-white " />
      <div className="w-full 2xl:max-w-theme xl:max-w-6xl sm:max-w-4xl hidden lg:flex lg:pt-8 ">
        <Carousel
          slidesToShow={4}
          slidesToScroll={1}
          infinite={true}
          dots={false}
          responsive={[
            {
              breakpoint: 1536,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                centerMode: true,
                centerPadding: "72px",
              },
            },
            {
              breakpoint: 1280,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                centerMode: true,
                centerPadding: "92px",
              },
            },
          ]}
        >
          {subscriptionsList.map((currentSubscription, index) => (
            <SubscriptionCard
              className={"h-full w-full px-2"}
              key={`S-${index}`}
              subscriptionData={currentSubscription}
              callToActionCard={
                currentSubscription?.callToAction
                  ? currentSubscription.callToAction
                  : handleCallToActionCard
              }
            />
          ))}
        </Carousel>
      </div>
      <div className="lg:hidden flex flex-row flex-wrap justify-center gap-6 px-8">
        {subscriptionsList.map((currentSubscription, index) => (
          <SubscriptionCard
            key={`S-${index}`}
            subscriptionData={currentSubscription}
            callToActionCard={
              currentSubscription?.callToAction
                ? currentSubscription.callToAction
                : handleCallToActionCard
            }
          />
        ))}
      </div>
    </StyledSubscriptionsSection>
  );
};
