import { AxiosError } from "axios";
import { useLayoutEffect, useState } from "react";
import { LearningPathModel } from "../../../data/models/learningPath.model";
import { learningPathDataService } from "../../../data/services/learningPath.data.service";
import { LearningPathEntity, MedalEntity } from "../../../domain/entities";
import { useCaseLearningPath } from "../../../domain/useCases";
import { useCustomEffect, useNavigation } from "../../hooks";

export function useLearningPathsViewModel() {
  const { getCurrentPathName, navigateTo } = useNavigation();

  const [sizeScreen, setSizeScreen] = useState({ x: 0, y: 0 });
  const [listOfMedals, setListOfMedals] = useState<Array<MedalEntity>>([]);
  const [listLearningPaths, setListLearningPaths] = useState<
    Array<LearningPathEntity>
  >([]);

  const { getListMedals, getListLearningPaths } = useCaseLearningPath(
    learningPathDataService()
  );

  function successGetListOfMedals(medals: Array<MedalEntity>) {
    setListOfMedals(medals);
  }

  function errorGetListOfMedals(e: AxiosError) {
    console.log("errorGetListOfMedals", e);
  }

  function successGetListOfLearningPaths(
    learningPaths: Array<LearningPathEntity>
  ) {
    setListLearningPaths(learningPaths);
  }

  function errorGetListOfLearningPaths(e: AxiosError) {
    console.log("errorGetListOfLearningPaths", e);
  }

  /* const handleStartLearningPath = (uuid: string) => {
    postLearningPath({
      learningPathUuid: uuid,
    });
    navigateTo(`/learning-path-detail?uuid=${uuid}`)
  }; */

  useCustomEffect(() => {
    getListMedals({
      success: successGetListOfMedals,
      error: errorGetListOfMedals,
    }),
      getListLearningPaths({
        success: successGetListOfLearningPaths,
        error: errorGetListOfLearningPaths,
      });
  }, []);

  useLayoutEffect(() => {
    const handleResize = () => {
      setSizeScreen({ x: window.innerWidth, y: window.innerHeight });
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return {
    sizeScreen,
    listOfMedals,
    listLearningPaths,
    getCurrentPathName,
    navigateTo,
    //handleStartLearningPath
  };
}
