import tw from "twin.macro";
import styled from "styled-components";

export const StyledPodcastContainer = styled.div.attrs({
  className: "StyledPodcastContainer",
})`
  ${tw`relative max-w-sm w-full mx-auto px-2 lg:(max-w-theme px-16) pb-16 flex flex-col gap-4 items-center justify-center text-center`}
  > h3 {
    ${tw`text-2xl font-primaryBold text-white `}
  }
`;
