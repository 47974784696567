import { useState } from "react";
import { useSelector } from "react-redux";
import {
  getSessionToken,
  getUser,
} from "../../../../data/dto/selectors/session.selector";
import { useCustomEffect, useNavigation, useWindowSize } from "../../../hooks";
import { FieldValues, useForm } from "react-hook-form";

export function useHeader() {
  const token = useSelector(getSessionToken);
  const {
    handleSubmit,
    control,
    setValue
  } = useForm({ mode: "onChange" });

  const onSubmit = (data: FieldValues) => {
    navigateTo(`/search?searchTerm=${data.searchTerm}`);
  };

  const { isDesktop } = useWindowSize();
  const { navigateTo, getCurrentPathName, getQueryParam } = useNavigation();
  const [primaryButtonData, setPrimaryButtonData] = useState({
    isAuth: false,
    redirectTo: "/login",
    label: "Inicia sesión",
  });
  const pathName = getCurrentPathName();

  function handlePrimaryButtonData() {
    switch (pathName) {
      case "/login":
        setPrimaryButtonData({
          isAuth: true,
          redirectTo: "/signup",
          label: "Crear cuenta",
        });
        break;
      case "/signup":
        setPrimaryButtonData({
          isAuth: true,
          redirectTo: "/login",
          label: "Ingresa",
        });

        break;
      default:
        setPrimaryButtonData({
          isAuth: false,
          redirectTo: "/login",
          label: "Inicia sesión",
        });
        break;
    }
  }

  useCustomEffect(() => {
    handlePrimaryButtonData();
    const searchTerm = getQueryParam("searchTerm");
    if(pathName == "/search" && searchTerm){
      setValue("searchTerm", searchTerm);
    }
  }, [pathName]);
  return {
    token,
    isDesktop,
    navigateTo,
    primaryButtonData,
    handleSubmit,
    control,
    onSubmit
  };
}
