import { createReducer } from "./createReducer";
import { ActionsTypes } from "../actions/actions.types";
import { AnyAction } from "redux";

export const session = createReducer(null, {
  [ActionsTypes.SAVE_SESSION](
    state: { [key: string]: any },
    action: AnyAction
  ) {
    const { payload } = action;
    return {
      ...state,
      ...payload,
    };
  },
  [ActionsTypes.SAVE_PROFILE](
    state: { [key: string]: any },
    action: AnyAction
  ) {
    const { payload } = action;
    return {
      ...state,
      user: { ...payload },
    };
  },
  [ActionsTypes.LOGOUT_SESSION]() {
    return {};
  },
});
