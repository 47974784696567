import tw from "twin.macro";
import styled from "styled-components";

export interface StyledSavedCardProps {
  className?: string;
}

export const StyledSavedCard = styled.article.attrs((props) => ({
  className: props.className,
}))<StyledSavedCardProps>`
  ${tw`flex w-full gap-x-4 flex-row max-w-full`}
  @media (max-width: 1279.98px) {
    ${tw` flex-col`}
  }
  .StyledBadge {
    ${tw`py-1 h-auto min-w-[100px] text-center flex items-center justify-center`}
    width: min-content;
    font-size: 10px !important;
    line-height: 12px;
  }
`;

export const StyledContainerImage = styled.div.attrs({
  className: "StyledContainerImage",
})`
  ${tw`relative rounded-lg w-full lg:(h-32 max-w-[224px]) `}
  @media (max-width: 1279.98px) {
    ${tw`flex-row max-w-full`}
  }
  img {
    ${tw`object-cover rounded-lg w-full lg:(h-32)`}
  }
  @media (max-width: 1279.98px) {
    ${tw`h-44 w-full`}
    img {
      ${tw`h-44 w-full`}
    }
  }
  > .playButton {
    ${tw`absolute top-1/2 left-1/2`}
    width: 30px;
    height: 30px;
    transform: translate(-50%, -50%);
  }
`;

export const StyledContainerBadge = styled.div.attrs({
  className: "StyledContainerBadge",
})`
  ${tw`mt-2 flex flex-row overflow-hidden gap-2 lg:(w-96 flex-col)`}
  @media (max-width: 1279.98px) {
    ${tw`flex-row `}
  }
`;

export const StyledImage = styled.img.attrs({
  className: "StyledImage",
})`
  @media (max-width: 480px) {
    display: none !important;
  }
`;

export const StyledImageMobile = styled.img.attrs({
  className: "StyledImageMobile",
})`
  @media (min-width: 479.99px) {
    display: none !important;
  }
`;
