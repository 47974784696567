import tw from "twin.macro";
import styled from "styled-components";

export const StyledQuote = styled.article.attrs({
  className: "StyledQuote",
})`
  ${tw`relative flex flex-col items-center justify-center gap-y-9`}
  h3 {
    ${tw`text-xl leading-6 font-primarySemibold text-white lg:(max-w-[68rem] text-[2rem] leading-10)`}
  }
`;

export const StyledCite = styled.div.attrs({
  className: "StyledCite",
})`
  ${tw`relative w-full flex flex-col items-center gap-2 lg:(flex-row justify-end)`}

  .Separator {
    ${tw`hidden lg:(block)`}
  }
`;

export const StyledLine = styled.div.attrs({
  className: "StyledLine",
})`
  ${tw`w-8 h-0 border-b-4 border-white`}
`;
