import tw from "twin.macro";
import styled from "styled-components";

export const StyledCommentContainer = styled.article.attrs((props) => {
  className: props.className;
})`
  ${tw`w-full max-w-[272px] min-h-[128px] max-h-[280px] p-3 bg-gray-500 rounded-[24px] flex flex-col items-center justify-center gap-3 lg:(flex-row max-h-[208px] max-w-[364px]) `}
  .MuiAvatar-root {
    ${tw`hidden lg:(flex ) `}
  }
`;
