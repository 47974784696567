import { FieldValues, useForm } from "react-hook-form";
import { useStopwatch } from "react-timer-hook";
import { yupResolver } from "@hookform/resolvers/yup";
import { recoveryPasswordSchema } from "../../validators";
import { recoveryPassword } from "../../../domain/useCases";
import { authenticationDataService } from "../../../data/services/authentication.data.service";
import { useState } from "react";
import { useCustomEffect } from "../../hooks/useHookEffect";
import { useNavigation, useObjectToArray } from "../../hooks";
import toast from "react-hot-toast";

export function useRecoveryPasswordViewModel() {
  const { seconds, minutes, isRunning, pause, reset } = useStopwatch({
    autoStart: false,
  });
  const { navigateTo } = useNavigation();

  const [
    showModalRecoveryPasswordSuccess,
    setShowModalRecoveryPasswordSuccess,
  ] = useState(false);
  const { run } = recoveryPassword(authenticationDataService());
  const {
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(recoveryPasswordSchema),
  });

  /*handlers timer*/
  const handleModalTimer = () => {
    minutes === 1 && pause();
  };

  /*handlers modal*/
  const handleCloseModal = () => {
    setShowModalRecoveryPasswordSuccess(false);
    navigateTo("/login");
  };

  /*handlers email submit*/
  const onRecoveryPasswordSuccess = () => {
    setShowModalRecoveryPasswordSuccess(true);
    reset();
  };

  const onRecoveryPasswordError = (error: any) => {
    toast.error(error.response.data.message)
  };

  const onSubmit = (data: FieldValues) => {
    const recoveryData = {
      email: data.email,
      success: onRecoveryPasswordSuccess,
      error: onRecoveryPasswordError,
    };
    run(recoveryData);
  };

  useCustomEffect(handleModalTimer, [minutes]);

  return {
    showModalRecoveryPasswordSuccess,
    handleCloseModal,

    seconds,
    isRunning,

    handleSubmit,
    onSubmit,
    control,
    errors,
  };
}
