import { learningPathDetailInput, LearningPathDetailService, payMedalInput, startLearningPathInput } from "../../domain/services/learningPathDetail.service";
import { handleResponse } from "../../domain/shared";
import { httpService } from "../http/services/http";
import { BillingPortalModel } from "../models";
import { LearningPathModel } from "../models/learningPath.model";
import { LearningPathDetailModel } from "../models/learningPathDetail.model";
import { Endpoints } from "./endpoints";

export interface ListLearningPathsDetailResponse {
    data: {
        data: LearningPathDetailModel;
    }
}

export interface PayMedalResponse {
    data: {
      data: BillingPortalModel;
    };
  }

export function LearningPathDetailDataService(): LearningPathDetailService {
    const { get, post } = httpService();
    return {
        async getListDetailLearningPaths({success, error, learningPathUuid}: learningPathDetailInput){
            try {
                const response: ListLearningPathsDetailResponse = await get(`${Endpoints.listLearningPaths}/${learningPathUuid}`);
                if (success){
                    success(response.data.data);
                }
            } catch (e) {
                if(error){
                    error(e);
                }
            }
        },
        async postLearningPath({success, error, learningPathUuid}: startLearningPathInput){
            try {
                await post(`${Endpoints.listLearningPaths}/${learningPathUuid}`, {learningPathUuid});
                if (success){
                    success();
                  }
            } catch (e) {
                if (error) {
                    error(e);
                }
            }
        },
        async postPayMedal({success,error, learningPathUuid, medalId}: payMedalInput){
            try {
                const response: PayMedalResponse = await post(`${Endpoints.listLearningPaths}/${learningPathUuid}/medals/${medalId}/payment`, {learningPathUuid, medalId});
                if (success){
                    success(response.data.data.url);
                } 
            } catch (e) {
                if (error) {
                    error(e);
                }
            }
        }
    }
}