import tw from "twin.macro";
import styled from "styled-components";

interface StyledCategoryCarouselProps {
  fullWidth?: boolean;
  hideCategory?: boolean;
}

export const StyledCategoryCarousel = styled.div.attrs((props) => ({
  className: props.className,
}))<StyledCategoryCarouselProps>`
  ${tw`w-full flex flex-col items-center gap-y-8`}
  > h2 {
    ${tw`text-xl leading-9 font-primaryBold text-white sm:(text-[2rem] leading-[2.375rem])`}
  }

  > h3 {
    ${tw`text-sm font-primaryBold text-white sm:(text-2xl)`}
  }

  > p {
    ${tw`max-w-[67.5rem] text-xl leading-6 mt-4 text-white`}
  }
  ${({ hideCategory }) => {
    return (
      hideCategory &&
      `
      .Badge{
        display: none;
      }
    `
    );
  }}

  ${({ fullWidth }) => {
    return fullWidth && tw`lg:(px-5 text-left items-start)`;
  }}
`;
