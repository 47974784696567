import { FC, ChangeEvent } from "react";
import { GridRowProps, GridColumns, GridRowsProp } from "@mui/x-data-grid";

import {
  StyledTableContainer,
  StyledDataGrid,
  // StyledPagination,
} from "./Table.styles";

interface TableProps {
  className?: string;
  rows: GridRowsProp;
  columns: GridColumns;
  page?: number;
  totalPages?: number;
  onPageChange?: (event: ChangeEvent<unknown>, page: number) => void;
}

export const Table: FC<TableProps> = ({
  className,
  rows,
  columns,
  page,
  totalPages,
  onPageChange,
}) => {
  return (
    <StyledTableContainer
      className={className}
      style={{
        height: `${!!rows.length ? `${120 + rows.length * 72}` : `${180}`}px`,
      }}
    >
      <StyledDataGrid
        rows={rows}
        columns={columns}
        getRowId={(row) => row.uuid}
        disableSelectionOnClick
        disableColumnMenu
        disableColumnFilter
        rowHeight={48}
        paginationMode={"client"}
        columnBuffer={0}
        columnThreshold={0}
        localeText={{
          noRowsLabel: "Sin datos",
        }}
      />
      {/* <StyledPagination
        page={page}
        count={totalPages}
        onChange={onPageChange}
      /> */}
    </StyledTableContainer>
  );
};
