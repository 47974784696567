import { FC } from "react";
import { StyledGoSubscribe, StyledBar } from "./GoSubscribe.styles";
import { TextFieldControlled, Button } from "../../../../components";
import mailLogo from "../../../../assets/images/mailLogo.svg";
import { useGoSubscribe } from "./useGoSubscribe";

export const GoSubscribe: FC = () => {
  const { handleSubmit, control, onSubmit, errors } = useGoSubscribe();
  return (
    <StyledGoSubscribe>
      <StyledBar>
        <TextFieldControlled
          className="EmailTextField"
          id="email"
          name="email"
          placeholder="Ingresa tu email y crea tu cuenta"
          error={!!errors.email}
          helperText={errors?.email?.message}
          control={control}
          iconSrc={mailLogo}
        />
        <Button className="lg:h-[50px]" onClick={handleSubmit(onSubmit)}>
          ¡Comienza ya!
        </Button>
      </StyledBar>
    </StyledGoSubscribe>
  );
};
