import tw from "twin.macro";
import styled from "styled-components";

export const StyledFaqsSection = styled.section.attrs({
  className: "StyledFaqsSection",
})`
  ${tw`px-5 pt-12 pb-16 w-full flex flex-col gap-y-2 items-center bg-transparent lg:(gap-y-4 bg-secondary-200) `}

  > h1 {
    ${tw`text-2xl font-primaryBold text-white max-w-5xl lg:(text-2)`}
  }

  > p {
    ${tw`mb-10 font-primaryBook text-white text-sm lg:(mb-6 text-xl )`}
  }
`;
