import { FC } from "react";

interface IconProps {
  className?: string;
  color?: string;
  width?: string;
  height?: string;
}

export const LineSeparatorIcon: FC<IconProps> = ({
  className,
  color = "#ffffff",
  width = "4px",
  height = "38px",
}) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 4 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect opacity="0.1" width="4" height="38" rx="2" fill={color} />
    </svg>
  );
};
