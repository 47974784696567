import tw from "twin.macro";
import styled from "styled-components";
import { styled as styledMUI } from "@mui/material/styles";
import LinearProgress from "@mui/material/LinearProgress";

export interface StyledInProgressCardProps {
  className?: string;
}

export const StyledPodcastCard = styled.article.attrs((props) => ({
  className: props.className,
}))<StyledInProgressCardProps>`
  ${tw`relative w-[154px] lg:(w-[184px]) flex flex-col`}
  .playButton {
    ${tw`absolute top-1/2 left-1/2`}
    width: 30px;
    height: 30px;
    transform: translate(-50%, -50%);
    z-index: 10;
  }
  > div {
    ${tw`w-[154px] lg:(w-[184px])`}
  }
`;

export const StyledLinearProgress = styledMUI(LinearProgress)((props) => ({
  height: "10px",
  width: "100%",
  display: "relative",
  bottom: "0px",
  zIndex: "10",
  backgroundColor: "#FFFFFF",

  [props.theme.breakpoints.down(1024)]: {
    height: "16px",
  },
  "& .MuiLinearProgress-bar1Determinate": {
    backgroundColor: "#E30613",
  },
  "& .MuiLinearProgress-bar2Determinate": {
    backgroundColor: "#FFFFFF",
  },
}));

export const StyledImage = styled.img.attrs({
  className: "StyledImage",
})`
  ${tw`relative object-cover mx-auto rounded-lg w-[154px] h-[154px] lg:(w-[184px] h-[184px])`}
`;
