import { StyledBreadcrumb, StyledItem } from "./Breadcrumb.styles";
import { Link } from "react-router-dom";

interface BreadcrumbItem {
  breadcrumbItems: BreadcrumbProps[];
  className?: string;
}

interface BreadcrumbProps {
  name: string;
  url?: string;
}

export const Breadcrumb = ({ className, breadcrumbItems }: BreadcrumbItem) => {
  return (
    <StyledBreadcrumb className={className}>
      {breadcrumbItems?.map(({ name, url }, index) => {
        return url === undefined ? (
          <StyledItem key={index}>{name}</StyledItem>
        ) : (
          <StyledItem key={index}>
            {" "}
            <Link to={url}>{name}</Link>
          </StyledItem>
        );
      })}
    </StyledBreadcrumb>
  );
};
