import { FC } from "react";
import { FieldValues } from "react-hook-form";
import { CloseIcon } from "../../assets/Icons";
import { Button } from "../Button";
import { RatingControlled } from "../Rating/RatingControlled";
import { TextAreaControlled } from "../TextArea";
import {
  StyledButtonRating,
  StyledModal,
  StyledRatingContainer,
} from "./RatingModal.styles";
import { useRating } from "./useRatingModal";

interface RatingModalProps {
  className?: string;
  showModal: boolean;
  onSubmit: (data: FieldValues) => void;
  onCancel: (data: any) => void;
}

export const RatingModal: FC<RatingModalProps> = ({
  className,
  showModal,
  onSubmit,
  onCancel,
}) => {
  const { handleSubmit, control, errors } = useRating();
  return (
    <StyledModal
      className={className}
      showModal={showModal}
      backgroundColor={"black"}
    >
      <StyledRatingContainer>
        <h3>Calificar este título</h3>
        <RatingControlled
          id={"qualification"}
          name={"qualification"}
          control={control}
          error={!!errors?.qualification}
          helperText={errors?.qualification?.message}
        />
        <h4 className="pt-1">Comentario</h4>
        <TextAreaControlled
          id={"body"}
          name={"body"}
          control={control}
          error={!!errors?.body}
          helperText={errors?.body?.message}
          maxLength={140}
        />
        <p>Max. 140 Carácteres</p>
        <div>
          <StyledButtonRating onClick={handleSubmit(onSubmit)}>
            Enviar
          </StyledButtonRating>
          <StyledButtonRating color="secondary" onClick={onCancel}>
            Cancelar
          </StyledButtonRating>
        </div>
      </StyledRatingContainer>
    </StyledModal>
  );
};
